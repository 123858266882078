import API from '../api/api'
import { exportPages } from './pages'

const SYNC_BLOGS_PENDING = 'SYNC_BLOGS_PENDING'
const SYNC_BLOGS_PROGRESS = 'SYNC_BLOGS_PROGRESS'
const SYNC_BLOGS_RESPONSE = 'SYNC_BLOGS_RESPONSE'
const SYNC_BLOG_PENDING = 'SYNC_BLOG_PENDING'
const SYNC_BLOG_RESPONSE = 'SYNC_BLOG_RESPONSE'
const SAVE_BLOGS_PENDING = 'SAVE_BLOGS_PENDING'
const SAVE_BLOGS_PROGRESS = 'SAVE_BLOGS_PROGRESS'
const SAVE_BLOGS_RESPONSE = 'SAVE_BLOGS_RESPONSE'
const SYNC_BLOG_METAFIELDS_PENDING = 'SYNC_BLOG_METAFIELDS_PENDING'
const SYNC_BLOG_METAFIELDS_PROGRESS = 'SYNC_BLOG_METAFIELDS_PROGRESS'
const SYNC_BLOG_METAFIELDS_RESPONSE = 'SYNC_BLOG_METAFIELDS_RESPONSE'
const CREATE_BLOG_CUSTOM_CONTENTS_PENDING = 'CREATE_BLOG_CUSTOM_CONTENTS_PENDING'
const CREATE_BLOG_CUSTOM_CONTENTS_PROGRESS = 'CREATE_BLOG_CUSTOM_CONTENTS_PROGRESS'
const CREATE_BLOG_CUSTOM_CONTENTS_RESPONSE = 'CREATE_BLOG_CUSTOM_CONTENTS_RESPONSE'
const DELETE_BLOG_CUSTOM_CONTENT_PENDING = 'DELETE_BLOG_CUSTOM_CONTENT_PENDING'
const DELETE_BLOG_CUSTOM_CONTENT_PROGRESS = 'DELETE_BLOG_CUSTOM_CONTENT_PROGRESS'
const DELETE_BLOG_CUSTOM_CONTENT_RESPONSE = 'DELETE_BLOG_CUSTOM_CONTENT_RESPONSE'
const EXPORT_BLOGS_PENDING = 'EXPORT_BLOGS_PENDING'
const EXPORT_BLOGS_PROGRESS = 'EXPORT_BLOGS_PROGRESS'
const EXPORT_BLOGS_RESPONSE = 'EXPORT_BLOGS_RESPONSE'
const IMPORT_BLOGS_PENDING = 'IMPORT_BLOGS_PENDING'
const IMPORT_BLOGS_PROGRESS = 'IMPORT_BLOGS_PROGRESS'
const IMPORT_BLOGS_RESPONSE = 'IMPORT_BLOGS_RESPONSE'
const RESET_ERRORS = 'RESET_ERRORS'

const initialState = {
  sync_blogs_pending: true,
  sync_blog_pending: false,
  sync_blog_metafields_pending: false,
  save_blogs_pending: false,
  create_blog_custom_contents_pending: false,
  delete_blog_custom_content_pending: false,
  export_blogs_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_blogs_pending: false,
  imported_entries: 0,
  progress: 0,
  error: false,
  blogs: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case SYNC_BLOGS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_blogs_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_BLOGS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_BLOGS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_blogs_pending: false,
          error: false,
          blogs: action.response.blogs
        }
      } else {
        return {
          ...state,
          sync_blogs_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }

    case SYNC_BLOG_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_blog_pending: true,
        error: false,
      }
    }
    case SYNC_BLOG_RESPONSE: {
      if(action.response.success === true) {
        let newBlogs = state.blogs.slice()
        let blogToUpdate = newBlogs.find(x => x.id == action.response.blog.id)
        
        blogToUpdate.synchronized = true;

        blogToUpdate.progress = action.response.blog.progress

        blogToUpdate.source.title = action.response.blog.source.title
        blogToUpdate.source.page_title = action.response.blog.source.page_title
        blogToUpdate.source.page_description = action.response.blog.source.page_description
        
        blogToUpdate.target.title = action.response.blog.target.title
        blogToUpdate.target.page_title = action.response.blog.target.page_title
        blogToUpdate.target.page_description = action.response.blog.target.page_description

        blogToUpdate.custom_contents = action.response.blog.custom_contents.slice()
        
        return {
          ...state,
          blogs: newBlogs,
          sync_blog_pending: false,
          error: false,
        }
      } else {
        return {
          ...state,
          sync_blog_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1,
          },
        }
      }
    }

    case SAVE_BLOGS_PENDING: {
      return {
        ...state,
        progress: 0,
        save_blogs_pending: true,
        error: false,
      }
    }
    case SAVE_BLOGS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_BLOGS_RESPONSE: {
      let error = false;
      let newBlogs = state.blogs.slice();
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let newBlog = newBlogs.find(x => x.id == response.blog.id)
          newBlog.progress = response.blog.progress
          newBlog.source = {...response.blog.source}
          newBlog.target = {...response.blog.target}
          newBlog.metafields = response.blog.metafields.slice()
          newBlog.custom_contents = response.blog.custom_contents.slice()
        }
      })
      return {
        ...state,
        blogs: newBlogs,
        save_blogs_pending: false,
        error: error
      }
    }

    case SYNC_BLOG_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_blog_metafields_pending: true,
        error: false,
      }
    }
    case SYNC_BLOG_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_BLOG_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        let newBlogs = state.blogs.slice()
        let blogToUpdate = newBlogs.find(x => x.id == action.response.blog_id)
        
        blogToUpdate.metafields_synchronized = true;
        blogToUpdate.metafields = action.response.metafields.slice()

        return {
          ...state,
          sync_blog_metafields_pending: false,
          blogs: newBlogs,
          error: false
        }
      } else {
        return {
          ...state,
          sync_blog_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 2,
          },
        }
      }
    }
    case CREATE_BLOG_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_blog_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_BLOG_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_BLOG_CUSTOM_CONTENTS_RESPONSE: {
      let newBlogs = state.blogs.slice()
      let error = false;

      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let blogToUpdate = newBlogs.find(x => x.id == response.blog_id)
          blogToUpdate.custom_contents.push({
            id: response.custom_content.id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          });
        }
      }

      return {
        ...state,
        blogs: newBlogs,
        create_blog_custom_contents_pending: false,
        error: error
      }
    }
    case DELETE_BLOG_CUSTOM_CONTENT_PENDING: {
      return {
        ...state,
        delete_blog_custom_content_pending: true,
        error: false,
      }
    }
    case DELETE_BLOG_CUSTOM_CONTENT_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_BLOG_CUSTOM_CONTENT_RESPONSE: {
      let newBlogs = state.blogs.slice()
      let error = false;

      if(action.response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
      } else {
        let blogToUpdate = newBlogs.find(x => x.id == action.response.blog_id)
        blogToUpdate.custom_contents = blogToUpdate.custom_contents.filter(x => x.id != action.response.id)
      }

      return {
        ...state,
        blogs: newBlogs,
        delete_blog_custom_content_pending: false,
        error: error
      }
    }
    case EXPORT_BLOGS_PENDING: {
      return {
        ...state,
        progress: 0,
        export_blogs_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_BLOGS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_BLOGS_RESPONSE: {
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_blogs_pending: false,
          export_po_download_url: action.response.po_download_url,
          export_csv_download_url: action.response.csv_download_url
        }
      } else {
        return {
          ...state,
          progress: 100,
          export_blogs_pending: false
        }
      }
    }
    case IMPORT_BLOGS_PENDING: {
      return {
        ...state,
        progress: 0,
        import_blogs_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_BLOGS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_BLOGS_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_blogs_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_blogs_pending: false,
          imported_entries: importedEntries
        }
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncBlogs = (language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_BLOGS_PENDING
    })

    API.syncBlogs(
      language,
      progress => {
        dispatch({
          type: SYNC_BLOGS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_BLOGS_RESPONSE,
          response: response,
          action: () => syncBlogs(language)
        }
      )
    })
  }
}

export const syncBlog = (blogId, languageId) => {  
  return dispatch => {
    dispatch({
      type: SYNC_BLOG_PENDING
    })

    API.syncBlog(
      blogId,
      languageId,
      response => {
        dispatch({
          type: SYNC_BLOG_RESPONSE,
          response: response,
          action: () => syncBlog(blogId, languageId)
        })
      }
    )
  }
}

export const saveBlogs = (language, items, force = 0) => {  
  return dispatch => {
    dispatch({
      type: SAVE_BLOGS_PENDING
    })

    API.saveBlogs(
      language,
      items,
      force,
      progress => {
        dispatch({
          type: SAVE_BLOGS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_BLOGS_RESPONSE,
          response: response,
          items: items,
          action: () => saveBlogs(language, items, force)
        })
      })
  }
}

export const syncBlogMetafields = (blog, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_BLOG_METAFIELDS_PENDING
    })

    API.syncBlogMetafields(
      blog,
      language,
      progress => {
        dispatch({
          type: SYNC_BLOG_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_BLOG_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncBlogMetafields(blog, language)
        }
      )
    })
  }
}

export const createBlogCustomContents = (blog, language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_BLOG_CUSTOM_CONTENTS_PENDING
    })

    API.createBlogCustomContents(
      blog,
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_BLOG_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_BLOG_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createBlogCustomContents(blog, language, items)
        })
      })
  }
}

export const deleteBlogCustomContent = (blog, ids) => {
  return dispatch => {
    dispatch({
      type: DELETE_BLOG_CUSTOM_CONTENT_PENDING
    })

    API.deleteBlogCustomContent(
      blog,
      ids,
      progress => {
        dispatch({
          type: DELETE_BLOG_CUSTOM_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_BLOG_CUSTOM_CONTENT_RESPONSE,
          response: response,
          action: () => deleteBlogCustomContent(blog, ids)
        })
      })
  }
}

export const exportBlogs = (language) => {
  return dispatch => {
    dispatch({
      type: EXPORT_BLOGS_PENDING
    })

    API.exportBlogs(
      language,
      progress => {
        dispatch({
          type: EXPORT_BLOGS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_BLOGS_RESPONSE,
          response: response,
          action: () => exportBlogs(language)
        }
      )
    })
  }
}

export const importBlogs = (file, fileType, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_BLOGS_PENDING
    })

    API.importBlogs(
      file,
      fileType,
      language,
      progress => {
        dispatch({
          type: IMPORT_BLOGS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_BLOGS_RESPONSE,
          response: response,
          action: () => importBlogs(file, fileType, language)
        }
      )
    })
  }
}