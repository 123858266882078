import { ContactlessOutlined } from '@material-ui/icons';
import API from '../api/api';

const COUNT_WORDS_PENDING = 'COUNT_WORDS_PENDING';
const COUNT_WORDS_PROGRESS = 'COUNT_WORDS_PROGRESS';
const COUNT_WORDS_RESPONSE = 'COUNT_WORDS_RESPONSE';
const TRANSLATE_WORDS_PENDING = 'TRANSLATE_WORDS_PENDING';
const TRANSLATE_WORDS_PROGRESS = 'TRANSLATE_WORDS_PROGRESS';
const TRANSLATE_WORDS_RESPONSE = 'TRANSLATE_WORDS_RESPONSE';
const RESET_ERRORS = 'RESET_ERRORS';
const GET_RUNNING_TASKS_PENDING = 'GET_RUNNING_TASKS_PENDING';
const GET_RUNNING_TASKS_RESPONSE = 'GET_RUNNING_TASKS_RESPONSE';
const CLEAR_TASK_RESPONSE = 'CLEAR_TASK_RESPONSE';
const PREPARE_COUNT_WORDS = 'PREPARE_COUNT_WORDS';

const initialState = {
  prepare_word_count_pending: false,
  count_words_pending: false,
  count_progress: 0,
  count_tasks: {},
  all_count_jobs_count: 0,
  all_count_jobs_done: 0,
  translate_words_pending: false,
  translate_progress: 0,
  translate_tasks: {},
  all_translate_jobs_count: 0,
  all_translate_jobs_done: 0,
  total_words: 0,
  error: false,
  task_errors: [],
}

const sumBy = (obj, field) => obj
  .map(item => {
    if(!item[1]) return null;
    return item[1][field];
  })
  .reduce((prev, curr) => prev + curr, 0);

const concatBy = (obj, field) => obj
  .map(item => {
    if(!item.task) return null;
    return item.task[field];
  })
  .reduce((prev, curr) => prev.concat(curr), []);

const taskKey2DataObject = (key) => {
  let split1 = key.split(':');
  let split2 = split1[2].split('_')
  return {
    store: split1[1],
    to: split2[0],
    scope: split2[1].toLowerCase(),
    type: split2[2].toLowerCase(),
  };
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }
    case PREPARE_COUNT_WORDS: {
      return {
        ...state,
        prepare_word_count_pending: true,
        total_words: 0,
        count_words_pending: true,
        count_progress: 0,
        count_tasks: {},
        translate_progress: 0,
        translate_tasks: {}
      }
    }
    case COUNT_WORDS_PENDING: {
      return {
        ...state,
        prepare_word_count_pending: false,
        count_words_pending: true,
      }
    }
    case COUNT_WORDS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          count_words_pending: false,
          error: {
            message: action.error,
            level: 0,
          },
        }
      }
    }



    case TRANSLATE_WORDS_PENDING: {
      return {
        ...state,
        translate_progress: 0,
        translate_words_pending: true,
        count_words_pending: false,
        error: false,
        task_errors: [],
      }
    }
    case TRANSLATE_WORDS_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          translate_words_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }


    case GET_RUNNING_TASKS_RESPONSE: {
      if(state.prepare_word_count_pending) {
        return {
          ...state
        }
      }


      // Update the countProcesses array
      let countTasks = {};
      let translateTasks = {};

      if(action.response.success && action.response.tasks) {
        for(let [task_key, task_value] of Object.entries(action.response.tasks)) {
          // 1. Seperate count and translate tasks
          if(task_key.indexOf('_COUNT') > 0) {
            countTasks[task_key] = task_value;
          }
          if(task_key.indexOf('_TRANSLATE') > 0) {
            translateTasks[task_key] = task_value;
          }
        }
      }

      
      let all_total_words = sumBy(Object.entries(countTasks), 'WordCount');
      let all_count_jobs_done = sumBy(Object.entries(countTasks), 'jobs_done');
      let all_count_jobs_count = sumBy(Object.entries(countTasks), 'jobs_count');
      let new_count_progress = Math.round(all_count_jobs_done / all_count_jobs_count * 100);
      let new_count_words_pending = true;
      
      let all_translate_jobs_done = sumBy(Object.entries(translateTasks), 'jobs_done');
      let all_translate_jobs_count = sumBy(Object.entries(translateTasks), 'jobs_count');
      let new_translate_progress = Math.round(all_translate_jobs_done / all_translate_jobs_count * 100);
      let new_translate_words_pending = true;
      
      if(Object.values(countTasks).every(x => x.status === 'completed') 
      || Object.values(countTasks).find(x => x.status === 'failed') 
      || Object.values(countTasks).find(x => x.status === 'expired') 
      || Object.values(translateTasks).length > 0) 
      {
        new_count_words_pending = false;
      }
      if(Object.values(translateTasks).every(x => x.status === 'completed') 
      || Object.values(translateTasks).find(x => x.status === 'failed') 
      || Object.values(translateTasks).find(x => x.status === 'expired') 
      || Object.values(countTasks).length > 0) 
      {
        new_translate_words_pending = false;
      }

      return {
        ...state,
        total_words: all_total_words,
        count_tasks: countTasks,
        count_progress: new_count_progress,
        count_words_pending: new_count_words_pending,
        translate_tasks: translateTasks,
        translate_progress: new_translate_progress,
        translate_words_pending: new_translate_words_pending,
        all_count_jobs_count: all_count_jobs_count,
        all_count_jobs_done: all_count_jobs_done,
        all_translate_jobs_count: all_translate_jobs_count,
        all_translate_jobs_done: all_translate_jobs_done,
        //task_errors: all_errors,
      }
    }

    case CLEAR_TASK_RESPONSE: {
      return {
        ...state,
        count_words_pending: true,
        count_progress: 0,
        count_tasks: {},
        translate_progress: 0,
        translate_tasks: {}
      }
    }

    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const prepareCountWords = () => {
  return dispatch => {
    dispatch({
      type: PREPARE_COUNT_WORDS
    });
  }
}
export const bulkCountWords = (store, from, to, scope, subObject, callback) => {
  return dispatch => {
    dispatch({
      type: COUNT_WORDS_PENDING,
      store: store,
      from: from,
      to: to,
      scope: scope
    })

    API.bulkCountStart(
      store, 
      from, 
      to,
      scope,
      subObject,
      response => {
        dispatch({
          type: COUNT_WORDS_RESPONSE,
          response: response,
          scope: scope
        })
        callback(response);
    })
  }
}
export const bulkTranslationStart = (store, key, callback) => {  
  return dispatch => {
    dispatch({
      type: TRANSLATE_WORDS_PENDING,
      store: store,
      key: key,
    })

    API.bulkTranslationStart(
      store, 
      key,
      response => {
        dispatch({
          type: TRANSLATE_WORDS_RESPONSE,
          response: response,
        });
        callback(response);
    })
  }
}
export const bulkTranslationGetTasks = (callback) => {
  return dispatch => {
    dispatch({
      type: GET_RUNNING_TASKS_PENDING
    });

    API.bulkTranslationGetRunningTasks(
      response => {
        dispatch({
          type: GET_RUNNING_TASKS_RESPONSE,
          response: response
        });
        callback(response);
      }
    )
  }
}
export const bulkTranslationClearTask = (cacheKey, callback) => {
  return dispatch => {
    API.bulkTranslationClearTask(cacheKey, response => {
      dispatch({
        type: CLEAR_TASK_RESPONSE,
        response: response
      });
      callback(response);
    });
  }
}