import React, { Component } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Link } from "react-router-dom";
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import static_theme from '../../modules/static_theme';

let categories = {
  'search': 'Search Apps',
  'review': 'Review Apps',
  'bundle': 'Bundle Apps',
  'pagebuilder': 'Page Builder',
  'seo': 'SEO & Tools',
  'mobile': 'Mobile Apps',
}

let data = [
  {
    category: 'Search Apps',
    title: 'Product Filter & Search',
    subtitle: 'Boostcommerce',
    description: 'Collection Filter, Filter Menu, Search Filter, Smart Search',
    logo: 'productFilterSearch.jpg',
    link: 'https://boostcommerce.net/',
    storeLink: 'https://apps.shopify.com/product-filter-search',
    docsLink: 'https://boostcommerce.net/pages/integration#page-1'
  },
  {
    category: 'Search Apps',
    title: 'Instant Search +',
    subtitle: 'FastSimon',
    description: 'Fast Instant Search Bar, Collection Filters, Sort, Filter Menu',
    logo: 'instantSearchPlus.jpg',
    link: 'https://www.fastsimon.com/',
    storeLink: 'https://apps.shopify.com/instant-search',
    docsLink: ''
  },
  {
    category: 'Search Apps',
    title: 'Smart Search & Filter',
    subtitle: 'Searchanize',
    description: 'Improve user experience with search and filters',
    logo: 'smartSearchFilter.jpg',
    link: 'https://searchanise.io/',
    storeLink: 'https://apps.shopify.com/searchanise',
    docsLink: 'http://docs.searchanise.io/langify-integration-shopify/'
  },
  {
    category: 'Review Apps',
    title: 'Judgeme reviews',
    subtitle: 'Judge.me',
    description: 'Product reviews with free photos & videos for social proof',
    logo: 'judgeMe.jpg',
    link: 'https://judge.me/',
    storeLink: 'https://apps.shopify.com/judgeme',
    docsLink: 'https://support.judge.me/support/solutions/articles/44001951828-use-langify-to-translate-judge-me-tips-to-troubleshoot-errors'
  },
  {
    category: 'Review Apps',
    title: 'Product reviews',
    subtitle: 'Shopify',
    description: 'The easiest way to share product reviews.',
    logo: 'shopifyReviews.jpg',
    link: '',
    storeLink: 'https://apps.shopify.com/product-reviews',
    docsLink: ''
  },
  {
    category: 'Page Builder',
    title: 'PageFly',
    subtitle: 'Pagefly',
    description: 'Perfect page speed High quality and standards Integration with the best apps',
    logo: 'pagefly.jpg',
    link: 'https://pagefly.io/',
    storeLink: 'https://apps.shopify.com/pagefly',
    docsLink: 'https://help.pagefly.io/manual/integrate-langify-to-translate-my-page/',
  },
  {
    category: 'Page Builder',
    title: 'Gempage',
    subtitle: 'Gempage',
    description: 'Easily build your own amazing landing pages & product pages!',
    logo: 'gempage.jpg',
    link: 'https://gempages.net',
    storeLink: 'https://apps.shopify.com/gempages',
    docsLink: 'https://help.gempages.net/articles/add-weglot-translate-store',
  },
  {
    category: 'Page Builder',
    title: 'LayoutHub',
    subtitle: 'Layout Hub',
    description: 'Create Landing, Product and Blog pages in less than 10 mins',
    logo: 'layoutHub.jpg',
    link: 'https://layouthub.com/',
    storeLink: 'https://apps.shopify.com/layout-hub',
    docsLink: 'https://docs.layouthub.com/user-guides/translate-apps',
  },
  {
    category: 'Page Builder',
    title: 'Shogun',
    subtitle: 'Shogun Labs, Inc.',
    description: 'Page Builder Blog, Produktseiten, Landing Pages & Analytics',
    logo: 'shogun.jpg',
    link: 'https://getshogun.com/',
    storeLink: 'https://apps.shopify.com/shogun',
    docsLink: '',
  },
  {
    category: 'Bundle Apps',
    title: 'Bundles App',
    subtitle: 'PickyStory',
    description: 'Bundle, AI-Cart, Bundle Builder, Upsell, Quantity Breaks, BOGO',
    logo: 'bundlesApp.jpg',
    link: 'https://pickystory.com/',
    storeLink: 'https://apps.shopify.com/product-kits-bundles-pickystory',
    docsLink: 'https://support.pickystory.com/how-does-the-pickystory-and-langify-integration-work',
  },
  {
    category: 'Bundle Apps',
    title: 'Appstle℠ Subscriptions',
    subtitle: 'Appstle Inc.',
    description: 'Sell Customizable Subscriptions + Incredible Customer Support',
    logo: 'appstle.jpg',
    link: 'https://appstle.com',
    storeLink: 'https://apps.shopify.com/subscriptions-by-appstle',
    docsLink: 'https://intercom.help/appstle/en/articles/5936885-how-to-integrate-appstle-subscriptions-with-langify-in-your-shopify-store',
  },
  {
    category: 'Bundle Apps',
    title: 'Unlimited Bundles & Discounts',
    subtitle: 'Revy Apps',
    description: 'Bundle products together with discounts to boost your sales!',
    logo: 'unlimitedBundles.jpg',
    link: 'https://revy.io/',
    storeLink: 'https://apps.shopify.com/product-bundles-discounts-by-revy',
    docsLink: '',
  },
  {
    category: 'SEO & Tools',
    title: 'Yoast SEO - SEO for everyone',
    subtitle: 'YOAST',
    description: 'Increase organic traffic, technical SEO & get rich results!',
    logo: 'yoast.jpg',
    link: 'https://yoast.com/',
    storeLink: 'https://apps.shopify.com/yoast-seo',
    docsLink: '',
  },
  {
    category: 'SEO & Tools',
    title: 'Geolizr',
    subtitle: 'Lovely Apps',
    description: 'GEO based currency conversion, redirects and popups',
    logo: 'geolizr.jpg',
    link: 'https://lovely-apps.com/',
    storeLink: 'https://apps.shopify.com/geolizr',
    docsLink: '',
  },
  {
    category: 'SEO & Tools',
    title: 'Metafields Guru',
    subtitle: '256 development',
    description: 'Browser extension | Metafield sets | Bulk editor,import/export',
    logo: 'metafieldGuru.jpg',
    link: '',
    storeLink: 'https://apps.shopify.com/metafields-editor-2',
    docsLink: '',
  },
  {
    category: 'SEO & Tools',
    title: 'Google Shopping Feed XML/CSV',
    subtitle: 'AdWords Robot',
    description: 'Google Shopping, Local Inventory, Buy on Google, Bing/Facebook',
    logo: 'googleShoppingFeed.jpg',
    link: 'https://www.adwordsrobot.com/en/google-shopping',
    storeLink: 'https://apps.shopify.com/simple-google-shopping-feed',
    docsLink: '',
  },
  {
    category: 'Mobile Apps',
    title: 'Mobile App Builder',
    subtitle: 'Shopney',
    description: 'More Conversion, Less Ad Spend, iOS & Android Mobile App Maker',
    logo: 'shopney.jpg',
    link: 'https://shopney.co/',
    storeLink: 'https://apps.shopify.com/shopney-mobile-app',
    docsLink: 'https://support.shopney.co/en/articles/6231972-how-to-integrate-langify-to-shopney',
  },
]



class RecommendedApps extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      category: '',
      data: data,
    };
  }

  /*
  componentDidMount() {
    this.forceUpdate();
    window.addEventListener('focus', this._rerender, false);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this._rerender);
  }
  */

  handleChange = (event) => {
    let newData = [...data];
    if(event.target.value !== 'all') {
      newData = data.filter(item => item.category.replace(' ', '').toLowerCase().indexOf(event.target.value) > -1);
    }

    this.setState({
      category: event.target.value,
      data: newData,
    })
  }


  render() {
    const { classes } = this.props;
    const { category, data } = this.state;
    let lastCategory = '';

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          Recommended Apps

          <FormControl variant="outlined" margin="none" className={classes.formControl}>
            <Select
              id="category-select"
              value={category}
              onChange={this.handleChange}
              margin="none"
              size="small"
              displayEmpty
            >
              <MenuItem value=""><em>Select a Category</em></MenuItem>
              {Object.entries(categories).map((item) => {
                return <MenuItem value={item[0]}>
                  {category === item[0] && <span class="selected-label">Category: </span>}
                  {item[1]}
                </MenuItem>
              })}
            </Select>
          </FormControl>           
        </Typography>
        <Divider className="divider-white" />    



        <div className={classes.root}>
          <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="stretch"
            spacing={10}
            className={classes.gridRoot}
          >
            {
              data.map((item, index) => {
                let divider = null;
                if(lastCategory !== item.category) {
                  lastCategory = item.category;
                  divider = <Grid item xs={12} style={{padding: '12px 0 0 24px'}}>
                    <h2 style={{color: index === 0 ? '#ffffff' : '#1a237e'}}>{item.category}</h2>
                    <Divider />
                  </Grid>
                }

                return <React.Fragment>
                    {divider &&
                      divider
                    }
                    <Card key={`card-${item.title}`} className={classes.cardRoot}>
                      
                      <div className={classes.banner}>
                        <div
                          className={classes.media}
                        >
                          <img src={'/files/images/recommendedApps/' + item.logo.replace('.jpg', '_b.jpg')} alt={item.title}/>
                          <div className={classes.diffusor}></div>
                        </div>

                        <span
                          className={classes.mediaLogo}
                          style={{backgroundImage: `url(/files/images/recommendedApps/${item.logo})`}}
                        />
                      </div>
                      <CardContent className={classes.cardContent}>

                        <Typography gutterBottom variant="h5" component="h2" align="center">
                          {item.title} <br />
                        </Typography>

                        {item.subtitle &&
                          <Typography gutterBottom variant="caption" component="h2" align="center" style={{marginBottom: 12}}>
                            {item.link ?
                              <a href={item.link} target="_blank" className={classes.companyLink}>
                                <Chip label={item.subtitle} color="default" size="small" variant="outlined" style={{cursor: 'pointer'}} />
                              </a>
                            :
                              <Chip label={item.subtitle} color="default" size="small" variant="outlined" />
                            }
                            <br />
                          </Typography>
                        }
                        
                        <Typography variant="body2" color="textSecondary" component="p">
                          {item.description}
                        </Typography>
                      </CardContent>
                    <CardActions className={classes.cardActions}>
                      {item.docsLink &&
                        <Button size="small" color="primary" href={item.docsLink} target="_blank">
                          Documentation
                        </Button>
                      }
                      {item.storeLink &&
                        <Button size="small" color="secondary" variant="contained" href={item.storeLink} target="_blank">
                          Get the App
                        </Button>
                      }
                    </CardActions>
                  </Card>
                </React.Fragment>
              })
            }
          </Grid>
        </div>

      </React.Fragment> 
    )
  }

  _rerender = (event) => {
    console.log('update');
    this.forceUpdate();
  }
}

const mapStateToProps = ({ shop }) => ({
  editLanguage: shop.shop.edit_language
})

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflow: 'hidden',
    padding: 24,
  },
  subtext: {
    padding: 2,
    color: '#999'
  },
  heading: {
    color: '#1a237e',
    opacity: '0.8'
  },
  content: {
    color: '#999'
  },
  spacer: {
    height: 24
  },
  gridRoot: {
    //margin: '20px -40px',
    justifyContent: 'start',
  },
  cardRoot: {
    maxWidth: 325,
    width: 325,
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-between',
    justifyContent: 'start',
    position: 'relative'
  },
  banner: {
    position: 'relative',
    backgroundColor: '#000',
  },
  media: {
    height: 120,
    opacity: 1,
    borderBottom: 'solid 1px #eeeeee',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    }
  },
  mediaLogo: {
    width: 75,
    height: 75,
    position: 'absolute',
    display: 'inline-block',
    top: 60,
    left: 16,
    boxShadow: '2px -2px 10px 0px rgba(0,0,0,0.35)',
    border: 'solid 4px #fff',
    backgroundSize: 'contain'
  },
  cardContent: {
    height: '100%',
    marginTop: 10,
  },
  cardActions: {
    justifyContent: 'end',
    
  },
  diffusor: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.25)'
  },
  companyLink: {
    color: '#1a237e',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  formControl: {
    float: 'right',
    marginTop: -4,
    color: 'white',
    '& .MuiOutlinedInput-input': {
      padding: '12px 32px 12px 20px',
      color: 'white'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, .5) !important',
    },
    '& .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, .5) !important',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, .23) !important',
    },
    '& .MuiSelect-icon': {
      color: 'rgba(255, 255, 255, 0.54) !important',
    }
  },
})

export default withRouter(connect(
  mapStateToProps,
  null,
)(withStyles(styles)(RecommendedApps)))