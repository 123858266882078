import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import { WindowScroller, CellMeasurer, CellMeasurerCache, List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import CircularProgress from '@material-ui/core/CircularProgress';
import TranslateOutlined from '@material-ui/icons/TranslateOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Sticky from 'react-sticky-el';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import RichTextEditor from '../components/RichTextEditor'
import RechargePlans from '../components/RechargePlans'
import ImageField from './ImageField'
import CollapsableFields from './CollapsableFields'
import CustomFields from './CustomFields'
import SeoFields from './SeoFields'
import VariantsFields from './VariantsFields';
import ItemsListSkeleton from './ItemsListSkeleton';
import { Button, MenuList } from '@material-ui/core';
import { updateAutoTranslations } from '../modules/shop'
import API from '../api/api';
import Tooltip from '@material-ui/core/Tooltip';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import DontAskAgainDialog from './DontAskAgainDialog.js';
import TranslationFields from './TranslationFields.js';
import OutOfWordsQuotaDialog from './OutOfWordsQuotaDialog.js';
import JsonEditor from './JsonEditor';

import DeleteIcon from '@material-ui/icons/DeleteOutline';

const REACT_APP_ENABLE_AUTO_TRANSLATIONS = window.loadEnv("REACT_APP_ENABLE_AUTO_TRANSLATIONS", process.env.REACT_APP_ENABLE_AUTO_TRANSLATIONS);

class TranslationField extends React.Component {
  hasMounted = false;
  mouseIsOver = false;

  constructor(props) {
    super(props);

    this.state = {
      usedText: props.target,
      showImportFromOriginalDialog: false,
      showAutoTranslateNotificationsWarning: false,
      showNotEnoughWordsDialog: false,
      anchorEl: null,
    }
  }

  componentDidMount = () => {
    this.hasMounted = true;

    if(this.props.onLoaded) {
      setTimeout(() => {
        if(this.hasMounted) {
          this.props.onLoaded();
        }
      }, 100);      
    }
  }

  componentWillUnmount = () => {
    this.hasMounted = false;
  }

  componentWillReceiveProps(newProps) {
    const wasPending = this.props.isPending;
    const isPending = newProps.isPending;

    if(wasPending === true && isPending === false || newProps.target !== this.state.usedText) {
      this.setState({usedText:newProps.target});
    }

    if(newProps.forceUpdate === false && this.props.forceUpdate === true) {
    //if(newProps.forceUpdate !== this.props.forceUpdate) {
      let data = {
        id: this.props.id,
        changed: this.props.target != newProps.value,
        initial: this.props.initial || '',
        target: newProps.target,
        parentField: newProps.parentField,
      }
      this.handleChange(null, data);
    }
  }

  handleChange = (event, data) => {
    let passThroughData = !event ? data : {
      id: this.props.id,
      changed: this.props.target != event.target.value,
      initial: this.props.target,
      target: event.target.value,
    };

    // Franks Idee
    //data.changed = this.props.target != event.target.value;

    //console.log('handleChange Field', passThroughData);
    this.setState({usedText: !event ? data.target : event.target.value});
    this.props.onChange(event, passThroughData);
  };

  showImportFromOriginalDialog = () => {
    this.setState({showImportFromOriginalDialog: true})
  }
  closeImportFromOriginalDialog = () => {
    this.setState({showImportFromOriginalDialog: false})
  }
  importFromOriginal = () => {
    if(this.props.target != this.props.source) {
      this.setState({usedText: this.props.source})

      this.props.onChange(null, {
        id: this.props.id,
        changed: true,
        initial: this.props.target,
        target: this.props.source,
        parentField: this.props.parentField,
      })
    }

    this.setState({showImportFromOriginalDialog: false});
  }

  updateAnchorEl = (anchorEl) => {
    this.setState({
      anchorEl: anchorEl
    })
  }

  closeAutoTranslateNotificationsWarning = () => {
    this.setState({showAutoTranslateNotificationsWarning: false});
  }

  autoTranslateField = (force = false) => {
    //debugger;
    if(!force && window.location.href.includes('_notifications')) {
      this.setState({showAutoTranslateNotificationsWarning: true});

      return;
    }

    let root = this;
    const { from, to } = this.props;

    // Collect all section source strings in an array
    let stringsArr = {};
    stringsArr[this.props.id] = this.props.source.toString();

    // Handle events that block the process
    let wordsToTranslateNum = this.countWords(stringsArr, true);
    let hasMuchHtml = this.checkHtmlAmount(stringsArr);
    let outQuota = wordsToTranslateNum > this.props.shop.shop.auto_translations.available_words;
    if(hasMuchHtml || outQuota) {
      this.setState({
        showNotEnoughWordsDialog: false,
        showMuchHtmlNotice: false,
      }, () => {
        this.setState({
          loadingTranslations: false,
          showNotEnoughWordsDialog: outQuota,
          showMuchHtmlNotice: hasMuchHtml,
        });
      });
      return;
    }

    // Call API Servie and manage response
    this.setState({
      loadingTranslations: true,
    })
    API.autoTranslateStrings(from, to, stringsArr, (res) => {
      if(res.success === false || !res.auto_translations) {
        this.setState({ 
          loadingTranslations: false, 
          loadingTranslationsError: true, 
        });
        return null;
      }

      this.props.updateAutoTranslations(
        res.auto_translations.total_used_words,
        res.auto_translations.available_words,
      )

      // Update res of all section target fields
      this.setState({
        usedText: res.Strings[this.props.id],
        loadingTranslations: false, 
        loadingTranslationsError: false, 
      });

      this.props.onChange(null, {
        id: this.props.id,
        changed: true,
        initial: this.props.target,
        target: res.Strings[this.props.id],
        parentField: this.props.parentField,
      });
    });
  }

  countWords = (arr, calcOverflow) => {
    let deleteEmptySliceValues = function(inputArray) {
      var r = [];
      inputArray.forEach(function(item) {
        if (item !== "") {
          r.push(item);
        }
      });
      return r
    }

    let sum = Object.values(arr).reduce((pv, cv, ci) => {
      let singleSourceString = cv.toString();
      let charCount = singleSourceString.length;
      let calc_words_by_chars = charCount / 6;
      singleSourceString = singleSourceString.replace(/-/g, " ");
      singleSourceString = singleSourceString.replace(/</g, " ");
      singleSourceString = singleSourceString.replace(/>/g, " ");
      singleSourceString = singleSourceString.replace(/\"/g, " ");
      singleSourceString = singleSourceString.replace(/\n/g, " ");
      let wordSlice = singleSourceString.split(" ");
      wordSlice = deleteEmptySliceValues(wordSlice);

      let diff = calc_words_by_chars - wordSlice.length;
      if (diff < 0) diff = diff * -1;
      let diffPercent = (diff * 100) / wordSlice.length
      let price_words = wordSlice.length;
      if (diffPercent > 10 && calc_words_by_chars > wordSlice.length) {
          price_words = calc_words_by_chars;
      }
      let totalWords = pv + parseInt(price_words);

      return totalWords;
    }, 0);
    return sum;
  }

  checkHtmlAmount = (arr) => {
    if(localStorage.getItem('ly_not_ask_again_MuchHtmlNotice')) {
      return false;
    }
    let htmlCount = 0;
    Object.entries(arr).map((item) => {
      if(item[0] === 'body_html' || item[0] === 'summary_html') {
        let plainTxt = item[1].replace(/<[^>]*>?/gm, '');
        let allWords = this.countWords({[item[0]]: item[1]});
        let txtWords = this.countWords({txt: plainTxt});
        let count = allWords - txtWords;
        if(count > htmlCount) htmlCount = count;
      }
    });
    return htmlCount > 250 ? true : false;
  }

  closeMuchHtmlNotice = () => {
    this.setState({showMuchHtmlNotice: false});
  }
  cancelMuchHtmlNotice = () => {
    this.setState({showMuchHtmlNotice: false});
  }
  submitMuchHtmlNotice = () => {
    if(!this.state.showNotEnoughWordsDialog) {
      this.autoTranslateField(true);
    }
  }

  selectField = (itemId, fieldId) => {
    if(this.props.parentField === 'json-editor') {
      return;
    }

    let hash = fieldId;

    if(this.props.parentField) {
      hash = this.props.parentField + ':' + hash;
      if(this.props.onSubfieldSelected) {
        this.props.onSubfieldSelected(itemId, fieldId, this.props.parentField);
      }
    } else if(this.props.onFieldSelected) {
      this.props.onFieldSelected(itemId, fieldId);
    }

    window.location.hash = hash;
  }

  render() {
    let root = this;

    const {
      loadingTranslations,
      loadingTranslationsError,
    } = this.state;
    const { 
      t,
      classes, 
      id,
      itemId,
      multiline,
      fields,
      deletable,
      to,
      source,
      target,
    } = this.props;

    if(this.props.isHidden) {
      return null
    }

    let usedLabel = ''
    if(id === 'seofields') {
      usedLabel = 'SEO Fields'
    } else if(id === 'variants') {
      usedLabel = 'Variants'
    } else if(id === 'option_names') {
      usedLabel = 'Option Names'
    } else if(id === 'metafields') {
      usedLabel = 'Metafields'
    } else if(id === 'variant_metafields') {
      usedLabel = 'Variant Metafields'
    } else if(id === 'images') {
      usedLabel = 'Images'
    } else if(id === 'customfields') {
      usedLabel = 'Custom'
    }

    const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;
    const fromLanguage = this.props.languages.languages.find(x => x.base == true);
    const toLanguage = this.props.languages.languages.find(x => x.id == this.props.shop.shop.edit_language);

    const rightPadding = deletable === true ? "38px": "0px";
    const rightArrowMargin = deletable === true ? "9px": "11px";
    const leftArrowMargin = deletable === true ? "-30px": "inherit";

    const getEditorVersion = () => {
      //return 'v2';
      if(this.props.shop.shop.langify_features && this.props.shop.shop.langify_features.find(x => x.name === 'editor_v2') && this.props.shop.shop.langify_features.find(x => x.name === 'editor_v2').is_enabled === true) {
        return 'v2';
      } else {
        return 'v1';
      }
    }

    let type = this.props.type;
    if(typeof source === 'string' && this.props.shop.shop.langify_features && this.props.shop.shop.langify_features.find(x => x.name === 'html_text_2_rich_text') && this.props.shop.shop.langify_features.find(x => x.name === 'html_text_2_rich_text').is_enabled === true) {
      const htmlContent = source.match(/^<(p|h1|h2|h3|h4|h5|div|span|ul|script).*>(.*)<\/(p|h1|h2|h3|h4|h5|div|span|ul|script)>$/gim);
      if(type === 'text' && htmlContent && htmlContent.length > 0) {
        type = 'richtext';
      }      
    }

    const open = Boolean(this.state.anchorEl);

    return (
      <React.Fragment>
        {(type !== 'subfields') &&
          <Grid container spacing={3} style={{paddingRight:rightPadding}} className={classNames('field-row', classes.root, `${type}`)}>
            {!fields && type != 'image' &&
              <React.Fragment>
                  <span 
                    onMouseOver={event => {
                      root.updateAnchorEl(event.currentTarget);
                    }} 
                    onMouseLeave={() => {
                      setTimeout(() => {
                        if(!root.mouseIsOver) {
                          root.updateAnchorEl(null);
                        }
                      }, 200);
                    }}
                    className={classes.arrow} style={{marginRight:rightArrowMargin, marginLeft:leftArrowMargin}}
                  >
                    <ChevronRightOutlined
                      onClick={event => {
                        root.updateAnchorEl(event.target);
                      }} 
                    />
                    {loadingTranslations && 
                      <CircularProgress size={24} className={classes.autoTranslationProgress}  />
                    }
                  </span>
                  <Popper
                    id="render-props-popover"
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => {
                      root.updateAnchorEl(null);
                    }}
                    anchororigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformorigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    style={{zIndex: '20000 !important'}}
                  >
                    <Paper>
                      <MenuList dense={true}
                        onMouseEnter={event => {
                          root.mouseIsOver = true;
                        }} 
                        onMouseLeave={() => {
                          root.mouseIsOver = false;
                          root.updateAnchorEl(null);
                        }}>
                        <MenuItem onClick={this.showImportFromOriginalDialog} align="center" dense={true}><small>{t('itemsList.importFromOriginal')}</small></MenuItem>
                        {to !== 'IA' && this.props.parentField !== 'json-editor' && 
                          <MenuItem style={{marginTop:'5px'}} onClick={() => {this.autoTranslateField(false)}} align="center" dense={true}><small>{t('itemsList.autoTranslateSingleField')}</small></MenuItem>
                        }
                      </MenuList>
                    </Paper>
                  </Popper>
              </React.Fragment>
            }
            {(type === 'text' || !type) &&
              <React.Fragment>
               {deletable === true && 
                <div style={{position:"absolute", right: "-4px"}} >
                  <Tooltip title="Delete" arrow>
                    <IconButton aria-label="Delete" onClick={e => {this.props.onDeleteItem(this.props.id)}}>
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>     
                </div>
               }
                <Grid item xs={12} md={6} classes={{item: classes.gridOdd}} onClick={() => this.selectField(itemId, id)}>
                  <TextField
                    label={(this.props.label && this.props.label.length >= 50) ? <Tooltip title={this.props.label} classes={{ arrow: classes.labelTooltipArrow, popper: classes.labelTooltip, tooltip: classes.noMaxWidth }} placement="bottom" arrow><span>{truncate(this.props.label, 50)}</span></Tooltip> : this.props.label}
                    disabled
                    multiline={this.props.multiline}
                    rows={this.props.rows}
                    rowsMax={this.props.rows ? this.props.rows : 1}
                    fullWidth
                    value={this.props.source ? this.props.source : ''}
                    margin="none"
                    variant="outlined"
                    InputLabelProps={{ 
                      style: { pointerEvents: "auto" },
                      shrink: true, 
                      classes: {
                        root: classes.label
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classNames(classes.textField, classes.disabledField),
                        multiline: classes.textField,
                        input: classes.inputField,
                        inputMultiline: classes.inputField,
                      }
                    }}
                    inputProps={{
                      dir: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                    }}
                    className={classNames(classes.textField, classes.targetField)}
                  /> 
                </Grid>   
                <Grid item xs={12} md={6} classes={{item: classes.gridEven}} onClick={() => this.selectField(itemId, id)}>
                  <TextField
                    multiline={this.props.multiline}
                    rows={this.props.rows}
                    rowsMax={this.props.rows ? this.props.rows : 1}
                    fullWidth
                    value={this.state.usedText ? this.state.usedText : ''}
                    onChange={this.handleChange}
                    margin="none"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        root: classes.textField,
                        multiline: classes.textField,
                        input: classes.inputField,
                        inputMultiline: classes.inputField,
                      }
                    }}
                    inputProps={{
                      maxLength: this.props.maxLength ? this.props.maxLength : null,
                      dir: (toLanguage && toLanguage.rtl) ? 'rtl' : 'ltr',
                    }}
                    className={classes.textField}
                    focused={id == this._getFieldIdByHash()}
                  />
                  {(this.props.maxLength && this.state.usedText) &&
                    <Typography variant="caption" className={classes.maxCharCount}>{`${this.state.usedText.length} of ${this.props.maxLength} characters used`}</Typography>
                  }
                </Grid>    

                {this.props.parentField !== 'json-editor' &&
                  <JsonEditor 
                    source={this.props.source}
                    target={this.state.usedText ? this.state.usedText : ''}
                    id={this.props.id}
                    parentField={this.props.parentField}
                    isPending={this.props.isPending}
                    onChange={this.handleChange}
                  />
                }
              </React.Fragment>      
            }
            {type === 'richtext' &&
              <React.Fragment>
                <Grid item xs={12} md={6} classes={{item: classes.gridOdd}} onClick={() => this.selectField(itemId, id)} className={'ly-richtext-editor-source'}>
                  <RichTextEditor
                    id={`${itemId}-${id}-source`}
                    label={this.props.label}
                    version={getEditorVersion()}
                    config={getEditorVersion() === 'v2' ? {
                        editor: {
                          direction: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                          notranslate: true,
                        },
                        toolbar: false,
                        topbar: false,
                        plugins: ['readonly'],
                        buttons: {
                          addbar: [],
                          context: [],
                          topbar: [],
                        }
                      }
                    :
                      {
                        direction: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                        buttons: ['html'],
                        plugins: [],
                      }
                    }
                    value={this.props.source}
                    sourceData={this.props.source}
                    isPending={this.props.isPending} 
                    readOnly={true}
                    onLoaded={this.props.onLoaded ? this.props.onLoaded : null}
                    densed
                  />
                </Grid>             
                <Grid item xs={12} md={6} classes={{item: classes.gridEven}} onClick={() => this.selectField(itemId, id)} className={'ly-richtext-editor-target'}>
                  <RichTextEditor
                    id={`${itemId}-${id}-target`}
                    version={getEditorVersion()}
                    config={getEditorVersion() === 'v2' ? {
                        placeholder: t('itemsList.richtextPlaceholder'),
                        editor: {
                          direction: (fromLanguage && fromLanguage.rtl) ? 'rtl' : 'ltr',
                          notranslate: true,
                        },
                      }
                    :
                      {
                        placeholder: t('itemsList.richtextPlaceholder'),
                        direction: (toLanguage && toLanguage.rtl) ? 'rtl' : 'ltr',
                        //focus: false,
                      }
                    }
                    sourceData={this.props.source}
                    value={this.state.usedText}
                    isPending={this.props.isPending} 
                    onChange={this.handleChange}
                    onLoaded={this.props.onLoaded ? this.props.onLoaded : null}
                    focused={id == this._getFieldIdByHash()}
                    densed
                  />
                  {(this.props.maxLength && this.state.usedText) &&
                    <Typography variant="caption" className={classes.maxCharCount}>{`${this.state.usedText.length} of ${this.props.maxLength} characters used`}</Typography>
                  }
                </Grid>
              </React.Fragment>
            } 
            {type === 'image' &&
              <ImageField 
                source={this.props.source}
                target={this.state.usedText ? this.state.usedText : ''}
                itemId={this.props.id}
                isPending={this.props.isPending}
                onChange={this.handleChange}
              />
            } 
          </Grid>
        }
        {(type === 'subfields' && id !== 'seofields' && id !== 'customfields') &&
          <CollapsableFields 
            itemId={this.props.itemId}
            parentField={id} 
            fields={fields}
            type={type}
            id={id}
            label={usedLabel}
            isPending={this.state.isPending} 
            isFirst={this.props.isFirst}
            forceUpdate={this.props.forceUpdate}
            forceOpen={this.props.forceOpen}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            emptyMessage={this.props.emptyMessage}
            from={this.props.from}
            to={this.props.to}

            onChange={this.handleChange}
            onExpand={this.props.onExpandCollapsableFields}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
          />
        }
        {(type === 'subfields' && id === 'customfields') &&
          <CustomFields 
            itemId={this.props.itemId}
            parentField={id} 
            fields={fields}
            type={type}
            id={id}
            label={usedLabel}
            isPending={this.state.isPending} 
            isFirst={this.props.isFirst}
            forceUpdate={this.props.forceUpdate}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            emptyMessage={this.props.emptyMessage}
            from={this.props.from}
            to={this.props.to}
            filterQuery={this.props.filterQuery}

            onChange={this.handleChange}
            onExpand={this.props.onExpandCollapsableFields}
            onAddItem={this.props.onAddItem}
            onDeleteItem={this.props.onDeleteItem}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
          />
        }
        {(type === 'subfields' && id === 'seofields') &&
          <SeoFields
            itemId={this.props.itemId}
            parentField={id} 
            fields={fields}
            type={type}
            id={id}
            label={usedLabel}
            isPending={this.state.isPending} 
            isFirst={this.props.isFirst}
            forceUpdate={this.props.forceUpdate}
            triggerClearAllTranslations={this.props.triggerClearAllTranslations}
            triggerDiscardChanges={this.props.triggerDiscardChanges}
            from={this.props.from}
            to={this.props.to}
            rawData={this.props.rawData}

            onChange={this.handleChange}
            onExpand={this.props.onExpandCollapsableFields}
            onSubfieldSelected={this.props.onSubfieldSelected}
            onSubfieldsExpand={this.props.onSubfieldsExpand}
            onSubfieldsExpanded={this.props.onSubfieldsExpanded}
            onSubfieldsCollapsed={this.props.onSubfieldsCollapsed}
          />
        }
        <DontAskAgainDialog
          context="ImportFromOriginal"
          open={this.state.showImportFromOriginalDialog}
          onClose={this.closeImportFromOriginalDialog}
          onSubmit={this.importFromOriginal}
          title={t('itemsList.importFromOriginal')}
          content={t('itemsList.importFromOriginalDescription')}
        />
        <DontAskAgainDialog
          context="showAutoTranslateNotificationsWarning"
          open={this.state.showAutoTranslateNotificationsWarning}
          onClose={this.closeAutoTranslateNotificationsWarning}
          onSubmit={() => {this.autoTranslateField(true)}}
          title={t('itemsList.notificationNoticeTitle')}
          content={t('itemsList.notificationNoticeDescription')}
        />
        <DontAskAgainDialog
          context="MuchHtmlNotice"
          open={this.state.showMuchHtmlNotice}
          onClose={this.closeMuchHtmlNotice}
          onCancel={this.cancelMuchHtmlNotice}
          onSubmit={this.submitMuchHtmlNotice}
          title={t('itemsList.htmlInContentTitle')}
          content={t('itemsList.htmlInContentDescription')}
          initialChecked
        />
        <OutOfWordsQuotaDialog
          open={this.state.showNotEnoughWordsDialog}
        />
        
        <Snackbar open={loadingTranslationsError} autoHideDuration={6000} onClose={() => this.setState({loadingTranslationsError: false})}>
          <MuiAlert onClose={() => this.setState({loadingTranslationsError: false})} severity="error" elevation={6} variant="filled">
            {t('system.whoopsMessage')}
          </MuiAlert>
        </Snackbar>
      </React.Fragment>
    );
  }

  _getFieldIdByHash = () => {
    let hash = window.location.hash.substring(1);
    if(hash.indexOf(':') > -1) {
      hash = hash.split(':')[1];
    }
    return hash;
  }
}
TranslationField.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = ({ shop, languages }) => ({
  shop: shop,
  languages: languages,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAutoTranslations
    },
    dispatch
  )

const styles = theme => ({
  root: {
    //backgroundColor: '#ccc !important',
    //paddingLeft: 16,
    position: 'relative !important',
    margin: '0 -12px !important',
    '&.image': {
      display: 'inline-block',
      width: 'auto',
      margin: '0 !important',
      paddingRight: 24,
    }
  },
  textField: {
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
  },
  inputField: {
    padding: '12px 14px !important',
    fontSize: '14px !important',
    //boxSizing: 'border-box',
    height: 'auto !important',
  },
  targetField: {
    borderStyle: 'dashed !important',
  },
  disabledField: {
    backgroundColor: '#fafafa',
  },
  gridItem: {
    margin: '0 -12px !important',
  },
  gridOdd: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0 !important',
      '&>div': {
        marginBottom: 0,
        '& fieldset': {
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }
      },
      '& .ly-editor>div': {
        marginBottom: '0 !important',
        '& .redactor-box': {
          borderBottom: 'none',
          borderBottomLeftRadius: '0 !important',
          borderBottomRightRadius: '0 !important',
        }
      },
    },
  },
  gridEven: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
      '&>div': {
        marginTop: -1,
        '& fieldset': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },
      '& .ly-editor>div': {
        marginTop: -1,
        '& .redactor-box': {
          borderTopLeftRadius: '0 !important',
          borderTopRightRadius: '0 !important',
        }
      },
    },
  },
  arrow: {
    position: 'absolute',
    cursor: 'pointer',
    left: '50%',
    top: '50%',
    margin: '-15px 0 0 -11px',
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& svg': {
      //fontSize: 22,
    }
  },
  imageContainer: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    border: 'solid 1px rgba(0, 0, 0, 0.26)',
  },
  noTranslationFieldsMsg: {
    textAlign: 'center',
    padding: '50px 0'
  },
  infoField: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f1f8e9',
    color: '#33691e',
    '& span': {
      lineHeight: 2,
    },
    '& button': {
      color: '#33691e',
      borderColor: '#33691e',
    }
  },
  warningField: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#feefb3',
    color: '#9f6000',
    '& span': {
      lineHeight: 2,
    },
    '& button': {
      color: '#9f6000',
      borderColor: '#9f6000',
    }
  },
  autoTranslationProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  closeIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    right: 4,
    top: 10
  },
  label: {
    backgroundColor: '#fafafa',
  },
  maxCharCount: {
    position: 'absolute',
    left: 12,
    bottom: -8,
    color: 'rgb(153, 153, 153)',
  },
  noMaxWidth: {
    maxWidth: 'none',
    marginLeft: 50,
  },
  labelTooltip: {
    marginLeft: 50,
  },
  labelTooltipArrow: {
    marginLeft: 50,
  },
});
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(TranslationField))));