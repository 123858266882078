import React, { useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

function ExportImportDialogs(props) {
  const { t } = useTranslation();
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [showImportCompleteDialog, setShowImportCompleteDialog] = useState(false);

  useEffect(() => {
    //console.log(props.showDownloadDialog, props.showImportCompleteDialog);
    if(props.showDownloadDialog === true) {
      onShowDownloadDialog();
    }
    if(props.showImportCompleteDialog === true) {
      onShowImportCompleteDialog();
    }
  }, [props.showDownloadDialog, props.showImportCompleteDialog, props.export_advanced_csv_download_url, props.imported_entries]); 
  
  const onShowDownloadDialog = () => {
    setShowDownloadDialog(true);
  }
  const onCloseDownloadDialog = () => {
    setShowDownloadDialog(false);
  }

  const onShowImportCompleteDialog = () => {
    onCloseDownloadDialog();
    setShowImportCompleteDialog(true);
  }
  const onCloseImportCompleteDialog = () => {
    window.location.reload();
  }

  return <React.Fragment>
    {(props.export_po_download_url || props.export_csv_download_url) ? (
        <Dialog
          open={showDownloadDialog}
          onClose={onCloseDownloadDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('exportFeature.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Trans i18nKey={'exportFeature.info1'}></Trans>
              <br />
              <br />
              <Trans i18nKey={'exportFeature.info2'}>
                <Link href="https://poedit.net/" target="_blank" color="primary" rel="noreferrer"></Link>
              </Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDownloadDialog} color="secondary">
              {t('system.cancel')}
            </Button>


            {props.export_advanced_csv_download_url &&
              <Tooltip title={t('exportFeature.downloadAdvancedCSVTooltip')} placement="top" arrow>
                <Button href={props.export_advanced_csv_download_url} onClick={onCloseDownloadDialog} download variant="outlined" color="secondary">{t('exportFeature.downloadAdvancedCSV')}</Button>
              </Tooltip>
            }

            <Button href={props.export_csv_download_url} onClick={onCloseDownloadDialog} download variant="outlined" color="secondary">{t('exportFeature.downloadCSV')}</Button>
            <Button href={props.export_po_download_url} onClick={onCloseDownloadDialog} download variant="outlined" color="primary">{t('exportFeature.downloadPO')}</Button>
          </DialogActions>
        </Dialog>
      ) : null
    }
    <Dialog
      open={showImportCompleteDialog}
      onClose={onCloseImportCompleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('importFeature.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('importFeature.description', {
            imported_entries: props.imported_entries
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseImportCompleteDialog} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>
}
export default ExportImportDialogs;