import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/StarBorder';
import CloseIcon from '@material-ui/icons/Close';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: '20px'
  },
  paper: {
    height: '350px',
    width: '300px',
    padding: '20px',
    textAlign: 'center'
  },
  box: {
    padding: 20,
  },
  boxHeadline: {
    margin: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  price: {
    marginTop: 20,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '2.25rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  words: {
    marginTop: 20,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.55rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },
  buyButton: {
    marginTop: 30,
  },
  chargeType: {
    marginTop: 20
  },

  
  title: {
    [theme.breakpoints.down('md')]: {marginTop: '0.35em'}
  },
  boxes: {
    marginTop: 24,
  },
  card: {
    margin: 24,
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    margin: '20px 0 32px',
  },
  cardActions: {
    padding: 16,
  },
});



const tiers = [
  {
    title: 'Basic',
    price: '4.99',
    words: '10 000',
    description: ['One-time charge'],
    buttonText: 'Buy Now',
    buttonVariant: 'outlined',
  },
  {
    title: 'Business',
    subheader: 'Most popular',
    price: '8.99',
    words: '20 000',
    description: ['One-time charge'],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
  },
  {
    title: 'Professional',
    price: '19.90',
    words: '50 000',
    description: ['One-time charge'],
    buttonText: 'Buy Now',
    buttonVariant: 'outlined',
  },
];
class RechargePlans extends React.Component {
  onBuy = plan => {
    this.props.onBuy(plan)
  }

  render() {
    const { t, classes, rows, contained } = this.props;

    return (
      <Dialog
        className={classes.dialog}
        onClose={this.props.onClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.visible}
        maxWidth={'lg'}
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '10px',
            margin: 0,
            maxHeight: '100%',
          }
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, .95)'
          }
        }}
      >


        <Grid id={'boxes'} container alignContent="center" justify="center" onClick={(e) => {e.target.id == 'boxes' && this.props.onClose()}}>
        <Grid item xs={12} md={12} lg={10}>
        <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom className={classes.title} onClick={this.props.onClose}>
          {t('buyAutoTranslationWordsDialog.title')}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p" onClick={this.props.onClose}>
          {t('buyAutoTranslationWordsDialog.subtitle')}
        </Typography>
        <Grid id={'boxes'} className={classes.boxes} container alignItems="flex-end" justify="center" onClick={(e) => {e.target.id == 'boxes' && this.props.onClose()}}>
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid id={'boxes'} item key={tier.title} xs={12} sm={tier.title === 'Professional' ? 12 : 6} md={4} onClick={(e) => {e.target.id == 'boxes' && this.props.onClose()}}>
              <Card className={classes.card}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Business' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h4" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      {t('buyAutoTranslationWordsDialog.once')}
                    </Typography>
                  </div>
                  <Divider/>
                  <div className={classes.words} align="center">{tier.words} words</div>
                  {tier.description.map(line => (
                    <Typography component="p" variant="subtitle1" align="center" key={line}>
                      {line}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button onClick={(e) => {this.onBuy(tier.title.toLowerCase())}} fullWidth variant={tier.buttonVariant} color="secondary" size="large">
                    {t('buyAutoTranslationWordsDialog.buyNow')}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        </Grid>
        </Grid>

        <IconButton variant="outlined" onClick={this.props.onClose} color="default" size="medium" style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}}>
          <CloseIcon />
        </IconButton>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withTranslation()(RechargePlans));
