import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import TranslationFields from './TranslationFields';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

class SeoFields extends React.Component {

  cachedFields = [];


  constructor(props) {
    super(props);

    this.state = {
      showInfo: true,
      collapsed: true,
      updateNumber: 0,
      inheritSeoFields: true,
      triggerClearAllTranslations: props.triggerClearAllTranslations,
      page_title: '',
      page_description: '',
    };
  }

  componentDidMount = () => {
    // Calc if SEO fields are are inheriting
    if(this.props.fields[0] && this.props.fields[0].target !== '' || this.props.fields[1] && this.props.fields[1].target !== '') {
      this.setState({ inheritSeoFields: false }, (newState) => {
        this.setState({ 
          page_title: this.getSeoFieldValue('page_title'), 
          page_description: this.getSeoFieldValue('page_description'), 
        })
      })
    }
    //debugger;
    this.setState({ 
      page_title: this.getSeoFieldValue('page_title'), 
      page_description: this.getSeoFieldValue('page_description'), 
    })
  }

  componentWillReceiveProps = (nextProps) => {
    // Calc if SEO fields are are inheriting on discard
    let inheritSeoFields = this.state.inheritSeoFields;
    if(this.props.triggerDiscardChanges !== nextProps.triggerDiscardChanges) {
      if(nextProps.fields[0] && nextProps.fields[0].target !== '' || nextProps.fields[1] && nextProps.fields[1].target !== '') {
        inheritSeoFields = false;
      } else {
        inheritSeoFields = true;
      }
    }

    let triggerClearAllTranslations = this.state.triggerClearAllTranslations;
    if(this.props.triggerClearAllTranslations !== nextProps.triggerClearAllTranslations) {
      triggerClearAllTranslations = !triggerClearAllTranslations;
    }

    this.setState({ 
      inheritSeoFields: inheritSeoFields,
      triggerClearAllTranslations: triggerClearAllTranslations,
    }, (newState) => {
      this.setState({ 
        page_title: this.getSeoFieldValue('page_title'), 
        page_description: this.getSeoFieldValue('page_description'), 
      })
    });
  }

  handleChange = (event, data) => {
    this.props.onChange(null, {
      ...data,
      parentField: this.props.id
    });
  }

  handleInheritToggle = name => event => {
    let checked = event.target.checked;
    this.setState({ 
      [name]: checked,
      triggerClearAllTranslations: checked ? !this.state.triggerClearAllTranslations : this.state.triggerClearAllTranslations,
    }, (newState) => {
      this.setState({ 
        page_title: this.getSeoFieldValue('page_title'), 
        page_description: this.getSeoFieldValue('page_description'), 
      })
    });
  };

  /**
   * Returns the value of a given SEO field depending on state.inheritSeoFields 
   * and if the original fields target has a value or not. If the target has no value it would
   * return field.source instead of field.target
   * 
   * @param {*} fieldId 
   */
  getSeoFieldValue = (fieldId) => {
    let field = null;
    let val = null;
    let maxLength = fieldId === 'page_title' ? 70 : 320;


    if(this.state.inheritSeoFields) {
      let _fieldId = fieldId.replace('page_', '').replace('description', 'body_html');
      field = this.props.rawData.find(x => x.id === _fieldId);

      if(field && field.target == '' && this.props.fields.length) {
        if(field.source !== this.props.fields.find(x => x.id === fieldId).source) {
          field = this.props.fields.find(x => x.id === fieldId);
        }        
      }

    } else {
      field = this.props.fields.find(x => x.id === fieldId);
    }

    if(field) {
      let val = field.target !== '' ? this.convertHtmltoText(field.target) : this.convertHtmltoText(field.source)
      return this.trimSeoField(val, maxLength);
    }
    return '';
  }

  convertHtmltoText(html) {
    if(!html) return false;
    var res = html.replace(/<[^>]*>/g, '');
    return res;
  }

  trimSeoField(string, maxLength = 70) {
    if(!string) return null;
    var trimmedString = string.length > maxLength ? string.substring(0, maxLength - 3) + "..." : string;
    return trimmedString;
  }

  render() {
    const { t, classes, fields, isFirst } = this.props;
    const {} = this.state;

    return (
      <div className={classNames('collapsable-fields', isFirst && 'first', this.props.type, this.props.id, classes.root)}>
        <Typography variant="subtitle1" className={classes.headline} style={{/*marginBottom: this.state.inheritSeoFields ? 0 : -16*/}}>
          {this.state.inheritSeoFields ? t('itemsList.seoPreview') : t('itemsList.seoFields')}
          <FormControlLabel
            control={
              <Switch
                checked={this.state.inheritSeoFields}
                onChange={this.handleInheritToggle('inheritSeoFields')}
                value="inheritSeoFields"
                classes={{
                  root: classes.checkboxRoot
                }}
              />
            }
            classes={{
              label: classes.checkboxLabel
            }}
            labelPlacement="start"
            label={t('itemsList.seoInherit')}
            className={classes.inheritFormControlLabel}
          />   
        </Typography>

        <Collapse in={this.state.inheritSeoFields}>
          <Paper 
            className={classes.paper}
            elevation={1}
          >
            <Typography variant="h4" style={{marginBottom: 4}}>
              { this.state.page_title }
            </Typography>
            <Typography variant="caption" component="p">
              { this.state.page_description }
            </Typography>
          </Paper>  
        </Collapse>
        <Collapse in={!this.state.inheritSeoFields}>
          <div style={{ marginTop: -12 }}>
            <TranslationFields 
              data={fields} 
              nested
              id={this.props.id}
              parentField={'seofields'}
              isPending={this.props.isPending} 
              onChange={this.handleChange} 
              from={this.props.from}
              to={this.props.to}
              forceUpdate={this.props.forceUpdate}
              triggerClearAllTranslations={this.state.triggerClearAllTranslations}
              triggerDiscardChanges={this.props.triggerDiscardChanges}
              type={this.props.type}
            />  
          </div>
        </Collapse>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    marginLeft: '-24px',
    marginRight: '-24px',
    backgroundColor: '#fafafa'
  },
  collapseContainer: {
    
  },
  divider: {
    //margin: '0 -24px',
  },
  headline: {
    padding: '8px 24px 8px 24px', 
    //marginTop: -10,
    position: 'relative'
  },
  label: {
    color: '#555',
    cursor: 'pointer',
    padding: '12px 24px',
    fontSize: '1rem',
  },
  toggleIcon: {
    margin: '-2px 12px 0 0'
  },
  paper: {
    margin: '6px 24px 24px 24px !important',
    padding: '12px',
    '& h4': {
      fontSize: '1.2rem',
    },
    '& p': {
      color: '#555',
    }
  },
  cta: {
    margin: '0 24px 24px 24px !important',
    padding: '12px',
    textAlign: 'center',
    cursor: 'pointer',
    border: 'dashed 1px rgb(92, 107, 192)',
    backgroundColor: 'rgb(235, 237, 247)',
    '& p': {
      color: 'rgb(92, 107, 192)',
    },
    '& span': {
      color: 'rgb(92, 107, 192)',
    },
  },
  checkboxRoot: {
    margin: '-24px 0 0 0', 
  },
  checkboxLabel: {
    margin: '-24px 0 0 0', 
    color: '#757575', 
    fontSize: 12
  },
  inheritFormControlLabel: {
    position: 'absolute',
    bottom: -2,
    right: 24,
  }
});

export default withStyles(styles)(withTranslation()(SeoFields));