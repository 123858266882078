import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import _ from 'lodash';
import API from '../api/api';
import ErrorBoundary from './ErrorBoundary';
import {Controlled as CodeMirror} from 'react-codemirror2';
import { Trans, withTranslation, useTranslation } from 'react-i18next';


window.$ = window.jQuery = $;


class RedactorX extends React.Component {
  app;

  constructor(props) {
    super(props);


    this.state = {
      forceupdate: false,
    };
  }

  componentDidMount() {
    const {
      id,
      data,
      defaultValue,
      readOnly,
      focused,
      onStarted,
      onChange,
      onFocus,
      onBlur,
      onKeydown,
      onKeyup,
      onSourceOpened,
      onSourceClosed,
      onSourceChanged,
      onEditorDestroyed,
      i18n
    } = this.props

    const defaultConfig = {
      //topbar: false,
      context: true,
      plugins: ['alignment', 'imageposition', 'removeformat', /*'fontcolor',*/ 'underline'], //, 'blockcode', 'counter'],
      buttons: {
        addbar: ['paragraph', 'table', 'image', 'embed', 'quote', 'pre', 'line'],
        editor: ['add', 'format'],
        context: ['bold', 'italic', 'link'],
        //context: ['bold', 'italic', 'underline', 'link'],
        toolbar: [],
        topbar: ['html'],
      },
      addbar: {
        add: [],
        hide: ['quote', 'pre'],
      },
      toolbar: {
        hide: ['html', 'deleted'],
      },
      //control: true,
      editor: {
        focus: focused,
        notranslate: true,
        maxHeight: '350px',
        minHeight: '350px',
        lang: i18n.language
        //sync: false,
      },
      format: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol'], // h4, h5, h6, address, dl
      line: false,
      clean: {
        enterinline: true // <-- CHECK BEHAVIOR!!!
      },
      paste: {
        clean: false,
        autoparse: false,
        paragraphize: false,
      },
      image: {
        //select: API.createURL('deprecated_get_images/' + `${this.props.id}`.split('-')[0]),
        states: false,
        upload: function(upload, data) {
          var formData = new FormData();        
          var file = data.files[0];
          formData.append('image', file);
          console.log(file);

          API.uploadMedia(formData, response => {
            if(response.success == true) {

              // create response
              var res = {
                "file": {
                    "url": response.url,
                    "id": response.url
                }
              };

              // call complete
              upload.complete(res, data.e);
            } else {
              // call complete
              upload.complete(response);
            }
          });
        }
      },
      codemirror: {
        lineNumbers: true,
        readOnly: readOnly ? true : false,
        //mode: 'html',
        theme: 'elegant',
        tabSize: 2,
        //specialChars: /[\u0000-\u001f\u007f-\u009f\u00ad\u061c\u200b\u200e\u200f\u2028\u2029\u202d\u202e\u2066\u2067\u2069\ufeff\ufff9-\ufffc]/g,
        specialChars: /[\u0000-\u001f\u007f-\u009f\u00ad\u061c\u200b\u200e\u200f\u2028\u2029\u202d\u202e\u2066\u2067\u2069\ufff9-\ufffc]/g,
      },
      subscribe: {
        'editor.blur': onBlur ? onBlur : null,
        'editor.focus': onFocus ? onFocus : null,
        'editor.before.parse': function(event) {
          var content = event.get('html');
          //if(readOnly) console.log('\n\n\neditor.before.parse (#'+ id +')\n------------------------------------\n', content);
        },
        'editor.parse': function(event) {
          var content = event.get('html');
          //if(readOnly) console.log('\n\n\neditor.parse (#'+ id +')\n------------------------------------\n', content);
          event.set('html', content);
        },
        'editor.unparse': function(event) {
          var content = event.get('html');
          var newHtml = '';
          var $nodes = this.dom(content);
          var nodes = $nodes.getAll();

          for (var i = 0; i < nodes.length; i++) {
            //if(!readOnly) console.log(nodes[i]);
            var node = nodes[i];
            var $node = this.dom(nodes[i]);
            if($node.hasClass('langify-figure') || $node.attr('data-paragraphized')) {
              newHtml += $node.html();
            } else {
              newHtml += node.outerHTML || node.nodeValue;
            }
          }

          //if(readOnly) console.log('\n\n\neditor.unparse (#'+ id +')\n------------------------------------\n', content);
          //console.log(content, newHtml)

          //if(!readOnly) console.log(newHtml);
          event.set('html', newHtml);
        },
        'editor.before.insert': function(event) {
          //if(!readOnly) console.log('\n\n\neditor.before.insert\n------------------------------------\n', event);
          var instance = event.get('instance');
        },
        'editor.insert': function(event) {
          //if(!readOnly) console.log('\n\n\neditor.insert\n------------------------------------\n', event);
          var instance = event.get('instance');
        },
        'editor.change': this.handleChange,
        'source.change': this.handleSourceChange,
        'source.open': onSourceOpened ? onSourceOpened : null,
        'source.close': onSourceClosed ? onSourceClosed : null,


        'block.add': function(event) {
          //if(!readOnly) console.log('\n\n\nblock.add\n------------------------------------\n', event.type);
          /*
          var instance = event.get('instance');
          if (instance.isType('paragraph')) {
              var $block = instance.getBlock();

              // example of manipulation of a block
              $block.html('My text');

              // set caret to the end
              this.app.caret.set($block, 'end')
          }
          */
        },
        'block.remove': function(event) {
          //if(!readOnly) console.log('\n\n\nblock.remove\n------------------------------------\n', event);
        }
      },
    }
    this.config = _.merge(defaultConfig, this.props.config);
    this.app = window.RedactorX('#'+id, this.config);

    // Read-only
    /*
    if(this.props.readOnly) {
      if(this.app) {
        this.app.editor.getEditor().attr('contenteditable', 'false');
        this.app.container.get('source').find('textarea').attr('readonly', 'true');
      }
    }
    */

    // Initial Focus (fix for redactorX bug)
    if (this.props.focused) {
      let $editor = this.app.container.get('main');
      let editor = $editor.get();
      editor.classList.toggle('focused');
    }
  }

  componentWillReceiveProps(newProps) {    
    if (newProps.defaultValue !== this.props.defaultValue) {
      if(this.app && this.app.editor.getContent() !== newProps.defaultValue) {
        if(this.app.source.is()) {
          this.app.source.toggle();
          this.app.editor.setContent({ html: newProps.defaultValue });
          this.app.source.toggle();
        } else {
          this.app.editor.setContent({ html: newProps.defaultValue });
        }
      }
    }
    if (newProps.focused !== this.props.focused) {
      if(newProps.focused == true) this.app.editor.setFocus('start');

      let $editor = this.app.container.get('main');
      let editor = $editor.get();
      editor.classList.toggle('focused');
    }
    // Read-only
    /*
    if (newProps.readOnly !== this.props.readOnly) {
      if(this.app) {
        if(this.props.readOnly) {
          this.app.editor.getEditor().attr('contenteditable', 'false');
          this.app.container.get('source').attr('readonly', 'true');
        } else {
          this.app.editor.getEditor().attr('contenteditable', 'true');
          this.app.container.get('source').attr('readonly', 'false');
        }
      }
    }
    */
  }

  componentWillUnmount() {
    if(this.app) {
      this.app.destroy();
    }
  }

  

  handleChange = (event) => {
    let data = event.params.html;

    // Own stuff here...
    if(!this.props.readOnly) {
      if(this.props.onChange && data !== this.props.defaultValue) {
        this.props.onChange(data);
      }
    }
  }

  handleSourceChange = (event) => {
    var html = this.app.editor.getContent();
    this.handleChange({
      params: {
        html: html
      }
    });

    // Own stuff here...
    if(this.props.onSourceChanged) {
      this.props.onSourceChanged(html);
    }
  }


  render() {
    return (
      <textarea 
        id={`${this.props.id}`} 
        ref={el => this.el = el}
        defaultValue={this.props.defaultValue}
      />
    )
  }
}

export default withTranslation()(RedactorX);