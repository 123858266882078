import React from 'react';
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect, ReactReduxContext } from 'react-redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import API from '../../api/api';
import CollapsableSection from '../../components/CollapsableSection';
import { publishTranslations, unpublishTranslations, refreshTranslations } from '../../modules/themes';
import { updateSwitcherLanguages, uploadFlagIcons } from '../../modules/switcherConfigurator';
import LoadingIndicator from '../../components/LoadingIndicator';
import OptionsMenu from '../../components/OptionsMenu';
import { Resizable } from "re-resizable";
import 'codemirror/lib/codemirror.css'; 
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript.js';
import {Controlled as CodeMirror} from 'react-codemirror2';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import DontAskAgainDialog from '../../components/DontAskAgainDialog.js';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { Alert } from '@material-ui/lab';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import LinkIcon from '@material-ui/icons/Link';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import LocalHistorySelect, { LocalHistory } from '../../components/LocalHistory';
import { CONSOLE_LEVELS } from '@sentry/utils';


import { compare } from 'compare-versions';


function decimalToSemanticVersion(version) {
  if(version.indexOf('.') >= 0) {
    return version;
  }

  var semanticFormat = (version > 10) ? (version/10).toString() : version.toString();

  if(semanticFormat.split('.').length === 1) semanticFormat += '.0.0';
  if(semanticFormat.split('.').length === 2) semanticFormat += '.0';
  return semanticFormat;
}




function TabContainer(props) {
  return (
    <div className={props.className ? props.className : null} style={{ padding: 8 * 3, width: '100%' }}>
      {props.children}
    </div>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}



const fileNames = [];
fileNames['themeScript'] = 'layout/theme.liquid';
fileNames['lyLiquidScript'] = 'snippets/ly-liquid-scripts.liquid';
fileNames['lyCoreScripts'] = 'snippets/ly-core-scripts.liquid';
fileNames['lyGetCustoms'] = 'snippets/ly-get-customs.liquid';
fileNames['lySwitcherFactory'] = 'snippets/ly-switcher-factory.liquid';
fileNames['lyRTLCSS'] = 'assets/ly-rtl.css.liquid';


class ThemesSection extends React.Component {

  lyDelimiter = '{% capture ly_liquid_content %}';
  themeSettings = {};

  constructor(props) {
    super(props);
     
    this.state = {
      tabIndex: 0,
      pending: false,
      settingsChanged: false,
      pending: true,
      noticeShow: false,
      noticeType: 'error',
      blacklistLink: '',
      isPublished: false,
      scrollToTop: true,

      debug: false,
      lazyload: false,
      observe: true,
      observeLinks: false,
      observeImages: true,
      observeCustomContents: true,
      linksBlacklist: [],
      loadJquery: false,
      nonStrict: false,

      removingV1Code: false,
      removingV1CodeProgress: 0,
      removingV1CodeComplete: false,

      customizedScriptExpanded: null,

      isLoadingScript: false,
      isLoadingScriptName: '',
      showConfirmLoadDefaultDialog: false,
      
      themeScript: null,
      currentThemeScript: null,

      lyLiquidScript: null,
      currentLyLiquidScript: null,

      lyCoreScripts: null,
      currentLyCoreScripts: null,

      lyGetCustoms: null,
      currentLyGetCustoms: null,

      lySwitcherFactory: null,
      currentLySwitcherFactory: null,

      lyRTLCSS: null,
      currentLyRTLCSS: null,

      updateScripts: false,
      scriptSettings: null,
    }
  }

  componentDidMount() {
    this.checkThemePublishState();
    setTimeout(this.loadThemeSettings, 100);
  }

  componentWillReceiveProps(newProps) {
    //this.setState({ isPublished: newProps.is_published });

    // published
    if(this.props.publish_transations_pending != newProps.publish_transations_pending && !newProps.publish_transations_pending) {
      this.setState({ noticeShow: false, isPublished: newProps.is_published });
    }
    // unpublished
    if(this.props.unpublish_transations_pending != newProps.unpublish_transations_pending && !newProps.unpublish_transations_pending) {
      this.setState({ noticeShow: true, isPublished: newProps.is_published });
    }
  }

  checkThemePublishState = () => {
    const data = {
      key: 'layout/theme.liquid',
    };
    API.getCustomAsset(this.props.editTheme, data, (res) => {
      if(res.success && res.value) {
        if(res.value.value.indexOf(this.lyDelimiter) == -1) {
          this.setState({
            pending: false,
            noticeShow: true,
            noticeType: 'error',
          });
        } else {
          this.setState({
            pending: false,
            isPublished: true,
          });          
        }
      }
    });
  }

  loadThemeSettings = () => {
    if(!this.props.themes.themes.find(x => x.id == this.props.editTheme)) {
      return;
    }

    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    API.getMetafield('global', `ly_theme_settings_${currentTheme.shopify_id}`, (data) => {
      if(data.success && data.result && data.result.value) {
        let options = JSON.parse(data.result.value);
        if(options) {
          this.themeSettings = options;
          this.setState({...options});
        } 
      }
    });

    API.getScriptSettings(
      currentTheme.shopify_id,
      response => {
        if(response.success == true) {
          const updateScripts = this.checkUpdateScriptsAvailable(response.script_settings);
          this.setState({scriptSettings: response.script_settings, updateScripts: updateScripts, noticeShow: !this.state.isPublished || updateScripts});
        }
    });

    this.setState({
      pending: false,
    });
  }

  checkUpdateScriptsAvailable = (scriptSettings) => {
      const versions = scriptSettings.versions;
      const customized = scriptSettings.customized;

      let updateScripts = false;
      if(
        customized["layout/theme.liquid"] ||
        customized["snippets/ly-core-scripts.liquid"] ||
        customized["snippets/ly-get-customs.liquid"] ||
        customized["snippets/ly-liquid-scripts.liquid"] ||
        customized["snippets/ly-switcher-factory.liquid"] ||
        customized["assets/ly-rtl.css.liquid"]
      ) {
        updateScripts = compare(decimalToSemanticVersion(versions.latest["layout/theme.liquid"]), decimalToSemanticVersion(versions.current["layout/theme.liquid"]), '>') ||
                        compare(decimalToSemanticVersion(versions.latest["snippets/ly-core-scripts.liquid"]), decimalToSemanticVersion(versions.current["snippets/ly-core-scripts.liquid"]), '>') ||
                        compare(decimalToSemanticVersion(versions.latest["snippets/ly-get-customs.liquid"]), decimalToSemanticVersion(versions.current["snippets/ly-get-customs.liquid"]), '>') ||
                        compare(decimalToSemanticVersion(versions.latest["snippets/ly-liquid-scripts.liquid"]), decimalToSemanticVersion(versions.current["snippets/ly-liquid-scripts.liquid"]), '>') ||
                        compare(decimalToSemanticVersion(versions.latest["snippets/ly-switcher-factory.liquid"]), decimalToSemanticVersion(versions.current["snippets/ly-switcher-factory.liquid"]), '>') ||
                        compare(decimalToSemanticVersion(versions.latest["assets/ly-rtl.css.liquid"]), decimalToSemanticVersion(versions.current["assets/ly-rtl.css.liquid"]), '>');
      }

      return updateScripts;
  };

  getCurrentSettings = () => {
    var result = {
      debug: this.state.debug,
      lazyload: this.state.lazyload,
      observe: this.state.observe,
      observeLinks: this.state.observeLinks,
      observeImages: this.state.observeImages,
      observeCustomContents: this.state.observeCustomContents,
      linksBlacklist: this.state.linksBlacklist,
      loadJquery: this.state.loadJquery,
      nonStrict: this.state.nonStrict,
    };
    return result;
  }
  
  handleSaveThemeSettings = () => {
    this.setState({pending: true});
    const options = this.getCurrentSettings();
    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    API.saveCustomMetafield('translation_mode', `ly${currentTheme.shopify_id}`, options.nonStrict ? 'non-strict' : 'strict', 'single_line_text_field', (res) => {

    });
    API.saveCustomMetafield('global', `ly_theme_settings_${currentTheme.shopify_id}`, JSON.stringify(options), 'json_string', (res) => {
      this.themeSettings = options;
      const assetsToSave = [];
      if(!_.isEqual(this.state.themeScript, this.state.currentThemeScript)) {
        assetsToSave.push(
          {
            key: 'layout/theme.liquid',
            value: this.state.themeScript
          }
        );
        LocalHistory.addHistoryEntry('layout/theme.liquid', null, this.state.themeScript, this.state.currentThemeScript);
      }
      if(!_.isEqual(this.state.lyLiquidScript, this.state.currentLyLiquidScript)) {
        assetsToSave.push(
          {
            key: 'snippets/ly-liquid-scripts.liquid',
            value: this.state.lyLiquidScript
          }
        );
        LocalHistory.addHistoryEntry('snippets/ly-liquid-scripts.liquid', null, this.state.lyLiquidScript, this.state.currentLyLiquidScript);
      }
      if(!_.isEqual(this.state.lyCoreScripts, this.state.currentLyCoreScripts)) {
        assetsToSave.push(
          {
            key: 'snippets/ly-core-scripts.liquid',
            value: this.state.lyCoreScripts
          }
        );
        LocalHistory.addHistoryEntry('snippets/ly-core-scripts.liquid', null, this.state.lyCoreScripts, this.state.currentLyCoreScripts);
      }
      if(!_.isEqual(this.state.lyGetCustoms, this.state.currentLyGetCustoms)) {
        assetsToSave.push(
          {
            key: 'snippets/ly-get-customs.liquid',
            value: this.state.lyGetCustoms
          }
        );
        LocalHistory.addHistoryEntry('snippets/ly-get-customs.liquid', null, this.state.lyGetCustoms, this.state.currentLyGetCustoms);
      }

      if(!_.isEqual(this.state.lySwitcherFactory, this.state.currentLySwitcherFactory)) {
        assetsToSave.push(
          {
            key: 'snippets/ly-switcher-factory.liquid',
            value: this.state.lySwitcherFactory
          }
        );
        LocalHistory.addHistoryEntry('snippets/ly-switcher-factory.liquid', null, this.state.lySwitcherFactory, this.state.currentLySwitcherFactory);
      }

      if(!_.isEqual(this.state.lyRTLCSS, this.state.currentLyRTLCSS)) {
        assetsToSave.push(
          {
            key: 'assets/ly-rtl.css.liquid',
            value: this.state.lyRTLCSS
          }
        );
        LocalHistory.addHistoryEntry('assets/ly-rtl.css.liquid', null, this.state.lyRTLCSS, this.state.currentLyRTLCSS);
      }

      API.saveCustomizedAssets(
        this.props.editTheme,
        assetsToSave,
        result => {
          const usedResult = result.slice(-1)[0];
          const scriptSettings = usedResult ? usedResult.script_settings : false;
          
          this.setState({
            loaded: true,
            pending: false,
            settingsChanged: false,
            currentThemeScript: this.state.themeScript,
            currentLyLiquidScript: this.state.lyLiquidScript,
            currentLyCoreScripts: this.state.lyCoreScripts,
            currentLyGetCustoms: this.state.lyGetCustoms,
            currentLySwitcherFactory: this.state.lySwitcherFactory,
            currentLyRTLCSS: this.state.lyRTLCSS,
            scriptSettings: scriptSettings ? scriptSettings : this.state.scriptSettings,
          });
        }
      )
    });
  }

  onImport = (e) => {
    this.setState({ optionsMenuAnchorEl: null });
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    if(fileType != 'json') {
      return;
    }
    reader.onloadend = (evt) => {
      if (evt.target.readyState == FileReader.DONE) {
        const options = JSON.parse(evt.target.result);
        if(options) {
          this.setState({...options}, this.handleSaveThemeSettings);
        }
      }
    };
    var blob = file.slice(0, file.size);
    reader.readAsBinaryString(blob);
  }

  onExport = () => {
    const download = (filename, text) => {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    download(`theme-config-${this.props.editTheme}.json`, JSON.stringify(this.getCurrentSettings()));
  }
 
  onResaveAll = () => {
    this.handleSaveThemeSettings();
  }

  handleChangeTab = (event, tabIndex) => {
    this.setState({ 
      tabIndex,
      scrollToTop: !this.state.scrollToTop,
    });
  };

  handleChange = name => event => {
    let value = null;
    if(event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    if(event.target.type === 'text') {
      value = event.target.value;
    }
    this.setState({ 
      [name]: value,
      settingsChanged: true,  
    }, this.checkSettingsDiff);
  };

  checkSettingsDiff = () => {
    if(
      _.isEqual(this.themeSettings, this.getCurrentSettings()) &&
      _.isEqual(this.state.themeScript, this.state.currentThemeScript) &&
      _.isEqual(this.state.lyLiquidScript, this.state.currentLyLiquidScript) &&
      _.isEqual(this.state.lyCoreScripts, this.state.currentLyCoreScripts) &&
      _.isEqual(this.state.lyGetCustoms, this.state.currentLyGetCustoms) &&
      _.isEqual(this.state.lySwitcherFactory, this.state.currentLySwitcherFactory) &&
      _.isEqual(this.state.lyRTLCSS, this.state.currentLyRTLCSS)
    ) {
      this.setState({settingsChanged: false});
    }
  }

  handlePublishTranslations = (id) => {
    this.props.publishTranslations(id);
    uploadFlagIcons(this.props.languages.languages, this.props.editTheme);
    this.handleSaveThemeSettings();
    this.props.updateSwitcherLanguages(this.props.languages.languages, this.props.editTheme, this.props.themes.themes);
  }

  handleUnpublishTranslations = (id) => {
    this.props.unpublishTranslations(id);
  }

  handleRefreshTranslations = (id) => {
    this.props.refreshTranslations(id);
  }

  handleAddLink = (event) => {
    if(this.state.blacklistLink == '')
      return null;
    this.setState({ 
      linksBlacklist: this.state.linksBlacklist.concat(this.state.blacklistLink),
      blacklistLink: '', 
      settingsChanged: true, 
    });
  }

  handleDeleteLink = (link) => {
    let temp = this.state.linksBlacklist.filter(i => i != link);
    this.setState({ 
      linksBlacklist: temp,
      settingsChanged: true, 
    });
  }

  handleCancel = () => {
    this.setState({
      tabIndex: 0,
      settingsChanged: false,
      ...this.themeSettings,
      themeScript: this.state.currentThemeScript,
      lyLiquidScript: this.state.currentLyLiquidScript,
      lyCoreScripts: this.state.currentLyCoreScripts,
      lyGetCustoms: this.state.currentLyGetCustoms,
      lySwitcherFactory: this.state.currentLySwitcherFactory,
      lyRTLCSS: this.state.currentLyRTLCSS
    });
  }

  deleteV1Code = (id) => {
    this.setState({
      removingV1Code: true,
      removingV1CodeProgress: 0
    })

    API.deleteV1Code(id,
      progress => {
        this.setState({
          removingV1CodeProgress: progress
        })
      },
      () => {
        this.setState({
          removingV1Code: false,
          removingV1CodeComplete: true
        })
      }
    );
  }

  renderGeneralTab = () => {
    const { t, classes } = this.props;
    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    return (
      <TabContainer>
        <FormControl component="fieldset" className={classNames(classes.formControl, 'outer')} style={{margin: 0}}>
          <Typography variant="h6" component="h3">{t('themeSettings.generalTitle')}</Typography>
          {!this.state.isPublished ? 
            <React.Fragment>
              <Typography variant="body2" style={{marginTop: 16, marginBottom: 24}}>{t('themeSettings.generalDescription')}</Typography>
              <div style={{marginTop: 24}}>
                <Button variant="contained" disabled={this.props.publish_transations_pending} elevation={0} size="large" color="secondary" onClick={() => this.handlePublishTranslations(currentTheme.id)} className={classes.publishBtn}>
                  {t('themeSettings.publishButton')}
                  {this.props.publish_transations_pending &&
                    <CircularProgress color="primary" size={16} style={{marginLeft: 10}} />
                  }
                </Button>
              </div>
            </React.Fragment>
          : 
            <React.Fragment>
              <Typography variant="body2" style={{marginTop: 16, marginBottom: 24}}>{t('themeSettings.generalDescriptionPublished')}</Typography>
              <div style={{marginTop: 24}}>
                <Button variant="contained" disabled={(this.props.unpublish_transations_pending || this.props.refresh_transations_pending)} size="medium" color="secondary" onClick={() => this.handleUnpublishTranslations(currentTheme.id)} className={classes.unpublishBtn}>
                  {t('themeSettings.unpublishButton')}
                  {this.props.unpublish_transations_pending &&
                    <CircularProgress color="primary" size={16} style={{marginLeft: 10}} />
                  }
                </Button>
                {/*
                <Button variant="outlined" disabled={(this.props.unpublish_transations_pending || this.props.refresh_transations_pending)} elevation={0} size="medium" color="default" onClick={() => this.handleRefreshTranslations(currentTheme.id)}>
                  Refresh 
                  {this.props.refresh_transations_pending &&
                    <CircularProgress color="primary" size={16} style={{marginLeft: 10}} />
                  }
                </Button>
                */}               
              </div>
            </React.Fragment>
          }
        </FormControl>
      </TabContainer>
    );
  }

  renderCoreTab = () => {
    const { t, classes } = this.props;
    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    
    return (
      <TabContainer className={classes.advancedSettingsWrapper}>
        <Grid container spacing={6}>
          <Grid item xs={12} style={{paddingBottom: 0}}>
            <Typography variant="h6" component="h3">{t('themeSettings.coreSettings')}</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {t('themeSettings.contentObserverTitle')}
                <FormControlLabel
                  className={classes.switchContainer}
                  control={
                    <Switch checked={this.state.observe} onChange={this.handleChange('observe')} value="observe" />
                  }
                  label={<Typography variant="caption">{this.state.observe ? t('system.enabled') : t('system.disabled')}</Typography>}
                  labelPlacement="start"
                />
              </FormLabel>
              <FormGroup>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 0}}>
                  {t('themeSettings.contentObserverInfo')}
                </Typography>      
              </FormGroup>
            </FormControl>
            
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormLabel component="legend">
                  {t('themeSettings.asyncCustomContentsTitle')}
                  <FormControlLabel
                    control={
                      <Switch disabled={!this.state.observe} checked={this.state.observeCustomContents} onChange={this.handleChange('observeCustomContents')} value="observeCustomContents" />
                    }
                    className={classes.switchContainer}
                    label={<Typography variant="caption">{this.state.observeCustomContents ? t('system.enabled') : t('system.disabled')}</Typography>}
                    labelPlacement="start"
                  />
                </FormLabel>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 0}}>
                  {t('themeSettings.asyncCustomContentsInfo')}
                </Typography>          
              </FormGroup>
            </FormControl>
            
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {t('themeSettings.linkCorrectionTitle')}
                <FormControlLabel
                  className={classes.switchContainer}
                  control={
                    <Switch disabled={!this.state.observe} checked={this.state.observeLinks} onChange={this.handleChange('observeLinks')} value="observeLinks" />
                  }
                  label={<Typography variant="caption">{this.state.observeLinks ? t('system.enabled') : t('system.disabled')}</Typography>}
                  labelPlacement="start"
                />   
              </FormLabel>
              <FormGroup>
                <Divider style={{marginTop: 16, marginBottom: 0}} />
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 0}}>
                  {t('themeSettings.linkCorrectionInfo')}
                </Typography>       
                <div className={classes.blacklistForm}>
                  <TextField disabled={(!this.state.observe || !this.state.observeLinks)} value={this.state.blacklistLink} onChange={this.handleChange('blacklistLink')} classes={{root: classes.textField}} placeholder="Enter a link like: /some_specific_site" fullWidth />
                  {this.state.blacklistLink != '' && 
                    <Button onClick={this.handleAddLink} size="small" color="secondary" variant="contained" className={classes.addLinkBtn}>{t('themeSettings.addLink')}</Button>
                  }
                </div>
                <div className={classes.blacklist}>
                  <Table disabled={!this.state.observe}>
                    <TableBody>
                      {this.state.linksBlacklist.length === 0 &&
                        <TableRow className={classes.row} padding="dense">
                          <TableCell component="th" scope="row" padding="checkbox" style={{opacity: (this.state.observe && this.state.observeLinks) ? 1 : .5,  textalign: 'center'}}>
                            <Typography variant="subtitle1" textalign="center">{t('themeSettings.emptyBlacklist')}</Typography>
                          </TableCell>
                        </TableRow>
                      }
                      {this.state.linksBlacklist.map(link => (
                        <TableRow className={classes.row} key={link} padding="dense">
                          <TableCell component="th" scope="row" style={{opacity: (this.state.observe && this.state.observeLinks) ? 1 : .5}}>
                            {link}
                          </TableCell>
                          <TableCell align="right">
                            {(this.state.observe && this.state.observeLinks && link != '') &&
                              <Link onClick={() => this.handleDeleteLink(link)}>{t('system.delete')}</Link>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>                  
                </div>
              </FormGroup>
            </FormControl>  
          </Grid>        
          
          <Grid item xs={12} lg={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormLabel component="legend">
                  {t('themeSettings.imageTranslationTitle')}    
                  <FormControlLabel
                    control={
                      <Switch disabled={!this.state.observe} checked={this.state.observeImages} onChange={this.handleChange('observeImages')} value="observeImages" />
                    }
                    className={classes.switchContainer}
                    label={<Typography variant="caption">{this.state.observeImages ? t('system.enabled') : t('system.disabled')}</Typography>}
                    labelPlacement="start"
                  />
                </FormLabel>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 0}}>
                  {t('themeSettings.imageTranslationInfo')}
                </Typography>     
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {t('themeSettings.lazyloadTitle')}
                <FormControlLabel
                  className={classes.switchContainer}
                  control={
                    <Switch checked={this.state.lazyload} onChange={this.handleChange('lazyload')} value="lazyload" />
                  }
                  label={<Typography variant="caption">{this.state.lazyload ? t('system.enabled') : t('system.disabled')}</Typography>}
                  labelPlacement="start"
                />
              </FormLabel>
              <FormGroup>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 0}}>
                  {t('themeSettings.lazyloadInfo1')}
                </Typography>
                <ul style={{padding: '0 16px', margin: '12px 0 0 0'}}>
                  <li>
                    <Trans i18nKey={'themeSettings.lazyloadInfo2'}></Trans>
                  </li>
                </ul>
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormLabel component="legend">
                  {t('themeSettings.nonStrictTitle')}
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.nonStrict} onChange={this.handleChange('nonStrict')} value="nonStrict" />
                    }
                    className={classes.switchContainer}
                    label={<Typography variant="caption">{this.state.nonStrict ? t('system.enabled') : t('system.disabled')}</Typography>}
                    labelPlacement="start"
                  />
                </FormLabel>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 8}}>
                  {t('themeSettings.nonStrictInfo')}
                </Typography>          
              </FormGroup>
            </FormControl> 

            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormLabel component="legend">
                  {t('themeSettings.debuggingTitle')}
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.debug} onChange={this.handleChange('debug')} value="debug" />
                    }
                    className={classes.switchContainer}
                    label={<Typography variant="caption">{this.state.debug ? t('system.enabled') : t('system.disabled')}</Typography>}
                    labelPlacement="start"
                  />
                </FormLabel>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 8}}>
                  {t('themeSettings.debuggingInfo')}
                </Typography>          
              </FormGroup>
            </FormControl> 
          </Grid>


          
          <Grid item xs={12} style={{paddingBottom: 0}}>
            <Typography variant="h6" component="h3">{t('themeSettings.versionMigration')}</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormLabel component="legend">
                  {t('themeSettings.versionMigration')}
                </FormLabel>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                {this.state.removingV1CodeComplete ? (
                  <Typography variant="body2" style={{marginTop: 16, marginBottom: 16}}>{t('themeSettings.removeV1CodeInfo1')}</Typography>
                ) : (
                  <Typography variant="body2" style={{marginTop: 16, marginBottom: 16}}>{t('themeSettings.removeV1CodeInfo2')}</Typography>
                )}
                <div>
                  <Button variant="outlined" elevation={0} size="medium" color="default" onClick={() => this.deleteV1Code(currentTheme.id)} style={{marginTop: 16}}>
                    {t('themeSettings.removeV1Code')}
                    {this.state.removingV1Code &&
                      <CircularProgress variant="determinate" color="primary" size={16} style={{marginLeft: 10}} value={this.state.removingV1CodeProgress} />
                    }
                  </Button>
                </div>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                <FormLabel component="legend">
                  {t('themeSettings.loadJqueryTitle')}      
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.loadJquery} onChange={this.handleChange('loadJquery')} value="loadJquery" />
                    }
                    className={classes.switchContainer}
                    label={<Typography variant="caption">{this.state.loadJquery ? t('system.enabled') : t('system.disabled')}</Typography>}
                    labelPlacement="start"
                  />
                </FormLabel>
                <Divider style={{marginTop: 16, marginBottom: 0}} /> 
                <Typography variant="body2" style={{marginTop: 16, marginBottom: 0}}>
                  {t('themeSettings.loadJqueryInfo')}
                </Typography>     
              </FormGroup>
            </FormControl>

          </Grid>

        </Grid>   
      </TabContainer>
    );
  }
  
  setGlobalValue = (name, value) => {
    this.setState({ 
      [name]: value,
      settingsChanged: true,
    }, 
      this.checkSettingsDiff
    );
  }

  handleCustomizeScriptsSelect = (panel) => (event, isExpanded) => {
    if(!isExpanded) {
      this.setState({customizedScriptExpanded: ''})
    } else {
      this.loadCustomAsset(panel);
    }
  };
  onCustomizeScriptsSelect = (event, index) => {
    this.loadCustomAsset(index);
  }

  loadCustomAsset = (panel, isExpanded) => {
    if(panel == false) return false;

    this.setState({isLoadingScript: true, isLoadingScriptName: panel});

    if(this.state[panel] === null) {
      API.getCustomAsset(
        this.props.editTheme,
        {key: fileNames[panel]},
        (res) => {
          if(res.success && res.value) {
            this.setState({
              ['current' + panel.charAt(0).toUpperCase()+panel.slice(1)]: res.value.value, 
              [panel]: res.value.value, 
              isLoadingScript: false, 
              isLoadingScriptName: '', 
              customizedScriptExpanded: panel,
              showKnowWhatYouAreDoingDialog: panel !== 'lyRTLCSS' ? true : false,
            });
            this.setGlobalValue(panel, res.value.value);
          }
      });
    } else {
      this.setState({
        isLoadingScript: false, 
        isLoadingScriptName: '', 
        customizedScriptExpanded: panel,
        showKnowWhatYouAreDoingDialog: panel !== 'lyRTLCSS' ? true : false,
      });
    }
  }

  restoreFromLocalAsset = (panel, isExpanded) => {
    this.setState({isLoadingScript: true, isLoadingScriptName: panel});

    API.restoreFromLocalAsset(
      this.props.editTheme,
      {key: fileNames[panel].replace('snippets/', '').replace('layout/', '').replace('assets/', '')},
      (res) => {
        if(res.script_settings) {
          this.setState({
            ['current' + panel.charAt(0).toUpperCase()+panel.slice(1)]: res.value, 
            [panel]: res.value, 
            isLoadingScript: false, 
            isLoadingScriptName: '', 
            scriptSettings: res.script_settings, 
            updateScripts: this.checkUpdateScriptsAvailable(res.script_settings)
          });
          this.setGlobalValue(panel, res.value);
        }
    });
  }

  onCloseLoadDefaultDialog = () => {
    this.setState({showConfirmLoadDefaultDialog: false})
  }
  onSubmitConfirmLoadDefaultDialog = () => {
    this.setState({[this.state.customizedScriptExpanded]: null}, () => {
      this.restoreFromLocalAsset(this.state.customizedScriptExpanded, true);
    });
  }

  handleSelectHistoryEntry = (data) => {
    //console.log(data); //, fileNames, data.id.split('_')[1]);

    const val = data.id.split('_')[1];
    const varName = Object.keys(fileNames).find(key => fileNames[key] === val);

    this.setGlobalValue(varName, data.value);
  }

  renderEntagledInfoIcon = () => {
    const { scriptSettings } = this.state;
    const { t, classes } = this.props;

    return <span style={{position: 'absolute', marginLeft: -28, transform: 'rotate(90deg)'}}>
      {scriptSettings.locked !== null &&
        <Tooltip title={t('themeSettings.customizedEntangledInfo')} arrow>
          <LinkIcon />
        </Tooltip>
      }
    </span>
  }

  renderCustomizeScripts = () => {
    if(!this.props.themes.themes.find(x => x.id == this.props.editTheme) || !this.state.scriptSettings) {
      return;
    }

    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    const { t, classes } = this.props;
    const { scriptSettings, customizedScriptExpanded } = this.state;

    let themeScriptVersionLabel = null;
    let lyLiquidScriptVersionLabel = null;
    let lyCoreScriptsVersionLabel = null;
    let lyGetCustomsVersionLabel = null;
    let lySwitcherFactoryVersionLabel = null;
    let lyRTLCSSVersionLabel = null;

    let themeScriptLatestVersionLabel = null;
    let lyLiquidScriptLatestVersionLabel = null;
    let lyCoreScriptsLatestVersionLabel = null;
    let lyGetCustomsLatestVersionLabel = null;
    let lySwitcherFactoryLatestVersionLabel = null;
    let lyRTLCSSLatestVersionLabel = null;

    let themeScriptUpdateAvailable = false;
    let lyLiquidScriptUpdateAvailable = false;
    let lyCoreScriptsUpdateAvailable = false;
    let lyGetCustomsUpdateAvailable = false;
    let lySwitcherFactoryUpdateAvailable = false;
    let lyRTLCSSUpdateAvailable = false;

    if(scriptSettings && scriptSettings.versions) {
      themeScriptVersionLabel = scriptSettings.versions["current"]["layout/theme.liquid"];
      themeScriptVersionLabel = 'version ' + decimalToSemanticVersion(themeScriptVersionLabel);

      lyLiquidScriptVersionLabel = scriptSettings.versions["current"]["snippets/ly-liquid-scripts.liquid"];
      lyLiquidScriptVersionLabel = 'version ' + decimalToSemanticVersion(lyLiquidScriptVersionLabel);
      
      lyCoreScriptsVersionLabel = scriptSettings.versions["current"]["snippets/ly-core-scripts.liquid"];
      lyCoreScriptsVersionLabel = 'version ' + decimalToSemanticVersion(lyCoreScriptsVersionLabel);
      
      lyGetCustomsVersionLabel = scriptSettings.versions["current"]["snippets/ly-get-customs.liquid"];
      lyGetCustomsVersionLabel = 'version ' + decimalToSemanticVersion(lyGetCustomsVersionLabel);

      lySwitcherFactoryVersionLabel = scriptSettings.versions["current"]["snippets/ly-switcher-factory.liquid"];
      lySwitcherFactoryVersionLabel = 'version ' + decimalToSemanticVersion(lySwitcherFactoryVersionLabel);

      lyRTLCSSVersionLabel = scriptSettings.versions["current"]["assets/ly-rtl.css.liquid"];
      lyRTLCSSVersionLabel = 'version ' + decimalToSemanticVersion(lyRTLCSSVersionLabel);

      themeScriptLatestVersionLabel = scriptSettings.versions["latest"]["layout/theme.liquid"];
      themeScriptLatestVersionLabel = decimalToSemanticVersion(themeScriptLatestVersionLabel);

      lyLiquidScriptLatestVersionLabel = scriptSettings.versions["latest"]["snippets/ly-liquid-scripts.liquid"];
      lyLiquidScriptLatestVersionLabel = decimalToSemanticVersion(lyLiquidScriptLatestVersionLabel);
      
      lyCoreScriptsLatestVersionLabel = scriptSettings.versions["latest"]["snippets/ly-core-scripts.liquid"];
      lyCoreScriptsLatestVersionLabel = decimalToSemanticVersion(lyCoreScriptsLatestVersionLabel);
      
      lyGetCustomsLatestVersionLabel = scriptSettings.versions["latest"]["snippets/ly-get-customs.liquid"];
      lyGetCustomsLatestVersionLabel = decimalToSemanticVersion(lyGetCustomsLatestVersionLabel);

      lySwitcherFactoryLatestVersionLabel = scriptSettings.versions["latest"]["snippets/ly-switcher-factory.liquid"];
      lySwitcherFactoryLatestVersionLabel = decimalToSemanticVersion(lySwitcherFactoryLatestVersionLabel);

      lyRTLCSSLatestVersionLabel = scriptSettings.versions["latest"]["assets/ly-rtl.css.liquid"];
      lyRTLCSSLatestVersionLabel = decimalToSemanticVersion(lyRTLCSSLatestVersionLabel);

      themeScriptUpdateAvailable = compare(decimalToSemanticVersion(scriptSettings.versions["latest"]["layout/theme.liquid"]), decimalToSemanticVersion(scriptSettings.versions["current"]["layout/theme.liquid"]), '>');
      lyLiquidScriptUpdateAvailable = compare(decimalToSemanticVersion(scriptSettings.versions["latest"]["snippets/ly-liquid-scripts.liquid"]), decimalToSemanticVersion(scriptSettings.versions["current"]["snippets/ly-liquid-scripts.liquid"]), '>');
      lyCoreScriptsUpdateAvailable = compare(decimalToSemanticVersion(scriptSettings.versions["latest"]["snippets/ly-core-scripts.liquid"]), decimalToSemanticVersion(scriptSettings.versions["current"]["snippets/ly-core-scripts.liquid"]), '>');
      lyGetCustomsUpdateAvailable = compare(decimalToSemanticVersion(scriptSettings.versions["latest"]["snippets/ly-get-customs.liquid"]), decimalToSemanticVersion(scriptSettings.versions["current"]["snippets/ly-get-customs.liquid"]), '>');
      lySwitcherFactoryUpdateAvailable = compare(decimalToSemanticVersion(scriptSettings.versions["latest"]["snippets/ly-switcher-factory.liquid"]), decimalToSemanticVersion(scriptSettings.versions["current"]["snippets/ly-switcher-factory.liquid"]), '>');
      lyRTLCSSUpdateAvailable = compare(decimalToSemanticVersion(scriptSettings.versions["latest"]["assets/ly-rtl.css.liquid"]), decimalToSemanticVersion(scriptSettings.versions["current"]["assets/ly-rtl.css.liquid"]), '>');
    }

    return (
      <TabContainer className={classes.customizeScriptsWrapper}>
        <React.Fragment>
          <div className="container">

            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={customizedScriptExpanded ? customizedScriptExpanded : false}
              onChange={this.onCustomizeScriptsSelect}
              aria-label="Vertical tabs example"
              className={classes.tabsLvl2}
            >
              <div className={classes.folderLabel}><FolderOpenIcon /> layout/</div>
              <Tab className={classes.tabLvl2} value={'themeScript'} label={<Badge color={'secondary'} badgeContent={themeScriptUpdateAvailable ? 'update' : null} variant="dot" invisible={scriptSettings.customized && !scriptSettings.customized["layout/theme.liquid"]===true} classes={{badge: classes.customizedBadge}}>theme.liquid</Badge>} {...a11yProps('themeScript')} />
              <div className={classes.folderLabel}><FolderOpenIcon /> snippets/</div>
              <Tab className={classes.tabLvl2} value={'lyCoreScripts'} label={<Badge color="secondary" variant="dot" badgeContent={lyCoreScriptsUpdateAvailable ? 'update' : null} invisible={scriptSettings.customized && !scriptSettings.customized["snippets/ly-core-scripts.liquid"]===true} classes={{badge: classes.customizedBadge}}>{this.renderEntagledInfoIcon()}ly-core-scripts.liquid</Badge>} {...a11yProps('lyCoreScripts')} />
              <Tab className={classes.tabLvl2} value={'lySwitcherFactory'} label={<Badge color={lySwitcherFactoryUpdateAvailable ? 'error' : 'secondary'} variant="dot" badgeContent={lySwitcherFactoryUpdateAvailable ? 'update' : null} invisible={scriptSettings.customized && !scriptSettings.customized["snippets/ly-switcher-factory.liquid"]===true} classes={{badge: classes.customizedBadge}}>{this.renderEntagledInfoIcon()}ly-switcher-factory.liquid</Badge>} {...a11yProps('lySwitcherFactory')} />
              <Tab className={classes.tabLvl2} value={'lyLiquidScript'} label={<Badge color="secondary" variant="dot" badgeContent={lyLiquidScriptUpdateAvailable ? 'update' : null} invisible={scriptSettings.customized && !scriptSettings.customized["snippets/ly-liquid-scripts.liquid"]===true} classes={{badge: classes.customizedBadge}}>ly-liquid-scripts.liquid</Badge>} {...a11yProps('lyLiquidScript')} />
              <Tab className={classes.tabLvl2} value={'lyGetCustoms'} label={<Badge color="secondary" variant="dot" badgeContent={lyGetCustomsUpdateAvailable ? 'update' : null} invisible={scriptSettings.customized && !scriptSettings.customized["snippets/ly-get-customs.liquid"]===true} classes={{badge: classes.customizedBadge}}>ly-get-customs.liquid</Badge>} {...a11yProps('lyGetCustoms')} />
              <div className={classes.folderLabel}><FolderOpenIcon /> assets/</div>
              <Tab className={classes.tabLvl2} value={'lyRTLCSS'} label={<Badge color="secondary" variant="dot" badgeContent={!lyRTLCSSUpdateAvailable ? 'update' : null} invisible={scriptSettings.customized && !scriptSettings.customized["assets/ly-rtl.css.liquid"]===true} classes={{badge: classes.customizedBadge}}>ly-rtl.css.liquid</Badge>} {...a11yProps('lyRTLCSS')} />
            </Tabs>

            {!customizedScriptExpanded &&
              <div className={classes.tabPanel} style={{padding: 16, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Typography variant="h6" component="h3">{t('themeSettings.customizeTitle')}</Typography>
                <Typography>
                  <Trans i18nKey={'themeSettings.customizeInfo'}></Trans>
                </Typography>
              </div>
            }
            
            <TabPanel className={classes.tabPanel} value={customizedScriptExpanded} index={'themeScript'}>
              <Typography className={classes.customizeScriptsHeading} component="div">
                layout/theme.liquid
                {scriptSettings ? (<Chip variant="outlined" size="small" label={ themeScriptVersionLabel } className={classes.versionTag} />) : (null)}
                {scriptSettings && scriptSettings.customized && scriptSettings.customized["layout/theme.liquid"] ? (<Chip variant="outlined" size="small" label={t('themeSettings.customized')} className={classNames(classes.versionTag, classes.customizedTag)} />) : (null)}
                {themeScriptUpdateAvailable ? (<Chip variant="outlined" size="small" label={t('themeSettings.customizeUpdateAvailable')} className={classNames(classes.versionTag, classes.updateTag)} />) : (null)}
              </Typography>

              <div className={classes.restoreBtnArea}>
                <LocalHistorySelect
                  id="layout/theme.liquid"
                  language="null"
                  onSelect={this.handleSelectHistoryEntry}
                  currentValue={this.state.themeScript}
                />
                {this.state.customizedScriptExpanded === 'themeScript' ? (
                <Button variant="outlined" disabled={false} size="small" color="secondary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.setState({showConfirmLoadDefaultDialog: true})}} className={classes.restoreBtn}>
                  {t('themeSettings.customizeLoadLatest')} {themeScriptLatestVersionLabel}
                </Button>
                ) : null}
              </div>

              <div className={classes.codeMirrorWrapper}>
                <CodeMirror
                  className={classes.codemirror}
                  value={this.state.themeScript}
                  resizable={true}
                  options={{
                    mode: 'javascript',
                    //theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                  }}
                  onBeforeChange={
                    (editor, data, value) => {
                      this.setGlobalValue('themeScript', value)
                    }
                  }
                />
              </div>
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={customizedScriptExpanded} index={'lyLiquidScript'}>
              <Typography className={classes.customizeScriptsHeading} component="div">
                snippets/ly-liquid-scripts.liquid
                {scriptSettings ? (<Chip variant="outlined" size="small" label={ lyLiquidScriptVersionLabel } className={classes.versionTag} />) : (null)}
                {scriptSettings && scriptSettings.customized && scriptSettings.customized["snippets/ly-liquid-scripts.liquid"] ? (<Chip variant="outlined" size="small" label={t('themeSettings.customized')} className={classNames(classes.versionTag, classes.customizedTag)} />) : (null)}
                {lyLiquidScriptUpdateAvailable ? (<Chip variant="outlined" size="small" label={t('themeSettings.customizeUpdateAvailable')} className={classNames(classes.versionTag, classes.updateTag)} />) : (null)}
              </Typography>

              <div className={classes.restoreBtnArea}>
                <LocalHistorySelect
                  id="snippets/ly-liquid-scripts.liquid"
                  language="null"
                  onSelect={this.handleSelectHistoryEntry}
                  currentValue={this.state.lyLiquidScript}
                />
                {this.state.customizedScriptExpanded === 'lyLiquidScript' ? (
                  <Button variant="outlined" disabled={false} size="small" color="secondary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.setState({showConfirmLoadDefaultDialog: true})}} className={classes.restoreBtn}>
                    {t('themeSettings.customizeLoadLatest')} {lyLiquidScriptLatestVersionLabel}
                  </Button>
                  ) : (
                  null
                  )
                }
              </div>
              
              <div className={classes.codeMirrorWrapper}>
                <CodeMirror
                  className={classes.codemirror}
                  value={this.state.lyLiquidScript}
                  options={{
                    mode: 'javascript',
                    //theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                    
                  }}
                  onBeforeChange={
                    (editor, data, value) => {
                      this.setGlobalValue('lyLiquidScript', value)
                    }
                  }
                />
              </div>
            </TabPanel>
            
            <TabPanel className={classes.tabPanel} value={customizedScriptExpanded} index={'lyCoreScripts'}>
              <Typography className={classes.customizeScriptsHeading} component="div">
                snippets/ly-core-scripts.liquid
                {scriptSettings ? (<Chip variant="outlined" size="small" label={ lyCoreScriptsVersionLabel } className={classes.versionTag} />) : ( null )}
                {scriptSettings && scriptSettings.customized && scriptSettings.customized["snippets/ly-core-scripts.liquid"] ? (<Chip variant="outlined" size="small" label={t('themeSettings.customized')} className={classNames(classes.versionTag, classes.customizedTag)} />) : (null)}
                {lyCoreScriptsUpdateAvailable ? (<Chip variant="outlined" size="small" label={t('themeSettings.customizeUpdateAvailable')} className={classNames(classes.versionTag, classes.updateTag)} />) : (null)}
              </Typography>

              <div className={classes.restoreBtnArea}>
                <LocalHistorySelect
                  id="snippets/ly-core-scripts.liquid"
                  language="null"
                  onSelect={this.handleSelectHistoryEntry}
                  currentValue={this.state.lyCoreScripts}
                />
                {this.state.customizedScriptExpanded === 'lyCoreScripts' ? (
                <Button variant="outlined" disabled={false} size="small" color="secondary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.setState({showConfirmLoadDefaultDialog: true})}} className={classes.restoreBtn}>
                  {t('themeSettings.customizeLoadLatest')} {lyCoreScriptsLatestVersionLabel}
                </Button>
                ) : null }
              </div>

              <div className={classes.codeMirrorWrapper}>
                <CodeMirror
                  className={classes.codemirror}
                  value={this.state.lyCoreScripts}
                  options={{
                    mode: 'javascript',
                    //theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                    
                  }}
                  onBeforeChange={
                    (editor, data, value) => {
                      this.setGlobalValue('lyCoreScripts', value)
                    }
                  }
                />
              </div>
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={customizedScriptExpanded} index={'lyGetCustoms'}>
              <Typography className={classes.customizeScriptsHeading} component="div">
                snippets/ly-get-customs.liquid
                {scriptSettings ? (<Chip variant="outlined" size="small" label={ lyGetCustomsVersionLabel } className={classes.versionTag} />) : ( null )}
                {scriptSettings && scriptSettings.customized && scriptSettings.customized["snippets/ly-get-customs.liquid"] ? (<Chip variant="outlined" size="small" label={t('themeSettings.customized')} className={classNames(classes.versionTag, classes.customizedTag)} />) : (null)}
                {lyGetCustomsUpdateAvailable ? (<Chip variant="outlined" size="small" label={t('themeSettings.customizeUpdateAvailable')} className={classNames(classes.versionTag, classes.updateTag)} />) : (null)}
              </Typography>

              <div className={classes.restoreBtnArea}>
                <LocalHistorySelect
                  id="snippets/ly-get-customs.liquid"
                  language="null"
                  onSelect={this.handleSelectHistoryEntry}
                  currentValue={this.state.lyGetCustoms}
                />
                {this.state.customizedScriptExpanded === 'lyGetCustoms' ? (
                <Button variant="outlined" disabled={false} size="small" color="secondary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.setState({showConfirmLoadDefaultDialog: true})}} className={classes.restoreBtn}>
                  {t('themeSettings.customizeLoadLatest')} {lyGetCustomsLatestVersionLabel}
                </Button>
                ) : (
                null
                )}
              </div>

              <div className={classes.codeMirrorWrapper}>
                <CodeMirror
                  className={classes.codemirror}
                  value={this.state.lyGetCustoms}
                  options={{
                    mode: 'javascript',
                    //theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                    
                  }}
                  onBeforeChange={
                    (editor, data, value) => {
                      this.setGlobalValue('lyGetCustoms', value)
                    }
                  }
                />
              </div>
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={customizedScriptExpanded} index={'lySwitcherFactory'}>
              <Typography className={classes.customizeScriptsHeading} component="div">
                snippets/ly-switcher-factory.liquid
                {scriptSettings ? (<Chip variant="outlined" size="small" label={ lySwitcherFactoryVersionLabel } className={classes.versionTag} />) : ( null )}
                {scriptSettings && scriptSettings.customized && scriptSettings.customized["snippets/ly-switcher-factory.liquid"] ? (<Chip variant="outlined" size="small" label={t('themeSettings.customized')} className={classNames(classes.versionTag, classes.customizedTag)} />) : (null)}
                {lySwitcherFactoryUpdateAvailable ? (<Chip variant="outlined" size="small" label={t('themeSettings.customizeUpdateAvailable')} className={classNames(classes.versionTag, classes.updateTag)} />) : (null)}
              </Typography>
              <div className={classes.restoreBtnArea}>
                <LocalHistorySelect
                  id="snippets/ly-switcher-factory.liquid"
                  language="null"
                  onSelect={this.handleSelectHistoryEntry}
                  currentValue={this.state.lySwitcherFactory}
                />
                {this.state.customizedScriptExpanded === 'lySwitcherFactory' ? (
                <Button variant="outlined" disabled={false} size="small" color="secondary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.setState({showConfirmLoadDefaultDialog: true})}} className={classes.restoreBtn}>
                  {t('themeSettings.customizeLoadLatest')} {lySwitcherFactoryLatestVersionLabel}
                </Button>
                ) : (
                null
                )}
              </div>
              <div className={classes.codeMirrorWrapper}>
                <CodeMirror
                  className={classes.codemirror}
                  value={this.state.lySwitcherFactory}
                  options={{
                    mode: 'javascript',
                    //theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                    
                  }}
                  onBeforeChange={
                    (editor, data, value) => {
                      this.setGlobalValue('lySwitcherFactory', value)
                    }
                  }
                />
              </div>
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={customizedScriptExpanded} index={'lyRTLCSS'}>
              <Typography className={classes.customizeScriptsHeading} component="div">
                assets/ly-rtl.css.liquid
                {scriptSettings ? (<Chip variant="outlined" size="small" label={ lyRTLCSSVersionLabel } className={classes.versionTag} />) : ( null )}
                {scriptSettings && scriptSettings.customized && scriptSettings.customized["assets/ly-rtl.css.liquid"] ? (<Chip variant="outlined" size="small" label={t('themeSettings.customized')} className={classNames(classes.versionTag, classes.customizedTag)} />) : (null)}
                {lyRTLCSSUpdateAvailable ? (<Chip variant="outlined" size="small" label={t('themeSettings.customizeUpdateAvailable')} className={classNames(classes.versionTag, classes.updateTag)} />) : (null)}
              </Typography>
              <div className={classes.restoreBtnArea}>
                <LocalHistorySelect
                  id="assets/ly-rtl.css.liquid"
                  language="null"
                  onSelect={this.handleSelectHistoryEntry}
                  currentValue={this.state.lyRTLCSS}
                />
                {this.state.customizedScriptExpanded === 'lyRTLCSS' ? (
                <Button variant="outlined" disabled={false} size="small" color="secondary" onClick={(e) => {e.stopPropagation(); e.preventDefault(); this.setState({showConfirmLoadDefaultDialog: true})}} className={classes.restoreBtn}>
                  {t('themeSettings.customizeLoadLatest')} {lyRTLCSSLatestVersionLabel}
                </Button>
                ) : (
                null
                )}
              </div>
              <div className={classes.codeMirrorWrapper}>
                <CodeMirror
                  className={classes.codemirror}
                  value={this.state.lyRTLCSS}
                  options={{
                    mode: 'css',
                    //theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                    
                  }}
                  onBeforeChange={
                    (editor, data, value) => {
                      this.setGlobalValue('lyRTLCSS', value)
                    }
                  }
                />
              </div>
            </TabPanel>

            {this.state.isLoadingScript &&
              <div className={classes.codeMirrorLoadingSign}>
                <CircularProgress color="primary" size={20} style={{margin: 'auto'}} thickness={2}/>
              </div>
            }
          </div>
        </React.Fragment>
      </TabContainer>
    );
  }

  render() {
    if(!this.props.themes.themes.find(x => x.id == this.props.editTheme)) {
      return null
    }

    const { t, classes } = this.props;
    const { tabIndex, pending, noticeShow, noticeType, isPublished, updateScripts } = this.state;
    const currentTheme = this.props.themes.themes.find(x => x.id == this.props.editTheme);
    let noticeText = '';
    let _noticeType = noticeType;
    if(!isPublished) {
      noticeText = <span><Trans>{'themeSettings.alertNotPublished'}</Trans></span>
    } else if(updateScripts) {
      noticeText = <span><Trans>{'themeSettings.alertNewerVersion'}</Trans></span>
      _noticeType = 'info';
    }

    return(
      <React.Fragment>
        <CollapsableSection
          deeplink="theme-settings"
          className={classes.collapsableSection}
          title={t('themeSettings.title')}
          ctaBtnHide={!this.state.settingsChanged}
          ctaBtnText={<React.Fragment>{t('system.save')} <SaveAltOutlinedIcon style={{marginLeft: 5}} /></React.Fragment>}
          ctaBtnAction={this.handleSaveThemeSettings}
          ctaBtnProps={{
            disabled: this.state.pending,
          }}
          openBtnHide={false}
          openBtnText={t('themeSettings.configure')}
          cancelBtnHide={false}
          cancelBtnText={t('themeSettings.cancel')}
          noticeShow={(!isPublished && noticeShow) || (updateScripts && noticeShow)}
          noticeType={_noticeType}
          noticeText={noticeText}
          pending={pending}
          onClosed={this.handleCancel}
          //onOpened={this.handleOpened}
          pinSectionOnOpen={true}
          scrollToTop={this.state.scrollToTop}
          content={
            <div className={classes.root}>
              <React.Fragment>
                <Tabs  
                  position="static"
                  value={tabIndex} 
                  onChange={this.handleChangeTab}
                  classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                >
                  <Tab 
                    label={t('themeSettings.generalTab')}
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }} 
                    disableRipple
                  />
                  <Tab 
                    label={t('themeSettings.advancedTab')}
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }} 
                    disabled={!isPublished}
                    disableRipple
                  />
                  <Tab 
                    label={t('themeSettings.customizeTab')}
                    classes={{ root: classes.tabRoot, selected: classes.tabSelected }} 
                    disabled={!isPublished}
                    disableRipple
                  />
                  <OptionsMenu
                    onImport={this.onImport}
                    onExport={this.onExport}
                    onResaveAll={this.onResaveAll}
                    importLabel={t('themeSettings.import')}
                    exportLabel={t('themeSettings.export')}
                    jsonFormat
                    inlined
                  />
                </Tabs>
                <Divider />
              </React.Fragment>
              {tabIndex === 0 && this.renderGeneralTab()}
              {tabIndex === 1 && this.renderCoreTab()}
              {tabIndex === 2 && this.renderCustomizeScripts()}
            </div>
          } 
        />       

        <LoadingIndicator
          pending={this.props.master_loading}
          saving={false}
          progress={0}
          withLogo
        />
        <DontAskAgainDialog
          context="ConfirmLoadDefault"
          open={this.state.showConfirmLoadDefaultDialog}
          onClose={this.onCloseLoadDefaultDialog}
          onSubmit={this.onSubmitConfirmLoadDefaultDialog}
          title={t('themeSettings.customizeConfirmLoadTitle')}
          content={t('themeSettings.customizeConfirmLoadInfo')}
        />


        <DontAskAgainDialog
          context="knowWhatYouAreDoing"
          open={this.state.showKnowWhatYouAreDoingDialog}
          onClose={() => { this.setState({showKnowWhatYouAreDoingDialog: false}); }}
          onCancel={() => { this.setState({customizedScriptExpanded: ''}); }}
          onSubmit={null}
          title={t('themeSettings.customizeWarningTitle')}
          content={<span><Trans i18nKey={'themeSettings.customizeWarning'}></Trans></span>}
          icon={<ErrorOutlineRoundedIcon />}
          visuallyHideCheckbox
          suppressBackdropClick
        />
      </React.Fragment>

    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    margin: 0,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  collapseToggler: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontWeight: '400 !important',
  },
  icon: {
    marginRight: theme.spacing(2),
    opacity: .5,
  },
  contentContainer: {
    //marginBottom: theme.spacing(2),
  },
  card: {
    //maxWidth: 400,
  },
  media: {
    width: '100%',
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#ccc',
  },
  actions: {
    display: 'flex',
  },
  title: {
    fontSize: 16,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardActions: {
    padding: '0px 12px 14px 12px'
  },
  cardHeader: {
    alignItems: 'baseline',
    paddingBottom: 0
  },
  button: {
    marginLeft: 10,
  },
  tabsRoot: {
    //borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1a237e',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 'auto !important',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: '#1a237e',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1a237e',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#1a237e',
    },
  },
  tabSelected: {

  },



  publishBtn: {
    marginRight: 8,
    background: '#009900',
    color: '#ffffff',
    '&:hover': {
      background: '#009900',
      color: '#ffffff',
    }
  },
  unpublishBtn: {
    marginRight: 10,
    backgroundColor: '#ff5252',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ff5252',
      color: '#fff',
    }
  },
  formControl: {
    width: '100%',
    marginTop: 0,
    marginBottom: 24,
    '&.outer': {
      display: 'block',
      marginBottom: 0,
    },
    '& hr': {
      marginTop: '12px !important',
      marginBottom: '0 !important',
      //display: 'none',
    },
    '& p': {
      marginTop: '12px !important',
      marginBottom: '0 !important',
    },
  },
  blacklistForm: {
    marginTop: 10,
    position: 'relative',
  },
  addLinkBtn: {
    position: 'absolute',
    top: 8,
    right: 0,
  },
  blacklist: {
    maxHeight: 250,
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  row: {
    height: 'auto !important',
    padding: '4px 0 !important',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td': {
      padding: '8px !important',
    },
    '& th': {
      padding: '8px !important',
    }
  },
  textField: {
    backgroundColor: theme.palette.background.default,
    '& input': {
      padding: '14px !important',

    }
  },

  advancedSettingsWrapper: {
    height: 'calc(100vh - 225px)',
    overflow: 'hidden',
    overflowY: 'auto',
  },

  customizeScriptsWrapper: {
    height: 'calc(100vh - 225px)',
    overflow: 'hidden',
    paddingTop: '0 !important',
    '&>.container': {
      position: 'relative',
      height: '100%',
    }
  },
  switchContainer: {
    position: 'absolute',
    right: 8,
    top: 'unset',
    height: 16,
  },
  customizeScriptsHeading: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em',
    paddingBottom: '16px',
    paddingLeft: 16,
    whiteSpace: 'nowrap'
  },
  codemirror: {
    fontSize: 14,
    height: '100% !important',
  },

  restoreBtnArea: {
    position: 'absolute',
    right: 0,
    top: -8,
    marginLeft: '20px',
    backgroundColor: 'white',
  },
  restoreBtn: {
    color: '#1a237e',
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid rgba(26, 35, 126, 0.5)',
    marginLeft: 8,
  },

  versionTag: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '-6px -6px -6px 12px'
  },

  updateTag: {
    backgroundColor: '#ff5252',
    borderColor: '#ff5252',
    color: '#fff',
    marginTop: -8,
    marginLeft: '10px'
  },

  customizedTag: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '-6px -6px -6px 12px'
  },


  tabsLvl2: {
    display: 'inline-block',
    float: 'left',
    width: 225,
    height: 'calc(100% + 24px)',
    borderRight: 'solid 1px rgba(0, 0, 0, 0.12)',
    paddingTop: 24,
  },
  tabLvl2: {
    paddingLeft: 28,
    '& .MuiTab-root': {
      textTransform: 'none !important',
      textAlign: 'left !important',
    },
    '& .MuiTab-wrapper': {
      textTransform: 'none !important',
      textAlign: 'left !important',
      alignItems: 'start',
    },
  },
  tabPanel: {
    position: 'absolute',
    left: 226,
    right: 0,
    bottom: 0,
    top: 16,
  },
  folderLabel: {
    fontWeight: 'bold',
    padding: '6px 0',
  },

  codeMirrorWrapper: {
    width: 'calc(100% + 24px)',
    height: 'calc(100vh - 275px)',
    borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
  codeMirrorLoadingSign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.75)',
    position: 'absolute',
    top: 0,
    bottom: -24,
    right: -24,
    left: 226,
    zIndex: 100,
  },
  customizedBadge: {
    top: 12,
    right: -8
  }
});

ThemesSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  themes: state.themes,
  languages: state.languages,
  editTheme: state.themes.edit_theme,
  master_loading: state.master_loading,
  progress: state.progress,
  is_published: state.themes.is_published,
  publish_transations_pending: state.themes.publish_transations_pending,
  unpublish_transations_pending: state.themes.unpublish_transations_pending,
  refresh_transations_pending: state.themes.refresh_transations_pending,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      publishTranslations,
      unpublishTranslations,
      refreshTranslations,
      updateSwitcherLanguages
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ThemesSection))))
