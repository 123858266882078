import React, { useState, useEffect, useRef } from 'react'
import withRouter from 'with-router';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import CancelIcon from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import DoneOutlined from '@material-ui/icons/DoneOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import LayersIcon from '@material-ui/icons/Layers';
import TranslateOutlinedIcon from '@material-ui/icons/TranslateOutlined';
import IsoRoundedIcon from '@material-ui/icons/IsoRounded';
import PlusOneRoundedIcon from '@material-ui/icons/PlusOneRounded';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Input from '@material-ui/core/Input';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import SyncIcon from '@material-ui/icons/Sync';

import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { useSpring, animated } from '@react-spring/web'; // web.cjs is required for IE 11 support

import RechargePlans from './RechargePlans';
import LoadingIndicator from './LoadingIndicator';
import { bulkCountWords, bulkTranslationStart, bulkTranslationGetTasks, bulkTranslationClearTask, prepareCountWords } from '../modules/bulkTranslation.js';
import { syncProducts, resyncProducts } from '../modules/products';
import { syncCollections } from '../modules/collections';
import { syncPages } from '../modules/pages';
import { syncNavigation } from '../modules/navigation';
import { syncBlogs } from '../modules/blogs';
import { syncArticles } from '../modules/articles';
import API from '../api/api';
import { Typography } from '@material-ui/core';
import { CONSOLE_LEVELS } from '@sentry/utils';
import _ from 'lodash';


export const taskKey2DataObject = (key) => {
  let split1 = key.split(':');
  let split2 = split1[2].split('_')
  return {
    store: split1[1],
    to: split2[0],
    scope: split2[1].toLowerCase(),
    type: split2[2].toLowerCase(),
  };
}



window.tasksInterval = null;
const runTasksInterval = (func, force, callback) => {
  let _force = (!force) ? false : true;
  if(_force) {
    clearInterval(window.tasksInterval);
    window.tasksInterval = null;
    //console.log(`runTasksInterval() -> Force-Cleared Interval ${window.tasksInterval}`);
  }
  if(!window.tasksInterval) {
    window.tasksInterval = setInterval(() => {
      func((data) => {
        if(data.success === true && data.tasks) {
          let stopInterval = true;
          for (let [task_key, task_value] of Object.entries(data.tasks)) {
            // Wenn nur ein Task "running" als status hat, soll das Interval nicht gestopt werden
            if(task_value.status === 'running' && stopInterval) {
              stopInterval = false;
            }
          }
          if(stopInterval) {
            clearInterval(window.tasksInterval);
          }

          if(callback) {
            callback(data);
          }
        }
      });
    }, 1000);
  } else {
    //console.log(`runTasksInterval() -> There is already another interval running`);
  }
}


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

function MinusSquare(props) {
  return (<SvgIcon fontSize="inherit" style={{ width: 24, height: 24, backgroundColor: 'white', padding: 4 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}
function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{  width: 24, height: 24, backgroundColor: 'white', padding: 4 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}
function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}
 TransitionComponent.propTypes = {
   /**
    * Show the component; triggers the enter or exit states
    */
   in: PropTypes.bool,
 };
 const StyledTreeItem = withStyles((theme) => ({
   iconContainer: {
     '& .close': {
       opacity: 0.3,
     },
   },
   group: {
     marginLeft: 7,
     paddingLeft: 18,
     borderLeft: `1px dashed #ccc`,
   }
 }))((props) => {
  const classes = useStyles2();

  let [running, setRunning] = useState(false);
  let [visible, setVisible] = useState(true);

  const handleLabelClick = (event, value) => {
    if(!props.disabled) {
      event.preventDefault(); 
      return true;
    } else {
      event.stopPropagation();
      return false;
    }
  };

  const handleSyncClick = (e) => {
    setRunning(true);
    props.handleSync(e, props.nodeId);
  };
 
  return <TreeItem 
    {...props} 
    TransitionComponent={TransitionComponent} 
    //onIconClick={handleIconClick}
    onLabelClick={handleLabelClick}
    label={
      <Tooltip 
        title={(props.disabled && props.nodeId.indexOf('.') === -1) ? 'Synchronize content section' : ''}
        placement="bottom-start" 
        classes={{
          popper: classes.bulkSelectedFieldsTooltipPopper
        }}
        //arrow 
        //disableFocusListener 
        //disableHoverListener 
        //disableTouchListener
      >
        <FormControlLabel 
          disabled={props.disabled}
          style={{width: '100%'}}
          classes={{label: classes.formControlLabel}}
          control={
            <Checkbox 
              checked={props.checked}
              disabled={props.disabled}
            />
          } 
          label={<div style={{width: '100%'}}>
              {props.label} 
              {props.pending &&
                <React.Fragment>
                  {(visible) && 
                    <Button size="small" variant="outlined" color="secondary" disabled={props.running} onClick={handleSyncClick} style={{float: 'right', marginRight: 8, padding: '0 4px 0 0'}}>
                      <SyncIcon className={running ? classes.rotateIcon : null} style={{ fontSize: 18 }} /> 
                      {!running ? 'Sync' : 'Synchronizing...'}
                    </Button> 
                  }
                </React.Fragment>
              }
            </div>
          }
        >
        </FormControlLabel>
      </Tooltip>   
    }
  />
});
 
 




/** 
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * Bulk Translations Settings
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * 
 * 
 * 
 * 
 *  
 */
const BulkTranslationSettingsComponent = (props) => {
  const languages = props.languages.languages;
  const classes = useStyles2();

  const [activeStep, setActiveStep] = useState(0);
  const [expanded, setExpanded] = useState(['products']);
  const [bulkSelectedFields, setBulkSelectedFields] = useState([]);
  const [bulkSelectedLanguages, setBulkSelectedLanguages] = useState([]);

  const [wordsLeft, setWordsLeft] = useState(props.shop.shop.auto_translations.available_words);
  const [totalWordCount, setTotalWordCount] = useState(props.bulkTranslation.total_words);
  const [countProgress, setCountProgress] = useState(props.bulkTranslation.count_progress);
  const [countWordsPending, setCountWordsPending] = useState(props.bulkTranslation.count_words_pending);
  const [translateWordsPending, setTranslateWordsPending] = useState(props.bulkTranslation.translate_words_pending);
  const [showRechargePlans, setShowRechargePlans] = useState(false);
  const [showTaskErrors, setShowTaskErrors] = useState(false);

  const [open, setOpen] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    /*
    runTasksInterval(props.bulkTranslationGetTasks, true, () => {

      console.log(countProgress, totalWordCount, props.bulkTranslation.total_words);
    });
    */



    // Get currently active tasks
    props.bulkTranslationGetTasks((res) => {
      const sumBy = (obj, field) => obj
        .map(item => {
          if(!item[1]) return null;
          return item[1][field];
        })
        .reduce((prev, curr) => prev + curr, 0);

      if(res.tasks && Object.keys(res.tasks).length > 0) {
        let newSelectedFields = [];
        let newSelectedLanguages = [];
        let newOpen = false;
        for(let [taskKey, task] of Object.entries(res.tasks)) {
          newOpen = true;
          let keySegments = taskKey.split(':');
          let objects = keySegments[2].split('_')[1].toLowerCase().split(',');
          if(task.type === 'count') {
            if(!newSelectedLanguages[task.parameter.to]) {
              newSelectedLanguages.push(task.parameter.to);
            }
            if(task.parameter.sub_objects) {
              newSelectedFields = task.parameter.sub_objects.split(',');
            }
            objects.forEach((object) => {
              if(newSelectedFields.indexOf(object) === -1) {
                newSelectedFields.push(object);
              }
            });

            // Start task progress interval when there is a running count task
            if(task.status === 'running') {
              runTasksInterval(props.bulkTranslationGetTasks);
            }
          }
        }
        if(newSelectedFields.length > 0) { setBulkSelectedFields(newSelectedFields); }
        if(newSelectedLanguages.length > 0) { setBulkSelectedLanguages(newSelectedLanguages); }

        // Set stepper step
        if(Object.values(res.tasks).every(x => x.type === 'count' && x.status === 'completed')) { 
          let all_total_words = sumBy(Object.entries(res.tasks), 'WordCount');
          let all_count_jobs_done = sumBy(Object.entries(res.tasks), 'jobs_done');
          let all_count_jobs_count = sumBy(Object.entries(res.tasks), 'jobs_count');
          let new_count_progress = Math.round(all_count_jobs_done / all_count_jobs_count * 100);

          setCountProgress(new_count_progress);
          setTotalWordCount(all_total_words);

          // Nur wenn alle count tasks auf complete sind und count progress > 0 und count words < words left sonst auf step 3
          if(countWordsPending || (all_total_words > wordsLeft) || (new_count_progress === 100 && all_total_words === 0)) 
          {
            setActiveStep(2); 
          } else {
            setActiveStep(3);
          }

          // Wenn alle count tasks completed sind und totalWordCount immernoch auf 0 ist, die tasks flushen
          if(new_count_progress === 100 && all_total_words === 0) {
            clearEntireQueue();
          }
        } 

        if(Object.values(res.tasks).find(x => x.type === 'translate')) { 
          setActiveStep(null);
        }


        // Nur öffnen, wenn es keinen translate Task gibt
        if(Object.values(res.tasks).every(x => x.type === 'count') && newOpen === true) {
          setOpen(newOpen);
        }
      }
    });

    // Reopen entrypoint after more words have been bought or the bulk settings dialog was closed before starting the translation process
    if(window.localStorage.getItem('ly_bulk_translate:reopen_count_dialog')) {
      let reopenData = JSON.parse(window.localStorage.getItem('ly_bulk_translate:reopen_count_dialog'));
      setTotalWordCount(reopenData.totalWordCount);
      setBulkSelectedFields(reopenData.bulkSelectedFields);
      setBulkSelectedLanguages(reopenData.bulkSelectedLanguages);
      setCountProgress(100);
      if(reopenData.totalWordCount > wordsLeft) {
        setActiveStep(2);
      } else {
        setActiveStep(3);
      }
      setOpen(true);
      window.localStorage.removeItem('ly_bulk_translate:reopen_count_dialog');
    }
  }, []);



  // ComponentWillReceiveProps
  useEffect(() => {
    if(props.bulkTranslation.translate_words_pending !== translateWordsPending) setTranslateWordsPending(props.bulkTranslation.translate_words_pending);
    if(props.bulkTranslation.count_words_pending !== countWordsPending) setCountWordsPending(props.bulkTranslation.count_words_pending);
    if(props.bulkTranslation.total_words !== totalWordCount) setTotalWordCount(props.bulkTranslation.total_words);
    if(props.bulkTranslation.count_progress !== countProgress) setCountProgress(props.bulkTranslation.count_progress);
    if(props.shop.shop.auto_translations.available_words !== wordsLeft) setWordsLeft(props.shop.shop.auto_translations.available_words);
    if(props.bulkTranslation.count_words_pending) {
      setCountProgress(props.bulkTranslation.count_progress);
    }
    
    if(
      ((props.bulkTranslation.total_words > props.shop.shop.auto_translations.available_words) && props.bulkTranslation.count_progress === 100 && !props.bulkTranslation.count_words_pending) ||
      props.bulkTranslation.count_words_pending || 
      (props.bulkTranslation.total_words === 0 && props.bulkTranslation.count_progress === 100)
    ) {
      setActiveStep(2);
    }
    if(!props.bulkTranslation.count_words_pending && props.bulkTranslation.count_progress === 100 && 
      (props.bulkTranslation.total_words > 0) && 
      (props.bulkTranslation.total_words <= props.shop.shop.auto_translations.available_words) &&
      activeStep !== null
    ) {
      setActiveStep(3);
    }
  }, [
    props.bulkTranslation.total_words, 
    props.bulkTranslation.count_words_pending, 
    props.bulkTranslation.translate_words_pending, 
    props.bulkTranslation.count_progress, 
    props.bulkTranslation.processes, 
    props.shop.shop.auto_translations.available_words
  ]);


  const prevOpen = usePrevious(props.open);
  useEffect(() => {
    if(prevOpen === false) {
      if(props.open) {
        handleOpen();
      } else {
        handleClose();
      }      
    }
  }, [props.open]);
  const handleOpen = function() {
    setActiveStep(0);
    setOpen(true);
  }
  const handleClose = function() {
    setOpen(false);
  }

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleResetSteps = () => {
    setActiveStep(0);
    setTotalWordCount(0);
    setCountProgress(0);
  };


  const handleUnselectLanguage = (event, langToDelete) => {
    setBulkSelectedLanguages((bulkSelectedLanguages) => bulkSelectedLanguages.filter((language) => language !== langToDelete));
    setTotalWordCount(0);
    setCountProgress(0);
  };
  const handleSelectBulkLanguages = (event) => {
    let theValue = [event.target.value];
    if(Array.isArray(event.target.value)) {
      theValue = event.target.value;
      if(event.target.value.length > 3) {
        theValue = bulkSelectedLanguages;
      }
    }
    setBulkSelectedLanguages(theValue);
    setTotalWordCount(0);
    setCountProgress(0);
  }

  const handleSelectBulkField = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    const first = nodeIds[0];
    if (bulkSelectedFields.includes(first)) {
      setBulkSelectedFields(bulkSelectedFields.filter(id => id !== first));
    } else {
      setBulkSelectedFields([first, ...bulkSelectedFields]);
    }
    setTotalWordCount(0);
    setCountProgress(0);
  }

  const handleStartCountProcess = () => {
    const languages = props.languages.languages;
    const baseLanguage = languages.filter(x => x.base === true)[0];
    const newSelectedFields = bulkSelectedFields.slice();//_getSelectedFieldsObject();


    if(bulkSelectedLanguages.length > 0) {
      setTotalWordCount(0);
      setCountProgress(0);

      props.prepareCountWords();


      // Clear queue
      clearEntireQueue(() => {

        // Iterate selected fields
        let scopeObjects = '';
        let subObject = '';

        newSelectedFields.map(val => {
          if(val.indexOf('.') !== -1){
            subObject += val + ',';
          } else {
            scopeObjects += val + ',';
          }
        });

        // Iterate selected languages
        bulkSelectedLanguages.forEach(language => {
          props.bulkCountWords(props.shopUrl, baseLanguage.code, language, scopeObjects.slice(0, -1), subObject.slice(0, -1), () => {
            runTasksInterval(props.bulkTranslationGetTasks, true);
          });
        });
      });
    }
  }

  const handleStartBulkTranslation = () => {
    setActiveStep(null);
    setTotalWordCount(0);
    setCountProgress(0);
    //setShowTaskErrors(false);

    if(props.showInModalDialog) {
      handleClose();
    }

    Object.entries(props.bulkTranslation.count_tasks).forEach(task => {
      props.bulkTranslationStart(props.shopUrl, task[1].key, () => {
        runTasksInterval(props.bulkTranslationGetTasks, true);
      });

      let taskData = taskKey2DataObject(task[0]);
      let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
      outOfSyncString = `${!outOfSyncString ? '' : outOfSyncString}${taskData.to}_${taskData.scope};`;
      window.localStorage.setItem('ly_bulk_translation:out_of_sync', outOfSyncString);
    });
  }

  const _isEmptyAndParentDisabled = () => {
    const bulkSelectedFieldsCheck = bulkSelectedFields.filter(item => item.indexOf('.') === -1);
    if(bulkSelectedFieldsCheck.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  const _isSelectedAndOutOfSync = () => {
    const bulkSelectedFieldsCheck = bulkSelectedFields.filter(item => item.indexOf('.') === -1);
    let hasOutOfSyncObject = false;
    bulkSelectedFieldsCheck.forEach((value) => {
      if(props[value][`sync_${value}_pending`]) {
        hasOutOfSyncObject = true;
      }
    });
    return hasOutOfSyncObject;
  }

  const handleToggle = (event, nodeIds) => {
    if (event.target.nodeName !== "svg") {
      return;
    }
    setExpanded(nodeIds);
  };


  const openRechargePlans = () => {
    setShowRechargePlans(true)
  }
  const closeRechargePlans = () => {
    setShowRechargePlans(false)
  }
  const onBuyWords = (plan) => {
    API.buyWords(
      plan,
      res => {
        //console.log(res)
      }
    );

    let reopenData = {
      totalWordCount: totalWordCount,
      wordsLeft: wordsLeft,
      bulkSelectedFields: bulkSelectedFields,
      bulkSelectedLanguages: bulkSelectedLanguages
    }
    window.localStorage.setItem('ly_bulk_translate:reopen_count_dialog', JSON.stringify(reopenData));
  }


  const handleSync = (e, scope) => {
    e.stopPropagation();

    let languageId = props.editLanguage;

    switch(scope) {
      case 'products': {
        props.syncProducts(languageId);
        break;
      }
      case 'collections': {
        props.syncCollections(languageId);
        break;
      }
      case 'pages': {
        props.syncPages(languageId);
        break;
      }
      case 'navigation': {
        props.syncNavigation(languageId);
        break;
      }
      case 'blogs': {
        props.syncBlogs(languageId);
        break;
      }
      case 'articles': {
        props.syncArticles(languageId);
        break;
      }
      case 'notifications': {
        props.syncNotifications(languageId);
        break;
      }
    }
  }

  const clearEntireQueue = (callback) => {
    props.bulkTranslationGetTasks((data) => {
      let tasks = data.tasks;
      if(tasks && Object.keys(tasks).length > 0) {
        Object.entries(tasks).forEach((entry, i) => {
          props.bulkTranslationClearTask(entry[0], () => {
            if(i === Object.keys(data.tasks).length-1) {
              if(typeof callback === 'function') callback();
            }
          });
        });
      } else {
        if(typeof callback === 'function') callback();
      }
    });
  }

  const renderFieldSelection = () => {
    //console.log(bulkSelectedFields.includes('products'))
    return <TreeView
       className={classes.root}
       defaultCollapseIcon={<MinusSquare />}
       defaultExpandIcon={<PlusSquare />}
       //defaultEndIcon={<CloseSquare />}
       defaultEndIcon={null}
       onNodeSelect={handleSelectBulkField}
       onNodeToggle={handleToggle}
       expanded={expanded}
       selected={bulkSelectedFields}
       multiSelect
     >
      <StyledTreeItem nodeId="products" label="Products" checked={bulkSelectedFields.includes('products') ? true : false} handleSync={handleSync} disabled={props.products.sync_products_pending} pending={props.products.sync_products_pending}>
        <StyledTreeItem nodeId="products.variants" label="Variants" checked={bulkSelectedFields.includes('products.variants') ? true : false} disabled={(props.products.sync_products_pending || !bulkSelectedFields.includes('products')) ? true : false} />
        <StyledTreeItem nodeId="products.options" label="Options" checked={bulkSelectedFields.includes('products.options') ? true : false} disabled={(props.products.sync_products_pending || !bulkSelectedFields.includes('products')) ? true : false} />
        {/*<StyledTreeItem nodeId="products.metafields" label="Metafields" checked={bulkSelectedFields.includes('products.metafields') ? true : false} disabled={(props.products.sync_products_pending || !bulkSelectedFields.includes('products')) ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="collections" label="Collections" checked={bulkSelectedFields.includes('collections') ? true : false} handleSync={handleSync} disabled={props.collections.sync_collections_pending} pending={props.collections.sync_collections_pending}>
        {/*<StyledTreeItem nodeId="collections.metafields" label="Metafields" checked={bulkSelectedFields.includes('collections.metafields') ? true : false} disabled={props.collections.sync_collections_pending || !bulkSelectedFields.includes('collections') ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="pages" label="Pages" checked={bulkSelectedFields.includes('pages') ? true : false} handleSync={handleSync} disabled={props.pages.sync_pages_pending} pending={props.pages.sync_pages_pending}>
        {/*<StyledTreeItem nodeId="pages.metafields" label="Metafields" checked={bulkSelectedFields.includes('pages.metafields') ? true : false} disabled={props.pages.sync_pages_pending || !bulkSelectedFields.includes('pages') ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="navigation" label="Navigation" checked={bulkSelectedFields.includes('navigation') ? true : false} handleSync={handleSync} disabled={props.navigation.sync_navigation_pending} pending={props.navigation.sync_navigation_pending} />
      <StyledTreeItem nodeId="blogs" label="Blogs" checked={bulkSelectedFields.includes('blogs') ? true : false} handleSync={handleSync} disabled={props.blogs.sync_blogs_pending} pending={props.blogs.sync_blogs_pending}>
        {/*<StyledTreeItem nodeId="blogs.metafields" label="Metafields" checked={bulkSelectedFields.includes('blogs.metafields') ? true : false} disabled={props.blogs.sync_blogs_pending || !bulkSelectedFields.includes('blogs') ? true : false} />*/}
      </StyledTreeItem>
      <StyledTreeItem nodeId="articles" label="Articles" checked={bulkSelectedFields.includes('articles') ? true : false} handleSync={handleSync} disabled={props.articles.sync_articles_pending} pending={props.articles.sync_articles_pending} >
        {/*<StyledTreeItem nodeId="articles.metafields" label="Metafields" checked={bulkSelectedFields.includes('articles.metafields') ? true : false} disabled={props.articles.sync_articles_pending || !bulkSelectedFields.includes('articles') ? true : false} />*/}
      </StyledTreeItem>
     </TreeView>
   }

   const renderBulkLanguagesSelection = () => {
    return <FormControl variant="outlined" className={classes.formControl} fullWidth>
      {bulkSelectedLanguages.length === 0 &&
        <Typography className={classes.languagesSelectorPlaceholder}>
          Select a language here...
        </Typography>
      }
      <Select
        id="bulk-mutiple-languages-chip"
        max
        multiple
        displayEmpty
        value={bulkSelectedLanguages}
        onChange={handleSelectBulkLanguages}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {bulkSelectedLanguages.map((value) => {
              const currentLanguage = props.languages.languages.filter(x => x.code == value)[0];
              if(!currentLanguage) {
                return null;
              }
              return <Chip 
                key={value} 
                label={currentLanguage.name} 
                className={classes.chip} 
                deleteIcon={<CancelIcon onMouseDown={(e) => { e.stopPropagation() }} />}
                onDelete={(e) => handleUnselectLanguage(e, value)}
              />
            })}
          </div>
        )}
        fullWidth
        inputProps={{ 
          'aria-label': 'Without label'
        }}
        MenuProps={{
          classes: { 
            paper: classes.menuPaper 
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        style={{
          paddingLeft: '4px !important',
        }}
      >
        {props.languages.languages.map((language) => {
          if(language.base === true) {
            return null;
          }
          return (
            <MenuItem key={language.id} value={language.code}>
              {language.name}
            </MenuItem>
          )}
        )}
      </Select>
      <FormHelperText>You can select up to three target languages.</FormHelperText>
    </FormControl>
   }

   const renderStepperActionsContainer = () => {
      return <div className={classes.actionsContainer}>
        {_isSelectedAndOutOfSync() && activeStep === 1 &&
          <Typography variant="caption" display="block" gutterBottom style={{color: 'red'}}>
            One or more selected fields would have to be synched before the can be used.
          </Typography>
        }
        <div>
          <Button
            disabled={activeStep === 0}
            onClick={handleBackStep}
            className={classes.button}
            variant="outlined"
          >
            <KeyboardBackspaceIcon style={{marginRight: 4}} /> Back
          </Button>

          {activeStep <= 1 && 
            <Button
              disabled={(activeStep === 0 && bulkSelectedLanguages.length === 0) || 
                        (activeStep === 1 && (bulkSelectedFields.length === 0 || _isEmptyAndParentDisabled() || _isSelectedAndOutOfSync()))}
              variant="contained"
              color="secondary"
              onClick={handleNextStep}
              className={classes.button}
            >
              Next step
            </Button>
          }

          {activeStep === 2 && 
            <React.Fragment>
              {totalWordCount > wordsLeft && countProgress === 100 && !countWordsPending &&
                <Button onClick={openRechargePlans} className={classes.button} variant="contained" color="secondary" autoFocus>
                  Get more words
                </Button> 
              }         
              <Button
                disabled={countWordsPending}
                variant="contained"
                color="secondary"
                onClick={handleStartCountProcess}
                className={classes.button}
              >
                {!countWordsPending ? 
                  <React.Fragment>
                    {countProgress === 100 ? 'Count again' : 'Start counting'}                  
                  </React.Fragment>
                  :
                  'Counting...'
                }
                <span className={classes.loadingSpinnerContainer}>
                  {countWordsPending &&
                    <CircularProgress className={classes.loadingSign} size={25} />
                  }
                </span>
              </Button>
              <Button
                //disabled={props.bulkTranslation.count_words_pending && totalWordCount <= wordsLeft && totalWordCount > 0 && countProgress === 100}
                disabled={
                  countWordsPending ||
                  (countProgress < 100) ||
                  (totalWordCount > wordsLeft) ||
                  (totalWordCount === 0 && countProgress === 100)
                }
                variant="contained"
                color="secondary"
                onClick={handleNextStep}
                className={classes.button}
              >
                Next step
              </Button>
            </React.Fragment>
          }

          {activeStep === 3 && 
            <Button variant="contained" 
              color="secondary" 
              className={classes.button} 
              onClick={handleStartBulkTranslation}
            >
              Start bulk translation
            </Button>
          }
        </div>
      </div>
    }


   const renderStepper = () => {
    return <div id="BulkTranslationsConfigurationPanel" className={classes.root}>
      <Typography>
        In the following steps you can setup and start a bulk-translation process in order to automatically translate all contents of a section at once:
      </Typography>
      {/*
      <Button variant="outlined" size="small" onClick={clearEntireQueue}>Clear entire queue</Button>
      <Button variant="outlined" size="small" onClick={() => {runTasksInterval(props.bulkTranslationGetTasks, true)}}>Run Interval</Button>
      */}
      <Stepper 
        activeStep={activeStep} 
        className={classes.stepperRoot} 
        orientation="vertical"
      >
        <Step key={'step_1'}>
          <StepLabel>Step 1: Select the target languages</StepLabel>
          <StepContent>
            {renderBulkLanguagesSelection()}
            {renderStepperActionsContainer()}
          </StepContent>
        </Step>

        <Step key={'step_2'}>
          <StepLabel StepIconComponent={null}>Step 2: Select the contents you'd like to bulk translate</StepLabel>
          <StepContent>
            <Typography variant="body2" style={{margin: '8px 0'}}>
              Please note, that you would have to synchronize some contents, before they can be selected for bulk translation.
            </Typography>
            {renderFieldSelection()}
            {renderStepperActionsContainer()}
          </StepContent>
        </Step>

        <Step key={'step_3'}>
          <StepLabel>Step 3: Count Words</StepLabel>
          <StepContent>
            <Typography variant="body1" classes={{ root: classes.text }} gutterBottom id="alert-dialog-description">
              Before the bulk translation can be started, the words have to be counted.
            </Typography>

            {countWordsPending && (countProgress <= 100) && 
              <DialogContentText variant="body1">Scanned {countProgress}% and found {totalWordCount} words to translate.</DialogContentText>
            }
            {!countWordsPending && countProgress === 100 && (totalWordCount > wordsLeft) &&
              <Typography>
                Your selection contains {new Intl.NumberFormat('en-US').format(totalWordCount)} words, but you don't have enough words left to translate everything.
                Please purchase at least <strong style={{color:'#1a237e'}}>{new Intl.NumberFormat('en-US').format(totalWordCount - wordsLeft)}</strong> words.
              </Typography>
            }
            {!countWordsPending && countProgress === 100 && totalWordCount === 0 &&
              <DialogContentText variant="body1">Found no words to translate.</DialogContentText>
            }
            {!countWordsPending && countProgress === 100 && (totalWordCount > 0 && totalWordCount <= wordsLeft) &&
              <DialogContentText variant="body1">
                Found <strong>{totalWordCount}</strong> words to translate.
              </DialogContentText>
            }

            {(props.bulkTranslation.task_errors.length > 0 && countProgress === 100) &&
              <DialogContentText variant="body1" classes={{ root: classes.errorText }}>
                <Tooltip title={showTaskErrors ? 'Hide all errors' : 'Show all errors'} arrow>
                  <span onClick={(e) => {e.preventDefault(); setShowTaskErrors(!showTaskErrors);}} style={{cursor: 'pointer'}}>{props.bulkTranslation.task_errors.length} fields could not be counted.</span>
                </Tooltip>
                <Collapse in={showTaskErrors}>
                  {props.bulkTranslation.task_errors.map(item => <Typography><small>{item}</small></Typography>)}
                </Collapse>
              </DialogContentText>
            }

            {renderStepperActionsContainer()}
          </StepContent>
        </Step>

        <Step key={'step_4'}>
          <StepLabel>Step 4: Bulk Translation</StepLabel>
          <StepContent>
            <React.Fragment>
              <Typography variant="body1" classes={{ root: classes.text }} gutterBottom id="alert-dialog-description">
                Everything is looking good now. Please press the button below to start the translation process.
              </Typography>

              <DialogContentText variant="body1">
                Found <strong>{totalWordCount}</strong> words to translate.
              </DialogContentText>
            </React.Fragment>
            {renderStepperActionsContainer()}
          </StepContent>
        </Step>
      </Stepper>

      <RechargePlans
        visible={showRechargePlans}
        onClose={closeRechargePlans}
        onBuy={onBuyWords}
      />
    </div>
   }
   
   return <React.Fragment>
    {props.showInModalDialog ?
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={handleOpen}>Bulk Translation</Button>
        <Dialog
          open={open}
          onClose={handleClose}
          disableBackdropClick={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(255, 255, 255, .5)'
            }
          }}
        >


          <DialogTitle id="alert-dialog-title">
            <Badge color="default" badgeContent="beta" classes={{badge: classes.badge}}>
              Bulk Translation
            </Badge>
          </DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          <DialogContent>
            {renderStepper()}
          </DialogContent>
        </Dialog>
    </React.Fragment>
    :
      renderStepper()
    }
  </React.Fragment>
 }
 const useStyles2 = makeStyles((theme) => ({
  badge: {
    backgroundColor: '#ececec',
    padding: '4px 8px',
    transform: 'scale(1) translate(100%, -25%)',
  },
  root: {
    width: '100%',
  },
  stepperRoot: {
    padding: '24px 0',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  menuPaper: {
    maxHeight: 225,
  },
  errorText: {
    color: 'red !important',
  },
  languagesSelectorPlaceholder: {
    position: 'absolute',
    padding: '11px 16px',
    fontStyle: 'italic',
    color: 'grey'
  },
  chip: {
    margin: '-8px 4px',
  },
  loadingSign: {
    position: 'relative',
    margin: 4,
  },
  loadingSpinnerContainer: {
    margin: 'auto', 
    top: 1,
    position: 'absolute',
  },
  formControlLabel: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  },
  bulkSelectedFieldsTooltipPopper: {
   zIndex: '100000 !important',
   marginTop: -40,
  }
}));
const mapStateToProps2 = ({ shop, stats, bulkTranslation, languages, products, collections, pages, navigation, blogs, articles, notifications }) => ({
  shop,
  languages,
  stats: stats,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  bulkTranslation,
  products, 
  collections, 
  pages, 
  navigation, 
  blogs, 
  articles, 
  notifications
});
const mapDispatchToProps2 = dispatch =>
  bindActionCreators(
    {
      prepareCountWords,
      bulkCountWords,
      bulkTranslationStart,
      bulkTranslationGetTasks,
      bulkTranslationClearTask,
      syncProducts,
      syncCollections,
      syncPages,
      syncNavigation,
      syncBlogs,
      syncArticles,
    },
    dispatch
  )
export const BulkTranslationSettings = withRouter(connect(
  mapStateToProps2,
  mapDispatchToProps2
)(BulkTranslationSettingsComponent));
























/**
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * Bulk Translation Status Card Component
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * 
 * Durch alle session storage entries durchiterieren und alle entries mit "ly_bulk_translate:" im Namen sammeln.
 * "ly_bulk_translate:<SHOP URL>:<TARGET LANGUAGE>_<SCOPE>" Prefix aus den Namen der gefundenen Entries entfernen und den rest bei '_' splitten damit man an die entsprechende editLanguage sowie den Scope gelangt
 * 
 * 
 * 
 */
function BulkTranslationStatusCardComponent(props) {
  const languages = props.languages.languages;
  const currentLanguage = languages.filter(x => x.id === parseInt(props.shop.shop.edit_language))[0];
  const baseLanguage = languages.filter(x => x.base === true)[0];

  const classes = useStyles();

  const [showCard, setShowCard] = useState(false);
  const [showTaskErrors, setShowTaskErrors] = useState(false);
  const [targetLanguageCode, setTargetLanguageCode] = useState(null);
  const [translateProgress, setTranslateProgress] = useState(props.bulkTranslation.count_progress);
  const [translateWordsPending, setTranslateWordsPending] = useState(props.bulkTranslation.translate_words_pending);
  const [allTranslateJobsCount, setAllTranslateJobsCount] = useState(props.bulkTranslation.all_translate_jobs_count);
  const [allTranslateJobsDone, setAllTranslateJobsDone] = useState(props.bulkTranslation.all_translate_jobs_done);
  const [translateTasks, setTranslateTasks] = useState(props.bulkTranslation.translste_tasks);
  const [outOfSyncSections, setOutOfSyncSections] = useState({});
  const [outOfSyncButtons, setOutOfSyncButtons] = useState([]);



  useEffect(() => {
    props.bulkTranslationGetTasks((res) => {
      if(res.tasks) {
        for(let [taskKey, task] of Object.entries(res.tasks)) {
          let cacheKey = taskKey;
          let keySegments = taskKey.split(':');
          let objects = keySegments[1].split('_')[1];

          // Start the progress interval when there is a translate task
          if(task.type === 'translate' && task.status === 'running') {
            runTasksInterval(props.bulkTranslationGetTasks);
            //break;
          }
        }
      }
    });

    if(window.localStorage.getItem('ly_bulk_translation:out_of_sync')) {
      setShowCard(true);
    }
  }, []);

  useEffect(() => {
    if(props.bulkTranslation.translate_progress !== translateProgress) setTranslateProgress(props.bulkTranslation.translate_progress);
    if(props.bulkTranslation.translate_words_pending !== translateWordsPending) setTranslateWordsPending(props.bulkTranslation.translate_words_pending);
    if(props.bulkTranslation.all_translate_jobs_count !== allTranslateJobsCount) setAllTranslateJobsCount(props.bulkTranslation.all_translate_jobs_count);
    if(props.bulkTranslation.all_translate_jobs_done !== allTranslateJobsDone) setAllTranslateJobsDone(props.bulkTranslation.all_translate_jobs_done);
    if(!_.isEqual(props.bulkTranslation.translate_tasks, translateTasks)) setTranslateTasks(props.bulkTranslation.translate_tasks);
    
    if(
      (props.bulkTranslation.translate_words_pending) || 
      (props.bulkTranslation.translate_progress === 100)
    ) {
      setShowCard(true);
    } else {
      //setShowCard(false);
    }

    let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
    if(outOfSyncString) {
      let outOfSyncLanguages = outOfSyncString.split(';');
      let newOutOfSyncSections = {};
      let newOutOfSyncButtons = [];
      outOfSyncLanguages.forEach(item => {
        if(item !== '') {
          let language = item.split('_')[0]
          let objects = item.split('_')[1].split(',');
          newOutOfSyncSections[language] = objects;
          for(let i=0; i<objects.length; i++) {
            if(!newOutOfSyncButtons.find(x => x.object === objects[i])) {
              newOutOfSyncButtons.push({
                object: objects[i],
                to: language
              });
            }
          }
        }
      });
      setOutOfSyncSections(newOutOfSyncSections); 
      setOutOfSyncButtons(newOutOfSyncButtons);     
    }

  }, [
    props.bulkTranslation.all_translate_jobs_count, 
    props.bulkTranslation.all_translate_jobs_done, 
    props.bulkTranslation.processes, 
    props.bulkTranslation.translate_words_pending, 
    props.bulkTranslation.translate_progress, 
    window.localStorage.getItem('ly_bulk_translation:out_of_sync')
  ]);

  const handleSyncProcesses = () => {
    // Iterate through all processes and trigger the resync of the scopes
    outOfSyncButtons.forEach(task => {
      handleSync(task.object, task.to);
    });
    setShowCard(false);
  }
  const handleSync = (scope, to) => {
    let languageId = languages.find(x => x.code == to).id;

    switch(scope) {
      case 'products': {
        props.syncProducts(languageId, true);
        break;
      }
      case 'collections': {
        props.syncCollections(languageId, true);
        break;
      }
      case 'pages': {
        props.syncPages(languageId, true);
        break;
      }
      case 'navigation': {
        props.syncNavigation(languageId);
        break;
      }
      case 'blogs': {
        props.syncBlogs(languageId);
        break;
      }
      case 'articles': {
        props.syncArticles(languageId, true);
        break;
      }
    }

    _manageOutOfSyncString(scope, to);
  }

  const _manageOutOfSyncString = (scope, to) => {
    // LocalStorage
    let outOfSyncString = window.localStorage.getItem('ly_bulk_translation:out_of_sync');
    if(outOfSyncString) {
      let outOfSyncLanguages = outOfSyncString.split(';');
      let newOutOfSyncLanguages = [];
      let newOutOfSyncSections = {};
      let newOutOfSyncButtons = [];
      outOfSyncLanguages.forEach(value => {
        if(value !== '') {
          let newValue = value;
          //if(newValue.indexOf(to) === 0) {
            newValue = newValue.replace(`${scope},`, '').replace(`,${scope}`, '').replace(`${scope}`, '');
            //if(newValue.charAt(newValue.length-1) === ',') newValue = newValue.slice(0, -1);
            if(newValue !== `${to}_`) {
              //console.log(newValue)
              newOutOfSyncLanguages.push(newValue);
              newOutOfSyncSections[to] = newValue.split('_')[1].split(',');
            }
          //} else {
          //  newOutOfSyncLanguages.push(newValue);
          //  newOutOfSyncSections[newValue.split('_')[0]] = newValue.split('_')[1].split(',');
          //}
        }
      });
      outOfSyncButtons.forEach(button => {
        if(button.object !== scope) {
          newOutOfSyncButtons.push(button);
        }
      })
      setOutOfSyncSections(newOutOfSyncSections); 
      setOutOfSyncButtons(newOutOfSyncButtons);    
    
    
      if(newOutOfSyncLanguages.length === 0) {
        window.localStorage.removeItem('ly_bulk_translation:out_of_sync');
        setTimeout(() => {
          setShowCard(false);
        }, 1000);
      } else {
        window.localStorage.setItem('ly_bulk_translation:out_of_sync', newOutOfSyncLanguages.join(';'));
      }

      // Ensure that the status dialog is closed when there is no outOfSync string
    } else {
      setTimeout(() => {
        setShowCard(false);
      }, 3000);
    }
  }

  return <div style={{position: 'fixed', bottom: 0, right: 12, width: 'auto', height: showCard ? 'auto' : 0, minHeight: 0, zIndex: 100000}}>
    <Slide 
      in={showCard} 
      appear={false}
      direction="up" 
      timeout={{ 
        enter: 1000, 
        exit: translateWordsPending ? 1 : 900 
      }}
    >
      <Paper elevation={5} style={{padding: '12px', minWidth: 350, maxWidth: 400}}>
        <Badge color="default" badgeContent="beta" classes={{badge: classes.badge}}>
          <Typography variant="h5" gutterBottom classes={(!translateWordsPending && translateProgress === 100 ? classes.successText : null)}>Bulk Translation</Typography>
        </Badge>
        <Divider style={{margin: '0 -12px'}} />
        <LinearProgress variant="determinate" value={translateProgress} style={{position: 'absolute', width: '100%', marginTop: -4, left: 0}} />

        {!translateWordsPending && props.bulkTranslation.translate_progress === 100 && 
          <Typography variant="body1" className={classes.successText} style={{marginTop: 8}}>
            Bulk translation {props.bulkTranslation.scope} completed <DoneAllIcon style={{marginRight: 12}}/>
          </Typography>
        } 
        {translateWordsPending &&
          <React.Fragment>
            <Typography variant="body1" className={classes.text} style={{marginTop: 8}}>
              Bulk translation running ({translateProgress}%)
            </Typography>
            <Typography variant="caption">
                Fetched tasks: {allTranslateJobsCount} <br />
                Done tasks: {allTranslateJobsDone}
            </Typography>
          </React.Fragment>
        }
        {!translateWordsPending && outOfSyncSections !== {} &&
          <div>
            <Typography variant="body2" style={{marginTop: 8}}>
              The contents have been auto-translated now. Please synchronize the sections below in order to complete the bulk translation process: 
            </Typography>
              <Divider style={{margin: '8px 0 4px 0'}} />
            {outOfSyncButtons.map(button => {
              return <Button key={`out-of-sync-btn-${button.object}`} 
                  onClick={() => { handleSync(button.object, button.to); }} 
                  variant="outlined" 
                  size="small" 
                  fullWidth 
                  gutterBottom
                  color="secondary"
                  style={{justifyContent: "flex-start", marginTop: 4}}
                >
                  <SyncIcon className={false ? classes.rotateIcon : null} style={{ fontSize: 18, marginRight: 8 }} /> Sync - {button.object}
                </Button>
              })
            }
            {outOfSyncButtons.length > 1 &&
              <React.Fragment>
                <Divider style={{margin: '8px 0 4px 0'}} /> 
                <Button 
                  onClick={handleSyncProcesses} 
                  variant="contained" 
                  fullWidth 
                  color="secondary"
                  style={{justifyContent: "flex-start", marginTop: 4}}
                >
                  <SyncIcon className={false ? classes.rotateIcon : null} style={{ fontSize: 18, marginRight: 8 }} /> Sync all
                </Button>             
              </React.Fragment>
            }
          </div>
        }
        {(props.bulkTranslation.task_errors.length > 0) &&
          <Typography variant="body1" classes={{ root: classes.errorText }}>
            <Tooltip title={showTaskErrors ? 'Hide all errors' : 'Show all errors'} arrow>
              <span onClick={(e) => {e.preventDefault(); setShowTaskErrors(!showTaskErrors);}} style={{cursor: 'pointer'}}>{props.bulkTranslation.task_errors.length} fields could not be translated.</span>
            </Tooltip>
            <Collapse in={showTaskErrors}>
              {props.bulkTranslation.task_errors.map(item => <Typography><small>{item}</small></Typography>)}
            </Collapse>
          </Typography>
        }
      </Paper>
    </Slide>
  </div>
}

const useStyles = makeStyles({
  badge: {
    color: '#1a237e',
    backgroundColor: '#ececec',
    padding: '4px 8px',
    transform: 'scale(1) translate(100%, -25%)',
  },
  ctaBtn: {
    marginBottom: 12,
  },
  countWordsBtn: {
    //marginTop: 8,
  },
  loadingSign: {
    position: 'relative',
    margin: 4,
  },
  iconRight: {
    marginLeft: 12,
  },
  loadingSpinnerContainer: {
    margin: 'auto', 
    top: 1,
    position: 'absolute',
  },
  successText: {
    color: 'green !important',
  },
  errorText: {
    color: 'red !important',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  }
});
 
const mapStateToProps1 = ({ shop, stats, bulkTranslation, languages }) => ({
  shop: shop,
  languages: languages,
  stats: stats,
  editLanguage: shop.shop.edit_language,
  shopUrl: shop.shop.url,
  bulkTranslation: bulkTranslation,
});
const mapDispatchToProps1 = dispatch =>
  bindActionCreators(
    {
      bulkCountWords,
      bulkTranslationStart,
      bulkTranslationGetTasks,
      resyncProducts,
      syncProducts,
      syncCollections,
      syncPages,
      syncNavigation,
      syncBlogs,
      syncArticles,
    },
    dispatch
  )
export const BulkTranslationStatusCard = withRouter(connect(
  mapStateToProps1,
  mapDispatchToProps1
)(BulkTranslationStatusCardComponent));