import React from 'react';
import { syncNewTheme, saveNewTheme, exportNewTheme, importNewTheme, resetErrors } from '../../modules/new_theme'
import withRouter from 'with-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ItemsList from '../../components/ItemsList'
import ItemsListSkeleton from '../../components/ItemsListSkeleton'
import LoadingIndicator from '../../components/LoadingIndicator';
import EmptyView from '../../components/EmptyView';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { Trans, withTranslation, useTranslation } from 'react-i18next';

import OptionsMenu from '../../components/OptionsMenu';
import ConditionalQuerySection from '../../components/ConditionalQuerySection';
import ExportImportDialogs from '../../components/ExportImportDialogs';

const styles = theme => ({
  button: {
    backgroundColor: 'transparent',
    color: '#fff',
    borderColor: '#ccc',
    top: '4px',
    '&:hover': {
      backgroundColor: 'rgba(26, 35, 126, 0.5)',
      borderColor: "#ccc",
    }
  }
});

class NewTheme extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      strings: [],
      showDownloadDialog: true,
      showImportCompleteDialog: false,
      errorLockCollapse: false
    }
  }

  componentDidMount() {
    this.props.syncNewTheme(this.props.themes.edit_theme, this.props.editLanguage)
    if(this.props.new_theme.export_po_download_url || this.props.new_theme.export_csv_download_url) {
      this.setState({showDownloadDialog: false})
    }
  }

  componentWillReceiveProps(newProps) {
    const currentLanguage = this.props.editLanguage;
    const newLanguage = newProps.editLanguage;
    if(currentLanguage != newLanguage) {
      this.props.syncNewTheme(this.props.themes.edit_theme, newLanguage)
      return
    }

    if(this.props.new_theme.import_new_theme_pending === true && newProps.new_theme.import_new_theme_pending === false) {
      this.setState({showImportCompleteDialog: true})
    }

    const currentTheme = this.props.themes.edit_theme;
    const newTheme = newProps.themes.edit_theme;
    if(currentTheme != newTheme) {
      this.props.syncNewTheme(newTheme, this.props.editLanguage)
      return
    }

    if((this.props.new_theme.sync_new_theme_pending === true && newProps.new_theme.sync_new_theme_pending === false) || (this.props.new_theme.save_new_theme_pending === true && newProps.new_theme.save_new_theme_pending === false)) {
      let themeSections = [];
      let themeSectionFields = {};
      let translatedStrings = {};
      let newStrings = [];

      newProps.new_theme.strings.forEach((string, index) => {
        var section = string.path.split('.')[0];
        if(typeof themeSectionFields[section] !== 'object') {
          themeSections.push(section);
          themeSectionFields[section] = [];
          translatedStrings[section] = 0
        }
        if(string.target) {
          translatedStrings[section] = translatedStrings[section] + 1;
        }

        if(section == 'shopify') {
          if(!string.target) {
            //console.log(string.source);
          }
        }


        themeSectionFields[section].push({
          id: string.id, //string.path.replaceAll('.', '___'),
          multiline: true,
          rows: 5,
          type: 'text',
          label: string.path || '',
          source: string.source || '',
          target: string.target || '',
          outdated: (typeof string.outdated === 'boolean') ? string.outdated : null  // TEST:  (Math.random() < .5 ) ? true : false
        })
      });
      
      themeSections.map(section => {
        newStrings.push({
          type: 'theme___' + this.props.themes.edit_theme,
          id: section,
          synchronized: false,
          title: section.charAt(0).toUpperCase() + section.slice(1),
          info: '',
          fields: themeSectionFields[section],
          progress: (themeSectionFields[section].length > 0) ? (translatedStrings[section] / themeSectionFields[section].length) * 100 : 100,
        });
      })

      this.setState({strings: newStrings});
    }
  }

  onSave = items => {
    const usedItems = []
    items.map(item => {
      item.fields.forEach(field => {
        if(field.target !== (this.props.new_theme.strings.find(x => x.id === field.id).target || '')) {
          usedItems.push({
            id: field.id,
            text: field.target
          });         
        }
      });
    });
    this.props.saveNewTheme(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
  }

  onResaveAll = (itemId) => {
    const item = this.state.strings.find(x => x.id == itemId);
    if(item) {
      const usedItems = [];
      item.fields.forEach(field => {
        usedItems.push({
          id: field.id,
          text: field.target
        });
      })

      this.props.saveNewTheme(this.props.themes.edit_theme, this.props.editLanguage, usedItems)
    }
  }

  onImport = (e) => {
    this.setState({ optionsMenuAnchorEl: null });
    
    const file = e.target.files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    if(fileType != 'mo' && fileType != 'csv') {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this.props.importNewTheme(formData, fileType, this.props.themes.edit_theme, this.props.editLanguage)
  }

  onExport = (e) => {
    if(!this.props.new_theme.export_po_download_url && !this.props.new_theme.export_csv_download_url) {
      this.props.exportNewTheme(this.props.themes.edit_theme, this.props.editLanguage);
    } else {
      this.setState({showDownloadDialog: false}, () => {
        this.setState({showDownloadDialog: true});
      });
    }
  }

  render() {
    const { t, classes } = this.props

    if(!this.props.themes.edit_theme || !this.props.themes.themes.find(x => x.id == this.props.themes.edit_theme)) {
      return <EmptyView
        title={t('pages.theme.noThemeTitle')}
        description={t('pages.theme.noThemeDescription')}
      />
    }

    if(this.props.new_theme.sync_new_theme_pending === true || (this.props.new_theme.error && this.props.new_theme.error.level === 0)) {
      return <React.Fragment>
        <Typography variant="h4" gutterBottom component="h2">
          {t('pages.theme.title')} - {this.props.themes.themes.find(x => x.id == this.props.themes.edit_theme).name}
        </Typography>
        <Divider className="divider-white" />
        <ItemsListSkeleton rows="10" hideBottomToolbar/>
        <LoadingIndicator
          pending={this.props.new_theme.sync_new_theme_pending}
          progress={this.props.new_theme.progress}
          error={this.props.new_theme.error}
          withLogo
        />

      </React.Fragment>  
    }

    return <React.Fragment>
      <Typography variant="h4" gutterBottom component="h2">
        {t('pages.theme.title')} - {this.props.themes.themes.find(x => x.id == this.props.themes.edit_theme).name}

        <OptionsMenu
          onImport={this.onImport}
          onExport={this.onExport}
        />
      </Typography>

      <Divider className="divider-white" />

      <ConditionalQuerySection languages={this.props.languages.languages} />

      <ItemsList 
        virtualized={false}
        type="theme"
        pagination={false}
        paginate={false}
        isPending={
          this.props.new_theme.sync_new_theme_pending ||
          this.props.new_theme.export_new_theme_pending ||
          this.props.new_theme.import_new_theme_pending
        }
        isSaving={
          this.props.new_theme.save_new_theme_pending
        }
        data={this.state.strings}
        onSave={this.onSave}
        onResaveAll={this.onResaveAll}
        extraRouteSegment={this.props.themes.edit_theme}
        toolbarTitle="Additional Fields"
        allowAutoTranslation={true}
        allowFieldFiltering={true}
        error={this.props.new_theme.error}
        errorLockCollapse={this.state.errorLockCollapse}
        //allowGroupFiltering
      />
      <LoadingIndicator
        pending={this.props.new_theme.sync_new_theme_pending || this.props.new_theme.save_new_theme_pending || this.props.new_theme.import_new_theme_pending || this.props.new_theme.export_new_theme_pending}
        loadingType={this.props.new_theme.save_new_theme_pending ? 'saving' : 'loading'}
        progress={this.props.new_theme.progress}
        error={this.props.new_theme.error}
        onErrorCancel={this.props.resetErrors}
        onErrorRetry={() => {
          this.setState({ errorLockCollapse: true }, () => {
            this.setState({ errorLockCollapse: false });
          });
        }}
        withLogo
      />

      <ExportImportDialogs
        showDownloadDialog={this.state.showDownloadDialog}
        showImportCompleteDialog={this.state.showImportCompleteDialog}
        imported_entries={this.props.new_theme.imported_entries}
        export_po_download_url={this.props.new_theme.export_po_download_url}
        export_csv_download_url={this.props.new_theme.export_csv_download_url}
      />
    </React.Fragment> 
  }
}

const mapStateToProps = ({ shop, themes, new_theme, languages }) => ({
  themes: themes,
  new_theme: new_theme,
  editLanguage: shop.shop.edit_language,
  languages: languages
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      syncNewTheme,
      saveNewTheme,
      exportNewTheme,
      importNewTheme,
      resetErrors
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(NewTheme))))