import API from '../api/api'

const SYNC_ARTICLES_PENDING = 'SYNC_ARTICLES_PENDING'
const SYNC_ARTICLES_PROGRESS = 'SYNC_ARTICLES_PROGRESS'
const SYNC_ARTICLES_RESPONSE = 'SYNC_ARTICLES_RESPONSE'
const SYNC_ARTICLE_PENDING = 'SYNC_ARTICLE_PENDING'
const SYNC_ARTICLE_RESPONSE = 'SYNC_ARTICLE_RESPONSE'
const SAVE_ARTICLES_PENDING = 'SAVE_ARTICLES_PENDING'
const SAVE_ARTICLES_PROGRESS = 'SAVE_AERTICLES_PROGRESS'
const SAVE_ARTICLES_RESPONSE = 'SAVE_ARTICLES_RESPONSE'
const SYNC_ARTICLE_METAFIELDS_PENDING = 'SYNC_ARTICLE_METAFIELDS_PENDING'
const SYNC_ARTICLE_METAFIELDS_PROGRESS = 'SYNC_ARTICLE_METAFIELDS_PROGRESS'
const SYNC_ARTICLE_METAFIELDS_RESPONSE = 'SYNC_ARTICLE_METAFIELDS_RESPONSE'
const CREATE_ARTICLE_CUSTOM_CONTENTS_PENDING = 'CREATE_ARTICLE_CUSTOM_CONTENTS_PENDING'
const CREATE_ARTICLE_CUSTOM_CONTENTS_PROGRESS = 'CREATE_ARTICLE_CUSTOM_CONTENTS_PROGRESS'
const CREATE_ARTICLE_CUSTOM_CONTENTS_RESPONSE = 'CREATE_ARTICLE_CUSTOM_CONTENTS_RESPONSE'
const DELETE_ARTICLE_CUSTOM_CONTENT_PENDING = 'DELETE_ARTICLE_CUSTOM_CONTENT_PENDING'
const DELETE_ARTICLE_CUSTOM_CONTENT_PROGRESS = 'DELETE_ARTICLE_CUSTOM_CONTENT_PROGRESS'
const DELETE_ARTICLE_CUSTOM_CONTENT_RESPONSE = 'DELETE_ARTICLE_CUSTOM_CONTENT_RESPONSE'
const EXPORT_ARTICLES_PENDING = 'EXPORT_ARTICLES_PENDING'
const EXPORT_ARTICLES_PROGRESS = 'EXPORT_ARTICLES_PROGRESS'
const EXPORT_ARTICLES_RESPONSE = 'EXPORT_ARTICLES_RESPONSE'
const IMPORT_ARTICLES_PENDING = 'IMPORT_ARTICLES_PENDING'
const IMPORT_ARTICLES_PROGRESS = 'IMPORT_ARTICLES_PROGRESS'
const IMPORT_ARTICLES_RESPONSE = 'IMPORT_ARTICLES_RESPONSE'
const RESET_ERRORS = 'RESET_ERRORS'

const initialState = {
  sync_articles_pending: true,
  sync_article_pending: false,
  sync_article_metafields_pending: false,
  create_article_custom_contents_pending: false,
  delete_article_custom_content_pending: false,
  save_articles_pending: false,
  export_articles_pending: false,
  export_po_download_url: null,
  export_csv_download_url: null,
  import_articles_pending: false,
  imported_entries: 0,
  progress: 0,
  error: false,
  articles: [],
}

// REDUCER
export default (state = initialState, action) => {
  switch(action.type) {
    case RESET_ERRORS: {
      return {
        ...state,
        error: false,
      }
    }

    case SYNC_ARTICLES_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_articles_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case SYNC_ARTICLES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_ARTICLES_RESPONSE: {
      if(action.response.success === true) {
        return {
          ...state,
          sync_articles_pending: false,
          error: false,
          articles: action.response.articles
        }
      } else {
        return {
          ...state,
          sync_articles_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 0,
          },
        }
      }
    }

    case SYNC_ARTICLE_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_article_pending: true,
        error: false,
      }
    }
    case SYNC_ARTICLE_RESPONSE: {
      if(action.response.success === true) {
        let newArticles = state.articles.slice()
        let articleToUpdate = newArticles.find(x => x.id == action.response.article.id)
        
        articleToUpdate.synchronized = true;
        articleToUpdate.progress = action.response.article.progress

        articleToUpdate.source.title = action.response.article.source.title
        articleToUpdate.source.body_html = action.response.article.source.body_html
        articleToUpdate.source.summary_html = action.response.article.source.summary_html
        articleToUpdate.source.page_title = action.response.article.source.page_title
        articleToUpdate.source.page_description = action.response.article.source.page_description
        articleToUpdate.source.image = action.response.article.source.image
        
        articleToUpdate.target.title = action.response.article.target.title
        articleToUpdate.target.body_html = action.response.article.target.body_html
        articleToUpdate.target.summary_html = action.response.article.target.summary_html
        articleToUpdate.target.page_title = action.response.article.target.page_title
        articleToUpdate.target.page_description = action.response.article.target.page_description
        articleToUpdate.target.image = action.response.article.target.image

        articleToUpdate.custom_contents = action.response.article.custom_contents.slice()
        
        return {
          ...state,
          articles: newArticles,
          sync_article_pending: false
        }
      } else {
        return {
          ...state,
          sync_article_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 1,
          },
        }
      }
    }

    case SAVE_ARTICLES_PENDING: {
      return {
        ...state,
        progress: 0,
        save_articles_pending: true,
        error: false,
      }
    }
    case SAVE_ARTICLES_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case SAVE_ARTICLES_RESPONSE: {
      let error = false;
      let newArticles = state.articles.slice();
      action.response.forEach((response, i) => {
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
          let newArticle = newArticles.find(x => x.id == action.items[i].id)
          newArticle.target = {...newArticle.target, ...action.items[i]}
        } else {
          let newArticle = newArticles.find(x => x.id == response.article.id)
          newArticle.progress = response.article.progress
          newArticle.source = {...response.article.source}
          newArticle.target = {...response.article.target}
          newArticle.metafields = response.article.metafields.slice()
          newArticle.custom_contents = response.article.custom_contents.slice()
        }
      })
      return {
        ...state,
        articles: newArticles,
        save_articles_pending: false,
        error: error
      }
    }

    case SYNC_ARTICLE_METAFIELDS_PENDING: {
      return {
        ...state,
        progress: 0,
        sync_article_metafields_pending: true,
        error: false,
      }
    }
    case SYNC_ARTICLE_METAFIELDS_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case SYNC_ARTICLE_METAFIELDS_RESPONSE: {
      if(action.response.success === true) {
        let newArticles = state.articles.slice()
        let articleToUpdate = newArticles.find(x => x.id == action.response.article_id)
        
        articleToUpdate.metafields_synchronized = true;
        articleToUpdate.metafields = action.response.metafields.slice()

        return {
          ...state,
          sync_article_metafields_pending: false,
          articles: newArticles,
          error: false
        }
      } else {
        return {
          ...state,
          sync_article_metafields_pending: false,
          error: {
            message: action.response,
            action: action.action,
            level: 2,
          },
        }
      }
    }
    case CREATE_ARTICLE_CUSTOM_CONTENTS_PENDING: {
      return {
        ...state,
        create_article_custom_contents_pending: true,
        error: false,
      }
    }
    case CREATE_ARTICLE_CUSTOM_CONTENTS_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case CREATE_ARTICLE_CUSTOM_CONTENTS_RESPONSE: {
      let newArticles = state.articles.slice()
      let error = false;

      for(let i in action.response) {
        const response = action.response[i];
        if(response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
        } else {
          let articleToUpdate = newArticles.find(x => x.id == response.article_id)
          articleToUpdate.custom_contents.push({
            id: response.custom_content.id,
            source: {
              text: response.custom_content.source.text
            },
            target: {
              text: response.custom_content.target.text
            }
          });
        }
      }

      return {
        ...state,
        articles: newArticles,
        create_article_custom_contents_pending: false,
        error: error
      }
    }
    case DELETE_ARTICLE_CUSTOM_CONTENT_PENDING: {
      return {
        ...state,
        delete_article_custom_content_pending: true,
        error: false,
      }
    }
    case DELETE_ARTICLE_CUSTOM_CONTENT_PROGRESS: {
      return {
        ...state,
        progress: action.progress
      }
    }
    case DELETE_ARTICLE_CUSTOM_CONTENT_RESPONSE: {
      let newArticles = state.articles.slice()
      let error = false;

      if(action.response.success !== true) {
          error = {
            message: action.response,
            action: action.action,
          };
      } else {
        let articleToUpdate = newArticles.find(x => x.id == action.response.article_id)
        articleToUpdate.custom_contents = articleToUpdate.custom_contents.filter(x => x.id != action.response.id)
      }

      return {
        ...state,
        articles: newArticles,
        delete_article_custom_content_pending: false,
        error: error
      }
    }
    case EXPORT_ARTICLES_PENDING: {
      return {
        ...state,
        progress: 0,
        export_articles_pending: true,
        export_po_download_url: null,
        export_csv_download_url: null,
        error: false,
      }
    }
    case EXPORT_ARTICLES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case EXPORT_ARTICLES_RESPONSE: {
      console.log(action.response)
      if(action.response.success == true) {
        return {
          ...state,
          progress: 100,
          export_articles_pending: false,
          export_po_download_url: action.response.po_download_url,
          export_csv_download_url: action.response.csv_download_url
        }
      } else {
        return {
          ...state,
          progress: 100,
          export_pages_pending: false
        }
      }
    }
    case IMPORT_ARTICLES_PENDING: {
      return {
        ...state,
        progress: 0,
        import_articles_pending: true,
        imported_entries: 0,
        error: false,
      }
    }
    case IMPORT_ARTICLES_PROGRESS: {
      return  {
        ...state,
        progress: action.progress
      }
    }
    case IMPORT_ARTICLES_RESPONSE: {
      let isOk = true;
      let importedEntries = 0;
      action.response.forEach(response => {
        if(response.success !== true) {
          isOk = false
        } else {
          importedEntries += response.imported_entries
        }
      })

      if(isOk == true) {
        return {
          ...state,
          progress: 100,
          import_articles_pending: false,
          imported_entries: importedEntries
        }
      } else {
        return {
          ...state,
          progress: 100,
          import_articles_pending: false,
          imported_entries: importedEntries
        }
      }
    }
    default:
      return state
  }
}

// ACTIONS
export const resetErrors = () => {
  return dispatch => {
    dispatch({
      type: RESET_ERRORS
    });
  }
}
export const syncArticles = (language, sync_reset) => {  
  return dispatch => {
    dispatch({
      type: SYNC_ARTICLES_PENDING
    })

    API.syncArticles(
      language,
      progress => {
        dispatch({
          type: SYNC_ARTICLES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_ARTICLES_RESPONSE,
          response: response,
          action: () => syncArticles(language)
        })
      },
      sync_reset
    )
  }
}

export const syncArticle = (articleId, languageId) => {  
  return dispatch => {
    dispatch({
      type: SYNC_ARTICLE_PENDING
    })

    API.syncArticle(
      articleId,
      languageId,
      response => {
        dispatch({
          type: SYNC_ARTICLE_RESPONSE,
          response: response,
          action: () => syncArticle(articleId, languageId)
        })
      }
    )
  }
}

export const saveArticles = (language, items, force = 0) => {  
  return dispatch => {
    dispatch({
      type: SAVE_ARTICLES_PENDING
    })

    API.saveArticles(
      language,
      items,
      force,
      progress => {
        dispatch({
          type: SAVE_ARTICLES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SAVE_ARTICLES_RESPONSE,
          response: response,
          items: items,
          action: () => saveArticles(language, items, force)
        })
      })
  }
}

export const syncArticleMetafields = (article, language) => {  
  return dispatch => {
    dispatch({
      type: SYNC_ARTICLE_METAFIELDS_PENDING
    })

    API.syncArticleMetafields(
      article,
      language,
      progress => {
        dispatch({
          type: SYNC_ARTICLE_METAFIELDS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: SYNC_ARTICLE_METAFIELDS_RESPONSE,
          response: response,
          action: () => syncArticleMetafields(article, language)
        }
      )
    })
  }
}

export const createArticleCustomContents = (article, language, items) => {  
  return dispatch => {
    dispatch({
      type: CREATE_ARTICLE_CUSTOM_CONTENTS_PENDING
    })

    API.createArticleCustomContents(
      article,
      language,
      items,
      progress => {
        dispatch({
          type: CREATE_ARTICLE_CUSTOM_CONTENTS_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: CREATE_ARTICLE_CUSTOM_CONTENTS_RESPONSE,
          response: response,
          items: items,
          action: () => createArticleCustomContents(article, language, items)
        })
      })
  }
}

export const deleteArticleCustomContent = (article, ids) => {
  return dispatch => {
    dispatch({
      type: DELETE_ARTICLE_CUSTOM_CONTENT_PENDING
    })

    API.deleteArticleCustomContent(
      article,
      ids,
      progress => {
        dispatch({
          type: DELETE_ARTICLE_CUSTOM_CONTENT_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: DELETE_ARTICLE_CUSTOM_CONTENT_RESPONSE,
          response: response,
          action: () => deleteArticleCustomContent(article, ids)
        })
      })
  }
}

export const exportArticles = (language) => {
  return dispatch => {
    dispatch({
      type: EXPORT_ARTICLES_PENDING
    })

    API.exportArticles(
      language,
      progress => {
        dispatch({
          type: EXPORT_ARTICLES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: EXPORT_ARTICLES_RESPONSE,
          response: response,
          action: () => exportArticles(language)
        }
      )
    })
  }
}

export const importArticles = (file, fileType, language) => {
  return dispatch => {
    dispatch({
      type: IMPORT_ARTICLES_PENDING
    })

    API.importArticles(
      file,
      fileType,
      language,
      progress => {
        dispatch({
          type: IMPORT_ARTICLES_PROGRESS,
          progress: progress
        })
      },
      response => {
        dispatch({
          type: IMPORT_ARTICLES_RESPONSE,
          response: response,
          action: () => importArticles(file, fileType, language)
        }
      )
    })
  }
}