import React, { useState, useEffect } from 'react';
import { enableCrossDomainLinks, disableCrossDomainLinks } from '../../modules/shop'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withRouter from 'with-router'
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TileRow from './TileRow';
import ThemesSection from './ThemesSection';
import SimpleLineChart from './statistics/SimpleLineChart';
import SimpleTable from './statistics/SimpleTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import Joyride from 'react-joyride';
import TranslationProgressSection from './TranslationProgressSection';
import RecentlyUpdatedContentSection from './RecentlyUpdatedContentSection';
import LanguagesSection from './LanguagesSection';
import SwitcherConfigurator from '../../components/SwitcherConfigurator';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import DontAskAgainDialog from '../../components/DontAskAgainDialog.js';
import LoadingIndicator from '../../components/LoadingIndicator';
import API from '../../api/api';
import ErrorBoundary from '../../components/ErrorBoundary';
import UpdateRequiredNotice from '../../components/UpdateRequiredNotice';


const REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR = window.loadEnv("REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR", process.env.REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR);
const REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS = window.loadEnv("REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS", process.env.REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS);


class Dashboard extends React.Component {

  componentDidMount() {
  }

  onEnableCrossDomainLinks = () => {
    this.props.enableCrossDomainLinks();
  }

  onDisableCrossDomainLinks = () => {
    this.props.disableCrossDomainLinks();
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>

        {/*
          <Alert severity="error" 
            gutterBottom
            style={{marginBottom: 16}} 
          >
              <strong>MAINTENANCE INFORMATION</strong><br /> 
              Please be advised, that due to maintenance work, there will be a scheduled downtime across our service on <strong>Saturday, 1/14/2023 from 11am to 4pm (UTC).</strong><br />
              Thank you for your understanding!     
          </Alert>
        */}

        <UpdateRequiredNotice 
          {...this.props}
          requiredVersions={{
            'snippets/ly-core-scripts.liquid': '3.6.0',
            'snippets/ly-switcher-factory.liquid': '3.4.0',
          }} 
          moreInfoContent={<React.Fragment>
            <br />
            <strong>The following features and functions won't work correctly until the update is done:</strong>
            <ul>
              <li>Change and save the switcher and language-detection configuration via the "Switcher Configurator".</li>
              <li>Create new Custom Contents</li>
            </ul>
            <strong>The following files will be affected by the update:</strong>
            <ul>
              <li>snippets/ly-core-scripts.liquid</li>
              <li>snippets/ly-get-customs.liquid</li>
              <li>snippets/ly-switcher-factory.liquid</li>
            </ul>  
          </React.Fragment>}
        />

        <Typography variant="h4" gutterBottom component="h2">
          Dashboard
        </Typography>
        <Divider className="divider-white" />
        <div className={classes.contentContainer}>
          <TileRow shop={this.props.shop} />
        </div>

        {this.props.shop.edit_language && this.props.shop.edit_language !== 'Language' && (
          <Grid container alignItems="stretch" alignContent="stretch" spacing={3}>
            <Grid item xs={12} md={6} style={{paddingTop:0}}>
              <TranslationProgressSection editLanguage={this.props.shop.edit_language} editTheme={this.props.editTheme}/>
            </Grid>
            <Grid item xs={12} md={6} style={{paddingTop:0}}>
              <RecentlyUpdatedContentSection />
            </Grid>
          </Grid>          
        )}

        <ErrorBoundary msg='Whoops, an error occured in the "Languages" section. Please try to reload this page or contact our support if the problem persists.'>
          <LanguagesSection />
        </ErrorBoundary>

        {REACT_APP_DASHBOARD_SHOW_SWITCHER_CONFIGURATOR == 'true' &&
          <ErrorBoundary msg='Whoops, an error occured in the "Switcher Configurator" section. Please try to reload this page or contact our support if the problem persists.'>
            <SwitcherConfigurator
              key={`switcher-section-${this.props.editTheme}`}
              onEnableCrossDomainLinks={this.onEnableCrossDomainLinks}
              onDisableCrossDomainLinks={this.onDisableCrossDomainLinks}
            />            
          </ErrorBoundary>
        }
        {REACT_APP_DASHBOARD_SHOW_THEME_SETTINGS == 'true' && 
          <ErrorBoundary msg='Whoops, an error occured in the "Theme Settings" section. Please try to reload this page or contact our support if the problem persists.'>
            <ThemesSection key={`theme-section-${this.props.editTheme}`} />
          </ErrorBoundary>
        }
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  contentContainer: {
    marginBottom: theme.spacing(3),
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
});

const mapStateToProps = ({ shop, themes }) => ({
  shop_version: shop.shop.version,
  shop: shop.shop,
  editTheme: themes.edit_theme,
  themes: themes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enableCrossDomainLinks,
      disableCrossDomainLinks
    },
    dispatch
  )

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard)))