import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DoneOutlined from "@material-ui/icons/DoneOutlined";
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import _ from 'lodash';


const styles = theme => ({
  formControl: {
    display: 'block'
  },
  switchContainer: {
    top: 'unset',
    right: '8px',
    height: '16px',
    position: 'absolute'
  },
  infoBox: {
    backgroundColor: 'rgb(241, 243, 249)', 
    padding: '12px 24px', 
    border: 'solid 1px rgba(0,0,0,.05)',
  },
  infoText: {
    color: '#5c6bc0',
  },
  inputBase: {
    padding: 0,
    width: '100%'
  },
  input: {
    padding: '6px 0',
    fontSize: '0.85rem'
  },
  toggleEditModeBtn: {
    position: 'absolute',
    right: -12,
    top: -12,
  },
  actionBar: {
    display: 'flex',
    position: 'fixed',
    bottom: 24,
    left: 265,
    right: 24,
    padding: 8,
    borderTop: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
    borderRadius: '0 0 6px 6px',
    '&>.item': {
      flexGrow: 1,
      height: 36,
      margin: 8,
      maxWidth: 250
    },
    '&>.item2': {
      width: 80,
      height: 36,
      margin: 8
    },
    '&> .MuiFormControl-root .MuiInputBase-root input, .MuiOutlinedInput-root.MuiInputBase-fullWidth input': {
      padding: '8px 16px !important',
    },
    '&> .MuiFormControl-root .MuiOutlinedInput-root .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '8px 32px 8px 8px !important',
    }
  }
});

const DragHandle = SortableHandle(() => (
  <ListItemIcon className={'toggleEditModeBtn'} style={{marginRight: 0, minWidth: 'auto', cursor: 'ns-resize', alignItems: 'center', justifyContent: 'right'}}>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableItem = SortableElement((props) => {
  const { classes, language, onChangeDomain, onRemoveRow, index, listIndex, onEditModeChange } = props;
  const rawLanguage = props.rawLanguages.find(item => item.code === language.iso_code);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({});

  const onChangeCustomName = (e, field) => {
    let datas = {...data};
    datas[field] = { 
      field: field,
      value: e.target.value, 
      index: listIndex,
      language
    }
    setData(datas);
  }
  const switchEditingMode = () => {
    onEditModeChange(!isEditing, data);
    setIsEditing(!isEditing);
  }

  const getAssignedDomain = () => {
    if(rawLanguage && typeof rawLanguage.assigned_to_domains.find(item => item.is_default === true) !== 'undefined')
      return rawLanguage.assigned_to_domains.find(item => item.is_default === true).host;
    else
      return '';
  }

  return (
    <TableRow key={language.name}>
      <TableCell component="th" scope="row"><DragHandle /></TableCell>
      <TableCell component="th" scope="row">
        <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${language.iso_code}.svg')`}} /> 
        {language.iso_code ? language.iso_code : '' }
      </TableCell>
      <TableCell component="th" scope="row">
        <div style={{position: 'relative', height: 24}}>
            {isEditing ?
              <TextField
                //key={`domain-field-${language.iso_code}`}
                defaultValue={(language.custom_name && language.custom_name !== '') ? language.custom_name : language.name}
                onChange={(e) => onChangeCustomName(e, 'custom_name')}
                placeholder={'Enter a language name'} 
                disabled={!props.crossDomainLinksEnabled}
                InputProps={{
                  classes: {input: classes.input}
                }}
                fullWidth
              />
            :
              <Typography variant="body2" className={classes.input}>
                {(language.custom_name && language.custom_name !== '') ? language.custom_name : language.name}
              </Typography>
            } 
        </div>
      </TableCell>
      <TableCell>
          <div style={{position: 'relative', height: 24}}>
            {isEditing ?
              <TextField
                //key={`domain-field-${language.iso_code}`}
                defaultValue={language.domain ? language.domain : getAssignedDomain()}
                onChange={(e) => onChangeCustomName(e, 'domain')}
                placeholder={'Enter Domain or Subdomain'} 
                disabled={!props.crossDomainLinksEnabled}
                InputProps={{
                  classes: {input: classes.input}
                }}
                fullWidth
              />
            :
              <Typography variant="body2" className={classes.input}>
              {language.domain ? language.domain : getAssignedDomain()}
              </Typography>
            }           
          </div>
      </TableCell>
      <TableCell align="right">
        <span style={{position: 'relative', height: 24, marginRight: 16}}>
          {isEditing ?
            <IconButton onClick={switchEditingMode} style={styles.toggleEditModeBtn} className={classes.toggleEditModeBtn}>
              <DoneOutlined />
            </IconButton>
          :
            <IconButton onClick={switchEditingMode} style={styles.toggleEditModeBtn} className={classes.toggleEditModeBtn}>
              <EditOutlined />
            </IconButton>
          }  
        </span>
        <Button disabled={!props.crossDomainLinksEnabled} size="small" onClick={() => onRemoveRow(language)} variant="outlined" color="secondary">Remove</Button> 
      </TableCell>
    </TableRow>
  );
});

const SortableList = SortableContainer((props) => {
  const { classes, selectedLanguageTemplate, rawLanguages, onChangeDomain, onChangeNewField, onSelectLanguage, onAddRow, onRemoveRow, onEditModeChange } = props;

  return (<Table className={props.classes.table} size="small" aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Order</TableCell>
        <TableCell align='left'>Code</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Domain</TableCell>
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {props.items.map((item, index) => (
        <SortableItem key={`item-${JSON.stringify(item)}`} 
          index={index} 
          listIndex={index} 
          language={item} 
          onEditModeChange={onEditModeChange}
          {...props}
        />
      ))}
    </TableBody>
  </Table>
  
  );
});


class DomainLocationMapper extends React.Component {
  _selectedLanguageObject = null;

  constructor(props) {
    super(props);

    //console.log(props.languages);

    this.state = {
      crossDomainLinksEnabled: props.crossDomainLinksEnabled,
      languages: props.languages,
      selectedLanguageTemplate: '',
      newName: '',
      newDomain: '',
      newIsoCode: '',
    }
  }

  componentDidMount() {
    //if(!Array.isArray(this.state.languages) || this.state.languages.length === 0) {
      var newLanguages = [];
      this.props.rawLanguages.map((language, index) => {
        if(language.published) {
          let _domain = language.domain ? language.domain : false;
          let _root_url = language.base ? '/' : '/' + language.code;
          if(typeof language.assigned_to_domains.find(item => item.is_default === true) !== 'undefined') {
            _domain = language.assigned_to_domains.find(item => item.is_default === true).host;
            _root_url = '/';
          }  
          newLanguages.push({
            iso_code: language.code,
            root_url: _root_url,
            name: language.name,
            primary: language.base,
            domain: _domain,
            published: language.published,
            custom_name: language.custom_name ? language.custom_name : false
          });      
        }
      });
      //console.log(this.state.languages, newLanguages);
      this.setState({languages: newLanguages});
      //this.props.onChange(newLanguages);
    //}
  }

  
  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.crossDomainLinksEnabled !== prevState.crossDomainLinksEnabled) {
      return {crossDomainLinksEnabled: nextProps.crossDomainLinksEnabled};
    }
    //if(JSON.stringify(nextProps.languages) !== JSON.stringify(prevState.languages)){
    if(!_.isEqual(nextProps.languages, prevState.languages)){
      //console.log('###', nextProps.languages);
      return {languages : nextProps.languages};
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  onToggleCrossDomainLinks = () => {
    if(this.state.crossDomainLinksEnabled == true) {
      this.props.onDisableCrossDomainLinks();
    } else {
      this.props.onEnableCrossDomainLinks();
    }
  }

  onChangeDomain = (language, e) => {
    let newLanguages = this.state.languages.slice();
    newLanguages.find((x) => x.iso_code === language.iso_code).domain = e.target.value;

    this.setState({
      languages: newLanguages
    });
    this.props.onChange(newLanguages);
  }

  onChangeNewField = (field, e) => {
    var field = field.replace('custom_', '');
    var newValue = e.target.value;
    if(field === 'domain') {
      if(newValue.indexOf('https://') === 0 || newValue.indexOf('http://') === 0 || newValue.indexOf('//') === 0) {
        newValue = newValue.replace('https://', '').replace('http://', '').replace('//', '');
      }
    }
    this.setState({
      [_.camelCase('new ' + field.replace('_', ' '))]: newValue
    });
  }

  onAddRow = (row) => {
    if(!this._selectedLanguageObject) {
      this._selectedLanguageObject = {
        iso_code: (this.state.selectedLanguageTemplate === '__intern__' || (this.state.selectedLanguageTemplate === '__custom__')) ? this.state.newIsoCode : '',
        root_url: '/',
        name: this.state.newName,
        primary: false,
        domain: this.state.newDomain,
        published: true,
        custom_name: this.state.newName
      }
    }
    let newLanguages = [...this.state.languages, this._selectedLanguageObject];
    this._selectedLanguageObject = null;

    this.setState({
      languages: newLanguages,
      selectedLanguageTemplate: '',
      newName: '',
      newDomain: '',
      newIsoCode: '',
    });
    this.props.onChange(newLanguages);
  }

  onRemoveRow = (row) => {
    let newLanguages = this.state.languages.filter(item => item.iso_code !== row.iso_code);

    this.setState({
      languages: newLanguages
    });
    this.props.onChange(newLanguages);
  }

  onSelectLanguage = (e) => {
    if(e.currentTarget.getAttribute('template')) {

      this.setState({
        selectedLanguageTemplate: e.currentTarget.getAttribute('template')
      });
    } else {
      const language = this.props.rawLanguages.find(item => item.code === e.target.value);
      if(language) {
        this._selectedLanguageObject = {
          iso_code: language.code,
          root_url: language.base ? '/' : '/' + language.code,
          name: language.name,
          primary: language.base,
          domain: language.domain ? language.domain : false,
          published: language.published,
          custom_name: language.custom_name ? language.custom_name : false
        }

        this.setState({
          selectedLanguageTemplate: e.target.value
        });
      }
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const newLanguages = arrayMove(this.state.languages, oldIndex, newIndex);
    this.setState({
      languages: newLanguages,
    });
    this.props.onChange(newLanguages);
  };

  onEditModeChange = (isEditing, data) => {
    let newLanguages = this.state.languages.slice();
    if(!isEditing) {
      Object.entries(data).forEach(([key, value]) => {
        //newLanguages.find((x) => x.iso_code === data.language.iso_code).domain = data.value;
        //console.log(key, value)

        let newValue = data[key].value;
        
        if(key === 'domain') {
          if(data[key].value.indexOf('https://') === 0 || data[key].value.indexOf('http://') === 0 || data[key].value.indexOf('//') === 0) {
            newValue = newValue.replace('https://', '').replace('http://', '').replace('//', '');
          }
          if(data[key].value === '') {
            newValue = false;
          }
        }
        else if(key === 'custom_name' || key === 'iso_code') {
          if(data[key].value === '') {
            newValue = newLanguages[data[key].index][data[key].field];
          }
        }

        newLanguages[data[key].index][data[key].field] = newValue;
      })
      this.props.onChange(newLanguages);
    }
    this.setState({ 
      isEditing: isEditing,
      languages: newLanguages
    });
  }

  render() {
    const { t, classes, title, description, bottom, rawLanguages } = this.props;

    let newRawLangs = [];
    rawLanguages.forEach((language, index) => {
      if(!this.state.languages.find(x => x.iso_code === language.code)) 
        newRawLangs.push(<MenuItem key={`lang-${language.code}`} value={language.code}>
          <i className={'ly-flag-icon'} style={{backgroundImage: `url('/flag-icons/${language.code}.svg')`}} /> {language.name}
        </MenuItem>);
    });

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h3">{t('switcherConfigurator.domainTitle')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {t('switcherConfigurator.domainEnable')}
              <FormControlLabel
                className={classes.switchContainer}
                control={
                  <Switch onChange={this.onToggleCrossDomainLinks} checked={this.state.crossDomainLinksEnabled} defaultValue={this.state.crossDomainLinksEnabled} />
                }
                label={<Typography variant="caption">{this.state.crossDomainLinksEnabled ? t('system.enabled') : t('system.disabled')}</Typography>}
                labelPlacement="start"
              />
            </FormLabel>
            <FormGroup>
              <Divider style={{marginTop: 16, marginBottom: 0}} />
              <Typography variant="body2" style={{marginTop: 16, marginBottom: 8}}>
                {t('switcherConfigurator.domainInfo1')}
              </Typography>
              <ul style={{padding: '0 16px', margin: '12px 0 0 0'}}>
                <li>{t('switcherConfigurator.domainInfo2')}</li>
                <li>{t('switcherConfigurator.domainInfo3')}</li>
              </ul>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid id="sortableListWrapper" item xs={12} style={{position: 'relative', marginBottom: 50}}>
          <SortableList 
            items={this.state.languages} 
            onSortEnd={this.onSortEnd} 
            distance={10}
            helperClass={'sortableHelper'}
            lockAxis="y"
            lockToContainerEdges={true}
            helperContainer={document.getElementById('sortableListWrapper')}
            useDragHandle

            onAddRow={this.onAddRow}
            onRemoveRow={this.onRemoveRow}
            onChangeDomain={this.onChangeDomain}
            onChangeNewField={this.onChangeNewField}
            onSelectLanguage={this.onSelectLanguage}
            onEditModeChange={this.onEditModeChange}
            classes={classes}
            newName={this.state.newName}
            newDomain={this.state.newDomain}
            selectedLanguageTemplate={this.state.selectedLanguageTemplate}
            rawLanguages={this.props.rawLanguages} 
            crossDomainLinksEnabled={this.state.crossDomainLinksEnabled}
          />
          <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, backgroundColor: 'rgba(255,255,255,.5)', display: !this.state.crossDomainLinksEnabled ? 'block' : 'none'}}></div>
        </Grid>



        <div id="addCrossDomainLinksBar" className={classes.actionBar}>
          <FormControl className={'item'} variant="outlined" style={{width: 225, flexGrow: 'unset'}}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedLanguageTemplate}
              onChange={(e) => this.onSelectLanguage(e)} 
              disabled={!this.state.crossDomainLinksEnabled}
              size="medium"
              displayEmpty
              classes={{root: classes.input}}
              inputprops={{
                classes: {input: classes.input}
              }}
            >
              <MenuItem value="">
                <em>{t('switcherConfigurator.selectSwitcherItem')}</em>
              </MenuItem>
              <ListSubheader>{t('switcherConfigurator.customLinks')}</ListSubheader>
              <MenuItem value="__custom__" template="__custom__">
                <InsertLinkIcon fontSize="small" />&nbsp;{t('switcherConfigurator.externLink')}
              </MenuItem>
              <MenuItem value="__intern__" template="__intern__">
                <InsertLinkIcon fontSize="small" />&nbsp;{t('switcherConfigurator.internLink')}
              </MenuItem>
              <ListSubheader>{t('switcherConfigurator.languages')}</ListSubheader>
              {newRawLangs.length > 0 ?
                (newRawLangs)
              :
                <MenuItem disabled>
                  <small>{t('switcherConfigurator.allInUseInfo')}</small>
                </MenuItem>
              }
            </Select>
          </FormControl>
          {(this.state.selectedLanguageTemplate === "__custom__" || this.state.selectedLanguageTemplate === "__intern__") &&
            <React.Fragment>
              {(this.state.selectedLanguageTemplate === "__custom__" || this.state.selectedLanguageTemplate === "__intern__") &&
                <div className={'item'}>
                  <TextField
                    value={this.state.newIsoCode}
                    onChange={(e) => this.onChangeNewField('iso_code', e)}
                    placeholder={this.state.selectedLanguageTemplate === "__intern__" ? 'Language code (eg. fr)' : 'Language code (optional)'} 
                    disabled={!this.state.crossDomainLinksEnabled}
                    size="medium"
                    InputProps={{
                      classes: {input: classes.input}
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              }
                <TextField
                  value={this.state.newName}
                  onChange={(e) => this.onChangeNewField('custom_name', e)}
                  placeholder={'Language name'} 
                  disabled={!this.state.crossDomainLinksEnabled}
                  size="medium"
                  className={'item'}
                  InputProps={{
                    classes: {input: classes.input}
                  }}
                  variant="outlined"
                />
                <TextField
                  value={this.state.newDomain}
                  onChange={(e) => this.onChangeNewField('domain', e)}
                  placeholder={'Domain'} 
                  disabled={!this.state.crossDomainLinksEnabled}
                  size="medium"
                  className={'item'}
                  InputProps={{
                    classes: {input: classes.input}
                  }}
                  variant="outlined"
                />      
            </React.Fragment>
          }
          {this.state.selectedLanguageTemplate &&
            <Button 
              className={'item2'} 
              disabled={this.state.selectedLanguageTemplate === "__custom__" && (this.state.newName === '' || this.state.newDomain === '') || 
                        this.state.selectedLanguageTemplate === "__intern__" && (this.state.newName === '' || this.state.newDomain === '' || this.state.newIsoCode === '')} 
              variant="contained" 
              color="secondary" 
              size="medium"
              onClick={this.onAddRow}
            >
              Add
            </Button>
          }
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(DomainLocationMapper));